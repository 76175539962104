import React, { Component, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent'
import { Backdrop, CircularProgress, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Button, Typography, ButtonGroup, TableFooter, TablePagination, ClickAwayListener, FormGroup, TextField, Tooltip, TableSortLabel } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import './ProductKeyDistributionDashboard.css';
import PropTypes from 'prop-types';
import StopIcon from '@material-ui/icons/Stop';
import InfoIcon from '@material-ui/icons/Info';
import { ApiCall } from '../../Utilities/ApiCall';
import ApplicationConstants from '../../Utilities/ApplicationConstants';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import MuiAlert from '@material-ui/lab/Alert';
import RefreshIcon from '@material-ui/icons/Refresh';
import queryString from 'query-string';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    array.forEach(function (object, index) {
        object.createdDate = new Date(object.createdDate).getTime();
    })
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const useStyles = makeStyles(theme => ({
    title: {
        fontFamily: '"Segoe UI VSS (Regular)","Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
        color: 'black',
        fontWeight: 450,
        fontSize: '18px',
    },
    container: {
        maxHeight: '30em',
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

const useStyles1 = makeStyles(theme => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = event => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = event => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = event => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = event => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

//var inProcessWidgetWidth = 0;
//var approvalWidgetWidth = 0;

function View(props) {

    const classes = useStyles();
    const theme = useTheme();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [selectedRow, setSelectedRow] = React.useState([]);
    const [cancelReject, setCancelReject] = React.useState(false);
    const [cancelComment, setCancelComment] = React.useState('');
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('createdDate');
    const [keyFulfilmentRequests, setKeyFulfilmentRequests] = React.useState([])
    const [totalRecords, setTotalRecords] = React.useState(-1);
    const [errorCode, setErrorCode] = React.useState(0);
    const [errorDescription, setErrorDescription] = React.useState('');
    const [numberOfInError, setNumberOfInError] = React.useState(props.numberOfInError);
    const [numberOfWaitingApproval, setNumberOfWaitingApproval] = React.useState(props.numberOfWaitingApproval);
    const [numberOfInProgress, setNumberOfInProgress] = React.useState(props.numberOfInProgress);
    const [maxWidth, setMaxWidth] = React.useState(0);
    const [openLoading, setOpenLoading] = React.useState(props.loading);
    const [openSnackBar, setOpenSnackBar] = React.useState(false);
    const [snackBarMessage, setSnackBarMessage] = React.useState('');
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertSelector, setAlertSelector] = React.useState(0);
    const [openAlert, setOpenAlert] = React.useState(false);
    const [actionButton, setActionButton] = React.useState('');
    const [propsLoading, setPropsLoading] = React.useState(false);
    const [inProcessWidgetWidth, setInProcessWidgetWidth] = React.useState(0);
    const [approvalWidgetWidth, setApprovalWidgetWidth] = React.useState(0);

    useEffect(() => {
        if (props.errorCode !== 0 && propsLoading === false && props.loading === false) {
            setAlertMessage(ApplicationConstants.AlertMessages.ErrorCode + props.errorCode + ApplicationConstants.AlertMessages.ErrorMessage + props.errorDescription);
            setAlertSelector(0);
            setOpenAlert(true);
            setOpenLoading(false);
            setErrorCode(props.errorCode)
            setPropsLoading(true);
        } else if (propsLoading === false && props.loading ===false) {
            if (Array.isArray(keyFulfilmentRequests) && keyFulfilmentRequests.length === 0) {
                setKeyFulfilmentRequests(props.keyFulfilmentRequests);
            }
            if (totalRecords === -1) {
                setTotalRecords(props.totalRecord);
                setErrorCode(props.errorCode);
                setErrorDescription(props.errorDescription);
                setNumberOfInError(props.numberOfInError);
                setNumberOfInProgress(props.numberOfInProgress);
                setNumberOfWaitingApproval(props.numberOfWaitingApproval);
                setInProcessWidgetWidth((parseInt(props.numberOfInProgress) / parseInt(props.numberOfInProgress + props.numberOfWaitingApproval + props.numberOfInError)) * 100);

                setApprovalWidgetWidth((parseInt(props.numberOfWaitingApproval) / parseInt(props.numberOfInProgress + props.numberOfWaitingApproval + props.numberOfInError)) * 100);
                var i = 0;
                if (props?.numberOfInError > 0 ) {
                    i++;
                }
                if (props?.numberOfWaitingApproval) {
                    i++;
                }
                if (props?.numberOfInProgress) {
                    i++;
                }
                setMaxWidth((100 - (i * 7)) / 100);
                setOpenLoading(props.loading);
                if (props.totalRecord === 0 && props.keyFulfilmentRequests.length === 0) {
                    console.log(ApplicationConstants.AlertMessages.no)
                    setAlertMessage(ApplicationConstants.AlertMessages.NoRecordsFound);
                    setAlertSelector(2);
                    setOpenAlert(true);
                    setOpenLoading(false);
                }
            }
            setPropsLoading(true);
        }

        
    })

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };


    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            setOpenAlert(false);
            return;
        }

        setOpenAlert(false);
    };

    const handleChangePage = async (event, newPage) => {
        setPage(newPage);
        setSelectedRow([]);
        setCancelReject(false);
    };

    const handleChangeRowsPerPage = async event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setSelectedRow([]);
        setCancelReject(false);
    };

    const handleCancelRejectClick = (action) => {
        if (action !== actionButton) {
            setCancelReject(true);
            setActionButton(action);
            var targetElm;
            setTimeout(() => {
                targetElm = document.querySelector('.commentBox');
                if (targetElm !== null) {
                    console.log(targetElm);
                    targetElm.scrollIntoView({ behavior: 'smooth', block: 'end' });
                }
            }, 50);
        }
        else {
            handleResetCancelReject();
        }
    }

    const handleResetCancelReject = () => {
        setCancelReject(false);
        setActionButton('');

        var targetElm = document.querySelector('#root');
        targetElm.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }

    const handleSnackBarClose = () => {
        setOpenSnackBar(false);
    }

    const handleSelectRow = (row) => {
        handleResetCancelReject();
        if (row === selectedRow) {
            console.log(selectedRow.length)
            setSelectedRow([]);
        } else {
            setSelectedRow(row);
        }
    }

    const handleComment = (event) => {
        setCancelComment(event.target.value);
    }

    const handleApproveOrRejectClick = (action, comment) => {
        var payload;
        if (action.localeCompare(ApplicationConstants.Buttons.Approve) === 0) {
            handleResetCancelReject();
            payload = { KeyFulfilmentRequestId: selectedRow.keyFulfilmentRequestId, ApprovalStatus: ApplicationConstants.DashboardRequestStatus.Approved };
        } else {
            payload = { KeyFulfilmentRequestId: selectedRow.keyFulfilmentRequestId, ApprovalStatus: ApplicationConstants.DashboardRequestStatus.Rejected, Comment: comment };
        }
        ActionClick(payload);
    }

    const handleCancelOrCompletetClick = (action, comment) => {
        var payload;
        if (action.localeCompare(ApplicationConstants.Buttons.Complete) === 0) {
            handleResetCancelReject();
            payload = { KeyFulfilmentRequestId: selectedRow.keyFulfilmentRequestId, WorkFlowStatus: ApplicationConstants.DashboardRequestStatus.Completed };
        } else {
            payload = { KeyFulfilmentRequestId: selectedRow.keyFulfilmentRequestId, WorkFlowStatus: ApplicationConstants.DashboardRequestStatus.Cancelled, Comment: comment };
        }
        ActionClick(payload);
    }

    const handleRetryClick = () => {
        handleResetCancelReject();
        var payload = { KeyFulfilmentRequestId: selectedRow.keyFulfilmentRequestId };
        console.log(selectedRow);
        console.log(payload)
        ActionClick(payload);
    }
    const Sleep = (milliseconds) => {
        const date = Date.now();
        let currentDate = null;
        do {
            currentDate = Date.now();
        } while (currentDate - date < milliseconds);
    }

    const ActionClick = async (payload) => {
        setOpenLoading(true);
        setOpenSnackBar(true);
        setSnackBarMessage(ApplicationConstants.AlertMessages.PleaseWait)
        await ApiCall.CallApiPost(ApplicationConstants.EndPoints.UpdateKeyFulfilmentAsyncEndPoint, payload, props.idToken, props.accessToken)
            .then(async (response) => {
                console.log('logging response in view history');
                console.log(response);
                if (response.errorCode === 0) {
                    Sleep(2000);
                    await FetchKeyFulfilmentRequests({ Limit: 10, PageNumber: 1 }, false);
                    setAlertMessage(ApplicationConstants.AlertMessages.Success);
                    setAlertSelector(1);
                    setOpenAlert(true);
                }
                else {
                    setErrorCode(response.errorCode);
                    setErrorDescription(response.errorDescription);
                    setAlertMessage(ApplicationConstants.AlertMessages.ErrorCode + response.errorCode + ApplicationConstants.AlertMessages.ErrorMessage + response.errorDescription);
                    setAlertSelector(0);
                    setOpenAlert(true);
                }
            })
            .catch(error => {
                setErrorCode(error.status);
                setErrorDescription(error);
                setAlertMessage(ApplicationConstants.AlertMessages.ErrorCode + error.status + ApplicationConstants.AlertMessages.ErrorMessage + error.statusText);
                setAlertSelector(0);
                setOpenAlert(true);
            });
        setOpenLoading(false);
        setOpenSnackBar(false);
        setSelectedRow([]);
        setCancelReject(false);
    }

    const FetchKeyFulfilmentRequests = async (getKeyFulfilmentRequest, refresh) => {
        setOpenLoading(true);
        if (refresh === true) {
            setOpenSnackBar(true);
            setSnackBarMessage(ApplicationConstants.AlertMessages.PleaseWait);
            setPage(0);
        }
        await ApiCall.CallApiGet(ApplicationConstants.EndPoints.GetKeyFulfilmentRequestsEndPoint, getKeyFulfilmentRequest, props.idToken, props.accessToken)
            .then((response) => {
                console.log('logging response in view history');
                console.log(response);
                var totalRecords = response?.keyFulfilmentRequests?.length;
                var noOfWaitingApproval = 0;
                var noOfInError = 0;
                var noOfInProgress = 0;
                var i = 0;
                response.keyFulfilmentRequests.forEach(function (keyFulfilmentRequest) {
                    if (keyFulfilmentRequest.requestStatus.localeCompare(ApplicationConstants.DashboardRequestStatus.InProgress) === 0) {
                        noOfInProgress++;
                    }
                    else if (keyFulfilmentRequest.requestStatus.localeCompare(ApplicationConstants.DashboardRequestStatus.WaitingForApproval) === 0 || keyFulfilmentRequest.approvalStatus.localeCompare(ApplicationConstants.DashboardRequestStatus.Waiting) === 0) {
                        noOfWaitingApproval++;
                    }
                    else if (keyFulfilmentRequest.requestStatus.localeCompare(ApplicationConstants.DashboardRequestStatus.Failed) === 0) {
                        noOfInError++;
                    }
                })
                var i = 0;
                if (noOfInError > 0) {
                    i++;
                }
                if (noOfWaitingApproval) {
                    i++;
                }
                if (noOfInProgress) {
                    i++;
                }
                setMaxWidth((100 - (i * 7)) / 100);
                setKeyFulfilmentRequests(response.keyFulfilmentRequests);
                setTotalRecords(totalRecords);
                setErrorCode(response.errorCode);
                setErrorDescription(response.errorDescription);
                setNumberOfInError(noOfInError);
                setNumberOfInProgress(noOfInProgress);
                setNumberOfWaitingApproval(noOfWaitingApproval);
                setInProcessWidgetWidth((parseInt(noOfInProgress) / parseInt(noOfInProgress + noOfWaitingApproval + noOfInError)) * 100);
                setApprovalWidgetWidth((parseInt(noOfWaitingApproval) / parseInt(noOfInProgress + noOfWaitingApproval + noOfInError)) * 100);
            })
            .catch(error => {
                console.log(error);
                setErrorCode(error.status);
                setErrorDescription(error.statusText);
                setAlertMessage(ApplicationConstants.AlertMessages.ErrorCode + error.status + ApplicationConstants.AlertMessages.ErrorMessage + error.statusText);
                setAlertSelector(0);
                setKeyFulfilmentRequests([]);
                setTotalRecords(0);
                setOpenAlert(true);
            });

        setOpenLoading(false);
        setOpenSnackBar(false);
    }

    //selector = 0 for error
    //selector = 1 for success
    //selector = 2 for info
    const AlertSnackBar = (selector) => (
        <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleAlertClose}>
            {selector === 0 ?
                <Alert onClose={handleAlertClose} severity="error">
                    {alertMessage}
                </Alert>
                : selector === 1 ?
                    <Alert onClose={handleAlertClose} severity="success">
                        {alertMessage}
                    </Alert> :
                    <Alert onClose={handleAlertClose} severity="info">
                        {alertMessage}
                    </Alert>
            }
        </Snackbar>
    )

    const BottomSnackBar = (<Snackbar
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        style={{ 'fontSize': '12px' }}
        open={openSnackBar}
        autoHideDuration={6000}
        message={snackBarMessage}
        action={
            <React.Fragment>
                <IconButton size="large" aria-label="close" color="inherit" onClick={handleSnackBarClose}>
                    <CloseIcon fontSize="large" />
                </IconButton>
            </React.Fragment>
        }
    />
    )

    const visualRequestsContainer = (
        <Card>
            {numberOfInProgress !== 0 || numberOfWaitingApproval !== 0 || numberOfInError !== 0 ? <div className='BarWidget'>
                {numberOfInProgress !== 0 ? <div className='visual-inprogress' style={{ width: ((inProcessWidgetWidth * maxWidth)+7) + '%' }}>
                    {numberOfInProgress !== -1 ? < span className='visual-progress-count'>{numberOfInProgress}</span> : null}
                </div> : null}
                {numberOfWaitingApproval !== 0 ? <div className='visual-progress-approval' style={{ width: ((approvalWidgetWidth * maxWidth) + 7) + '%' }}>
                    {numberOfWaitingApproval !== -1 ? < span className='visual-progress-count'>{numberOfWaitingApproval}</span> : null}
                </div> : null}
                {numberOfInError !== 0 ? <div className='visual-error' style={{ width: (((100 - approvalWidgetWidth - inProcessWidgetWidth) * maxWidth) + 7) + '%' }}>
                    {numberOfInError !== -1 ? < span className='visual-progress-count'>{numberOfInError}</span> : null}
                </div> : null}
            </div> : null}
            <div id='Legend'>
                <div id="colorcode">
                    <StopIcon id="inProgressLegend" />
                    <Typography id='LegendBody' variant="body2" color="textSecondary" component="p">
                        {ApplicationConstants.VisualBar.NumberofInProgessRequests}
                </Typography>
                </div>
                <div id="colorcode">
                    <StopIcon id="approvalLegend" />
                    <Typography id='LegendBody' variant="body2" color="textSecondary" component="p">
                        {ApplicationConstants.VisualBar.NumberofApprovalRequests}
                </Typography>
                </div>
                <div id="colorcode">
                    <StopIcon id="errorLegend" />
                    <Typography id='LegendBody' variant="body2" color="textSecondary" component="p">
                        {ApplicationConstants.VisualBar.NumberofErrorRequests}
                </Typography>
                </div>
            </div>
        </Card>)

    const inProcessRequestTable = (<Card className='CardOverflow'>
        <TableContainer className={classes.container} >
            <Table fixedHeader={true} stickyHeader id="pkpnTable" aria-label="sticky table" className='pendingTable'>
                <TableHead>
                    <TableRow>
                        {ApplicationConstants.TableColumns.ProductKeyDistributionDashboard.InProcessColumns.map(column => (
                            <TableCell id="TableHeader" className="headerBorder"
                                key={column.id}
                                style={{ minWidth: column.minWidth }}
                                sortDirection={orderBy === column.id ? order : false}>
                                {column.id == 'requestStatus' || column.id == 'createdDate' ?
                                    <TableSortLabel
                                        active={orderBy === column.id}
                                        direction={orderBy === column.id ? order : 'asc'}
                                        onClick={createSortHandler(column.id)}
                                    >

                                        {column.label}
                                        {orderBy === column.id ? (
                                            <span className={classes.visuallyHidden}>
                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </span>
                                        ) : null}
                                    </TableSortLabel> :
                                    column.label
                                }
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {keyFulfilmentRequests && keyFulfilmentRequests.length > 0 ?
                            (rowsPerPage > 0
                            ? stableSort(keyFulfilmentRequests, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : keyFulfilmentRequests
                        )
                        .map(row => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} onClick={event => handleSelectRow(row)} selected={row === selectedRow}>
                                    {ApplicationConstants.TableColumns.ProductKeyDistributionDashboard.InProcessColumns.map(column => {
                                        if (((column.id == "createdDate") || (column.id == "lastModifiedDate")) && row[column.id] != null) {
                                            row[column.id] = new Date(row[column.id]).toLocaleString();
                                        }
                                        const value = row[column.id]
                                        return (
                                            <TableCell id="PKPNTableBody" key={column.id}>
                                                {column.format && typeof value === 'number' ? column.format(value) : value}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        }
                        ) :
                        null
                    }
                </TableBody>
            </Table>
        </TableContainer>
        <TablePagination
            rowsPerPageOptions={[10, 20, 30, { label: 'All', value:  totalRecords && totalRecords !== -1 ? totalRecords : 0 }]}
            colSpan={8}
            count={totalRecords && totalRecords !== -1 ? totalRecords : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
        />
    </Card>)

    const commentBox = (
        <div className='commentBox'>
                <FormGroup style={{ width: '27em' }}>
                    <div className='FlexLabel'>
                        <label for="outlined-multiline-static" >{ApplicationConstants.Labels.EnterComments}</label>
                        <Tooltip title={ApplicationConstants.InfoIconsText.RequiredMax256AphaNumeric}>
                            <IconButton aria-label={ApplicationConstants.InfoIconsText.RequiredMax256AphaNumeric}>
                                <InfoIcon style={{ fontSize: 18 }} />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <TextField
                        id="outlined-multiline-static"
                        required
                        label="Comment"
                        margin="normal"
                        variant="outlined"
                        size="medium"
                        inputProps={{ maxLength: '256' }}
                        onChange={event => handleComment(event)}
                        size="medium"
                    />
                    <Button variant="contained" color="primary" style={{ marginTop: "2em", marginBottom: "2em" }} disabled={cancelComment === ''}
                        onClick={event => actionButton.localeCompare(ApplicationConstants.Buttons.Cancel) === 0 ? handleCancelOrCompletetClick(actionButton, cancelComment) : handleApproveOrRejectClick(actionButton, cancelComment)}>
                        {ApplicationConstants.Buttons.Submit}
                    </Button>
                </FormGroup>
        </div>)

    return (
        <div className='RowFlex'>
            <div className='TopTable'>
                {visualRequestsContainer}
                <div className="refreshButtonTopTable">
                <Button variant="contained" color="primary" id='refreshTopTable' onClick={event => FetchKeyFulfilmentRequests({ Limit: rowsPerPage, PageNumber: 1 }, true)}>
                    {ApplicationConstants.Buttons.Refresh}&nbsp;
                    <RefreshIcon fontSize="medium" />
                    </Button>
                </div>
            </div>
            {inProcessRequestTable}
            <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group" style={{ marginTop: '2em' }} disabled={selectedRow.length === 0} className='pkdd-buttonGroup'>
                <Button disabled={Object.entries(selectedRow).length > 0 ? (((selectedRow?.workFlowStatus?.keyMintingStatus?.localeCompare(ApplicationConstants.DashboardRequestStatus.Failed) !== 0) &&
                    (selectedRow?.workFlowStatus?.callbackStatus?.localeCompare(ApplicationConstants.DashboardRequestStatus.Failed) !== 0) && (selectedRow?.workFlowStatus?.rangeAllocationStatus?.localeCompare(ApplicationConstants.DashboardRequestStatus.Failed) !== 0))
                    && ((selectedRow?.workFlowStatus?.keyMintingStatus?.localeCompare(ApplicationConstants.DashboardRequestStatus.NotStarted) !== 0 && selectedRow?.workFlowStatus?.keyMintingStatus?.localeCompare(ApplicationConstants.DashboardRequestStatus.InProgress) !== 0)
                    && (selectedRow?.workFlowStatus?.callbackStatus?.localeCompare(ApplicationConstants.DashboardRequestStatus.NotStarted) !== 0 && selectedRow?.workFlowStatus?.callbackStatus?.localeCompare(ApplicationConstants.DashboardRequestStatus.InProgress) !== 0)
                    && (selectedRow?.workFlowStatus?.rangeAllocationStatus?.localeCompare(ApplicationConstants.DashboardRequestStatus.NotStarted) !== 0 && selectedRow?.workFlowStatus?.rangeAllocationStatus?.localeCompare(ApplicationConstants.DashboardRequestStatus.InProgress) !== 0)
                        || ((new Date().getTime() - new Date(selectedRow.lastModifiedDate).getTime()) / (1000 * 60 )) < 40)) : true}
                    onClick={even => handleRetryClick()}>
                    {ApplicationConstants.Buttons.Retry}
                </Button>

                <Button onClick={event => handleCancelRejectClick(ApplicationConstants.Buttons.Cancel)} disabled={Object.entries(selectedRow).length > 0 ? (((selectedRow?.workFlowStatus?.keyMintingStatus?.localeCompare(ApplicationConstants.DashboardRequestStatus.Failed) !== 0) &&
                    (selectedRow?.workFlowStatus?.keyMintingStatus?.localeCompare(ApplicationConstants.DashboardRequestStatus.WaitingForRanges) !== 0) && (selectedRow?.workFlowStatus?.rangeAllocationStatus?.localeCompare(ApplicationConstants.DashboardRequestStatus.Failed) !== 0)
                    && selectedRow?.workFlowStatus?.rangeAllocationStatus?.localeCompare(ApplicationConstants.DashboardRequestStatus.WaitingForRanges))
                    && ((selectedRow?.workFlowStatus?.keyMintingStatus?.localeCompare(ApplicationConstants.DashboardRequestStatus.NotStarted) !== 0 && selectedRow?.workFlowStatus?.keyMintingStatus?.localeCompare(ApplicationConstants.DashboardRequestStatus.InProgress) !== 0)
                        && (selectedRow?.workFlowStatus?.rangeAllocationStatus?.localeCompare(ApplicationConstants.DashboardRequestStatus.NotStarted) !== 0 && selectedRow?.workFlowStatus?.rangeAllocationStatus?.localeCompare(ApplicationConstants.DashboardRequestStatus.InProgress) !== 0)
                        || ((new Date().getTime() - new Date(selectedRow.lastModifiedDate).getTime()) / (1000 * 60)) < 40)) : true}>
                    {ApplicationConstants.Buttons.Cancel}
                </Button>

                <Button disabled={Object.entries(selectedRow).length > 0 ? (selectedRow?.requestStatus?.localeCompare(ApplicationConstants.DashboardRequestStatus.WaitingForApproval) !== 0
                    || selectedRow?.approvalStatus?.localeCompare(ApplicationConstants.DashboardRequestStatus.Waiting) !== 0)  : true}
                    onClick={event => handleApproveOrRejectClick(ApplicationConstants.Buttons.Approve)}>{ApplicationConstants.Buttons.Approve}</Button>

                <Button onClick={event => handleCancelRejectClick(ApplicationConstants.Buttons.Reject)} disabled={Object.entries(selectedRow).length > 0 ? (selectedRow?.requestStatus?.localeCompare(ApplicationConstants.DashboardRequestStatus.WaitingForApproval) !== 0
                    || selectedRow?.approvalStatus?.localeCompare(ApplicationConstants.DashboardRequestStatus.Waiting) !== 0) : true}>
                    {ApplicationConstants.Buttons.Reject}
                </Button>

                <Button disabled={Object.entries(selectedRow).length > 0 ? ((selectedRow?.workFlowStatus?.keyMintingStatus?.localeCompare(ApplicationConstants.DashboardRequestStatus.Cancelled)
                    && selectedRow?.workFlowStatus?.keyMintingStatus?.localeCompare(ApplicationConstants.DashboardRequestStatus.Completed) !== 0 && selectedRow?.workFlowStatus?.keyMintingStatus?.localeCompare(ApplicationConstants.DashboardRequestStatus.Rejected) !== 0)
                    || (selectedRow?.workFlowStatus?.callbackStatus?.localeCompare(ApplicationConstants.DashboardRequestStatus.Failed) !== 0 &&
                        ((selectedRow?.workFlowStatus?.callbackStatus?.localeCompare(ApplicationConstants.DashboardRequestStatus.NotStarted) !== 0 && selectedRow?.workFlowStatus?.callbackStatus?.localeCompare(ApplicationConstants.DashboardRequestStatus.InProgress) !== 0)
                            || ((new Date().getTime() - new Date(selectedRow.lastModifiedDate).getTime()) / (1000 * 60)) < 40))) : true}
                    onClick={event => handleCancelOrCompletetClick(ApplicationConstants.Buttons.Complete)}>
                    {ApplicationConstants.Buttons.Complete}
                </Button>
            </ButtonGroup>
            {cancelReject ? commentBox : null}
            <Backdrop className={classes.backdrop} open={openLoading} style={{ marginLeft: props.drawerWidth }}>
                <CircularProgress />
            </Backdrop>
            {BottomSnackBar}
            {AlertSnackBar(alertSelector)}
        </div>
    );
}

View.propTypes = {
    error: PropTypes.bool,
    errorDescription: PropTypes.string,
    loading: PropTypes.bool,
    keyFulfilmentRequests: PropTypes.array,
    totalRecord: PropTypes.number,
    responseCount: PropTypes.number,
    numberOfWaitingApproval: PropTypes.number,
    numberOfInError: PropTypes.number,
    numberOfInProgress: PropTypes.number,
    drawerWidth: PropTypes.string
}

export class ProductKeyDistributionDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorCode: 0,
            errorDescription: '',
            loading: true,
            keyFulfilmentRequests: [],
            totalRecord: -1,
            userPrincipalName: this.props.graphProfile.userPrincipalName,
            numberOfWaitingApproval: -1,
            numberOfInError: -1,
            numberOfInProgress: -1,
            idToken: this.props.idToken,
            accessToken: this.props.accessToken,
            drawerWidth: this.props.drawerWidth
        }
    }

    componentDidMount() {
        const values = queryString.parse(this.props?.location?.search);
        console.log(values)
        if (values) {
            var keyFulfilmentRequestId = values.keyFulfilmentRequestId;
            console.log(keyFulfilmentRequestId);
            const keyFulfilmentQueryRequest = { KeyFulfilmentRequestId: keyFulfilmentRequestId };
            this.FetchKeyFulfilmentRequests(keyFulfilmentQueryRequest);
        } else {
            const keyFulfilmentQueryRequest = { Limit: 10, PageNumber: 1};
            this.FetchKeyFulfilmentRequests(keyFulfilmentQueryRequest);
        }
    }

    componentWillUnmount() {
        var targetElm = document.querySelector('#root');
        targetElm.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }

    async FetchKeyFulfilmentRequests(getKeyFulfilmentRequest) {
        await ApiCall.CallApiGet(ApplicationConstants.EndPoints.GetKeyFulfilmentRequestsEndPoint, getKeyFulfilmentRequest, this.props.idToken, this.props.accessToken)
            .then((response) => {
                console.log('logging response in view history');
                console.log(response);
                var totalRecords = response?.keyFulfilmentRequests?.length;
                var noOfWaitingApproval = 0;
                var noOfInError = 0;
                var noOfInProgress = 0;
                response.keyFulfilmentRequests.forEach(function (keyFulfilmentRequest) {
                    if (keyFulfilmentRequest.requestStatus.localeCompare(ApplicationConstants.DashboardRequestStatus.InProgress) === 0) {
                        noOfInProgress++;
                    }
                    else if (keyFulfilmentRequest.requestStatus.localeCompare(ApplicationConstants.DashboardRequestStatus.WaitingForApproval) === 0 || keyFulfilmentRequest.approvalStatus.localeCompare(ApplicationConstants.DashboardRequestStatus.Waiting) === 0) {
                        noOfWaitingApproval++;
                    }
                    else if (keyFulfilmentRequest.requestStatus.localeCompare(ApplicationConstants.DashboardRequestStatus.Failed) === 0) {
                        noOfInError++;
                    }
                })
                this.setState({
                    errorCode: response.errorCode,
                    errorDescription: response.errorDescription,
                    loading: false,
                    keyFulfilmentRequests: response.keyFulfilmentRequests ? response.keyFulfilmentRequests : [],
                    totalRecord: totalRecords,
                    numberOfWaitingApproval: noOfWaitingApproval,
                    numberOfInError: noOfInError,
                    numberOfInProgress: noOfInProgress
                })

            })
            .catch(error => {
                console.log(error);
                this.setState({
                    errorCode: error.status,
                    errorDescription: error.statusText,
                    loading: false,
                    keyFulfilmentRequests: [],
                    totalRecord: 0
                })
            });
    }

    render() {

        return (
            <React.Fragment>
                <View {...this.state} />
            </React.Fragment>
        )
    }
}