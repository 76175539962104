import React, { Component, useState, useEffect } from 'react';
import { Route, Switch } from 'react-router';
import Home from './components/Home/Home';
import AuthProvider from "./authProvider";
import PropTypes from "prop-types";
import TenantIcon from '@material-ui/icons/PersonAddTwoTone';
import DashboardIcon from '@material-ui/icons/DashboardTwoTone';
import ValidationIcon from '@material-ui/icons/DiscFullTwoTone';
import KeyCheckIcon from '@material-ui/icons/PlaylistAddCheckTwoTone';
import ActivitiesIcon from '@material-ui/icons/TrendingUpTwoTone';
import FileDownloadIcon from '@material-ui/icons/DescriptionTwoTone';
import ApplicationConstants from './Utilities/ApplicationConstants';
import { Redirect } from 'react-router-dom'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle'
import { Divider } from '@material-ui/core';
import './App.css';
import WarningIcon from './icons/Warning.png';
import PrivacyIcon from './icons/Privacy.png';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';

const subActionsMenu = [[{ name: 'PK Distribution Request', icon: < div style={{ fontFamily: "Segoe MDL2 Assets", fontSize: '1.5em' }} >&#xE8F3;</div > }, { name: 'PK Validation Request', icon: < div style={{ fontFamily: "Segoe MDL2 Assets", fontSize: '1.5em' }} >&#xE8F3;</div > }],
[{ name: 'Submit Request', icon: < div style={{ fontFamily: "Segoe MDL2 Assets", fontSize: '1.5em' }} >&#xE8F3;</div > },
{ name: 'View Request History', icon: <div style={{ fontFamily: "Segoe MDL2 Assets", fontSize: '1.5em' }} >&#xE81C;</div> }, { name: 'Tool User Guide', icon: <div style={{ fontFamily: "Segoe MDL2 Assets", fontSize: '1.5em' }} >&#xF585;</div> }],
[],
[{ name: 'Office Mac Hash Tool Installation', icon: <div style={{ fontFamily: "Segoe MDL2 Assets", fontSize: '1.5em' }} >&#xE896;</div> }, { name: 'Office Mac Hash Tool User Guide', icon: <div style={{ fontFamily: "Segoe MDL2 Assets", fontSize: '1.5em' }} >&#xF585;</div> },
{ name: 'Key PKPN SN Range Risk Report', icon: <div style={{ fontFamily: "Segoe MDL2 Assets", fontSize: '1.5em' }} >&#xE9F9;</div> }, { name: 'Restart KCT Part Data Service', icon: <div style={{ fontFamily: "Segoe MDL2 Assets", fontSize: '1.5em' }} >&#xEBC4;</div> }],
    [{ name: 'Manage Tenant Profile', icon: <div style={{ fontFamily: "Segoe MDL2 Assets", fontSize: '1.5em' }} >&#xEBD2;</div> }, { name: 'RSM Org Profile', icon: <div style={{ fontFamily: "Segoe MDL2 Assets", fontSize: '1.5em' }} >&#xE7EE;</div> }
        , { name: 'Tool User Guide', icon: <div style={{ fontFamily: "Segoe MDL2 Assets", fontSize: '1.5em' }} >&#xF585;</div> }],
    [],
[]]


const NavBarActions = [
    {
        name: "Request Dashboard", icon: <DashboardIcon style={{ fontSize: 40 }} />
    },
    {
        name: "Product Key Validation Tool", icon: <ValidationIcon color='primary' style={{ fontSize: 40 }} />
    },
    {
        name: "Key Check Tool", icon: <KeyCheckIcon color='primary' style={{ fontSize: 40 }} />
    },
    {
        name: "SLK Activities", icon: <ActivitiesIcon color='primary' style={{ fontSize: 40 }} />
    },
    {
        name: "Tenant Onboarding Tool", icon: <TenantIcon color='primary' style={{ fontSize: 40 }} />
    },
    {
        name: "PKPN Hash File Download", icon: <FileDownloadIcon style={{ fontSize: 40 }} />
    },
    {
        name: "Privacy Policy", icon: <img src={PrivacyIcon} alt="Privacy Icon" style={{ height: '1.8em' }} />, link: "/privacypolicy"
    }]

function AlertDialog(props) {
    const [open, setOpen] = React.useState(false);
    const [invokedUseEffect, setInvokedUseEffect] = React.useState(false);

    useEffect(() => {
        if (props.sessionTimeOut !== null && !invokedUseEffect) {
            setInvokedUseEffect(true);
            if (props.sessionTimeOut > 0) {
                setTimeout(() => setOpen(true), props.sessionTimeOut);
            }
            else {
                setOpen(true);
            }
        }
    })

    const handleRefresh = () => {
        setOpen(false);
        sessionStorage.clear();
        window.location.reload();
    }

    const alertDialogBox = (
        <Dialog
            open={open}
            onClose={event => setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            style={{ fontFamily: '"Segoe UI VSS (Regular)", "Segoe UI", "-apple-system", BlinkMacSystemFont, Roboto, "Helvetica Neue", Helvetica, Ubuntu, Arial, sans- serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"'}}
        >
            <DialogTitle id="alert-dialog-title">
                <img src={WarningIcon} className='warningicon' />
                {ApplicationConstants.Information.SessionExpired}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {ApplicationConstants.Information.SessionExpiredInfo}
                </DialogContentText>
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button variant="contained" style={{
                    background: "#ff9800", textTransform: 'none',
                    fontFamily: '"Segoe UI VSS (Regular)", "Segoe UI", "-apple-system", BlinkMacSystemFont, Roboto, "Helvetica Neue", Helvetica, Ubuntu, Arial, sans- serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                fontWidth:'400', fontSize:'1em'}}
                onClick={handleRefresh} color="primary" autoFocus>
                    {ApplicationConstants.Buttons.Refresh}
                </Button>
            </DialogActions>
        </Dialog>
    )
    return (<div>
        {alertDialogBox}
    </div>
        )
}

class App extends React.Component {
    static displayName = App.name;

    static propTypes = {
        account: PropTypes.object,
        emailMessages: PropTypes.object,
        error: PropTypes.string,
        graphProfile: PropTypes.object,
        onSignIn: PropTypes.func.isRequired,
        onSignOut: PropTypes.func.isRequired,
        onRequestEmailToken: PropTypes.func.isRequired,
        idToken: PropTypes.string,
        userPhoto: PropTypes.object,
        accessToken: PropTypes.string
    };

    constructor(props) {
        super(props);
        this.state = {
            isAuthenticated: false,
            user: {},
            error: null,
            handleClose: false,
            open: this.props.sessionTimeOut 
        };
    }
    componentDidMount() {
        console.log(this.props)
        if (this.props.account != null) {
            this.setState({
                isAuthenticated: true
            })
        }
        this.logOut();
    }
    logOut() {
        window.addEventListener("beforeunload", function (e) {
            this.props.onSignOut();
        })
    }

    render() {
        return (
            <React.Fragment>
                {this.props.graphProfile !== null && this.props.idToken !== null && this.props.isAuthorized?
                    <React.Fragment>
                        <Switch>
                            <Route exact path='/' render={props =>
                                <Redirect to={'/' + NavBarActions[0].name.split(" ").join("").toLowerCase() + "/" + subActionsMenu[0][0].name.split(" ").join("").toLowerCase()} />}/>
                            {subActionsMenu[0].map((subAction, subIndex) => (
                                <Route path={"/" + NavBarActions[0].name.split(" ").join("").toLowerCase() + "/" + subAction.name.split(" ").join("").toLowerCase()}
                                    render={props => <Home {...this.props} actionSelected={0} subActionSelected={subIndex} tabAccess={ApplicationConstants.SecurityGroupDetails[ApplicationConstants.EnvironmentType][0].isAuthorized} location={props.location}/>}/>
                            ))
                            }
                            {subActionsMenu[1].map((subAction, subIndex) => (
                                <Route path={"/" + NavBarActions[1].name.split(" ").join("").toLowerCase() + "/" + subAction.name.split(" ").join("").toLowerCase()}
                                    render={props => <Home {...this.props} actionSelected={1} subActionSelected={subIndex} tabAccess={ApplicationConstants.SecurityGroupDetails[ApplicationConstants.EnvironmentType][1].isAuthorized}/>} />
                            ))
                            }
                            {subActionsMenu[3].map((subAction, subIndex) => (
                                <Route path={"/" + NavBarActions[3].name.split(" ").join("").toLowerCase() + "/" + subAction.name.split(" ").join("").toLowerCase()}
                                    render={props => <Home {...this.props} actionSelected={3} subActionSelected={subIndex} tabAccess={ApplicationConstants.SecurityGroupDetails[ApplicationConstants.EnvironmentType][3].isAuthorized}/>} />
                            ))
                            }
                            {subActionsMenu[4].map((subAction, subIndex) => (
                                <Route path={"/" + NavBarActions[4].name.split(" ").join("").toLowerCase() + "/" + subAction.name.split(" ").join("").toLowerCase()}
                                    render={props => <Home {...this.props} actionSelected={4} subActionSelected={subIndex} tabAccess={ApplicationConstants.SecurityGroupDetails[ApplicationConstants.EnvironmentType][4].isAuthorized}/>} />
                            ))
                            }
                            <Route path={'/' + NavBarActions[2].name.split(" ").join("").toLowerCase()} render={props => <Home {...this.props} actionSelected={2} subActionSelected={0} tabAccess={ApplicationConstants.SecurityGroupDetails[ApplicationConstants.EnvironmentType][2].isAuthorized}/>} />

                            <Route path={'/' + NavBarActions[5].name.split(" ").join("").toLowerCase()}
                                render={props => <Home {...this.props} actionSelected={5} subActionSelected={0} tabAccess={ApplicationConstants.SecurityGroupDetails[ApplicationConstants.EnvironmentType][5].isAuthorized} location={props.location} />} />

                            <Route path={'/' + NavBarActions[6].name.split(" ").join("").toLowerCase()}
                                render={props => <Home {...this.props} actionSelected={6} subActionSelected={0} tabAccess={ApplicationConstants.SecurityGroupDetails[ApplicationConstants.EnvironmentType][6].isAuthorized} location={props.location} />} />
                        </Switch>
                    </React.Fragment>
                    : null}
                <AlertDialog sessionTimeOut={this.props.sessionTimeOut} />
            </React.Fragment>
        );
    }
}

export default AuthProvider(App);
