import React, { Component, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import { Backdrop, CircularProgress, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Button, TablePagination, TableSortLabel, Checkbox } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import './HashFileDownload.css';
import PropTypes from 'prop-types';
import { ApiCall } from '../../Utilities/ApiCall';
import ApplicationConstants from '../../Utilities/ApplicationConstants';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import MuiAlert from '@material-ui/lab/Alert';
import StorageAccount from '../../icons/StorageAccount.png';
import FolderIcon from '../../icons/FolderIcon.png';
import FileIcon from '../../icons/FileIcon.png';
import DownloadIcon from '../../icons/DownloadIcon.png';
import * as FileSaver from 'file-saver';
import queryString from 'query-string';
import { checkMemberGroup } from '../../auth-utils';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const useStyles = makeStyles(theme => ({
    title: {
        fontFamily: '"Segoe UI VSS (Regular)","Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
        color: 'black',
        fontWeight: 450,
        fontSize: '18px',
    },
    container: {
        maxHeight: '41em',
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

const useStyles1 = makeStyles(theme => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = event => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = event => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = event => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = event => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

function View(props) {

    const classes = useStyles();
    const theme = useTheme();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(15);
    const [selectedRow, setSelectedRow] = React.useState([]);
    const [cancelReject, setCancelReject] = React.useState(false);
    const [cancelComment, setCancelComment] = React.useState('');
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('createdDate');
    const [hashFileTableRows, setHashFileTableRows] = React.useState(ApplicationConstants.TableRows.HashFileDownload.conatinerNameRows);
    const [containerName, setContainerName] = React.useState('');
    const [blobName, setBlobName] = React.useState('');
    const [folderName, setFolderName] = React.useState('');
    const [errorCode, setErrorCode] = React.useState(0);
    const [errorDescription, setErrorDescription] = React.useState('');
    const [numberOfInError, setNumberOfInError] = React.useState(props.numberOfInError);
    const [openLoading, setOpenLoading] = React.useState(props.loading);
    const [openSnackBar, setOpenSnackBar] = React.useState(false);
    const [snackBarMessage, setSnackBarMessage] = React.useState('');
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertSelector, setAlertSelector] = React.useState(0);
    const [openAlert, setOpenAlert] = React.useState(false);
    const [iconComponenet, setIconComponent] = React.useState(<img src={StorageAccount} style={{ height: '1.8em' }} className='hfd-icon' />);
    const [securityGroupDetails, setSecurityGroupDetails] = React.useState([]);
    const [totalNumberOfRows, setTotalNumberOfRows] = React.useState(0);
    const [folderPage, setFolderPage] = React.useState(0);
    const [selectedFiles, setSelectedFiles] = React.useState([]);
    const [securityGroupCheck, setSecurityGroupCheck] = React.useState(false);

    useEffect(() => {
        if (props.containerName && props.hashContact && props.dateStamp && securityGroupCheck === false) {
            console.log('Enter');
            var userHasAccess = CheckSecurityGroupInfo({ ContainerName: props.containerName }, props.hashContact);
            console.log(userHasAccess);
            if (userHasAccess) {
                setContainerName(props.containerName);
                setBlobName(props.hashContact);
                //setFolderName(props.dateStamp);
                FetchHashFileData({ ContainerName: props.containerName, PathPrefix: props.hashContact.toLowerCase() + '/' + props.dateStamp }, props.dateStamp);

            }
            setSecurityGroupCheck(true);
        }
    })
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };


    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            setOpenAlert(false);
            return;
        }

        setOpenAlert(false);
    };

    const handleChangePage = async (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = async event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSnackBarClose = () => {
        setOpenSnackBar(false);
    }

    const createDateRange = () => {
        var tempHashTableRow = [];
        var endDate = new Date();
        var startDate = new Date();
        startDate.setDate(endDate.getDate() - 60);
        while (endDate >= startDate) {
            tempHashTableRow.push({ name: endDate.getFullYear() + '_' + String(endDate.getMonth() + 1).padStart(2, '0') + '_' + String(endDate.getDate()).padStart(2, '0') });
            endDate.setDate(endDate.getDate() - 1)
        }
        setHashFileTableRows(tempHashTableRow);
        setTotalNumberOfRows(tempHashTableRow.length);
    }

    const handleSelectRow = async (row) => {
        if (containerName.length === 0) {
            await FetchSecurityGroupInfo({ ContainerName: row.name })
        }
        else if (blobName.length === 0) {
            setBlobName(row.name);
            createDateRange();
        }
        else if (folderName.length === 0) {
            await FetchHashFileData({ ContainerName: containerName, PathPrefix: blobName.toLowerCase() + '/' + row.name }, row.name);
        }
        else if (folderName.length !== 0) {

            const selectedIndex = selectedFiles.indexOf(row);
            let newSelected = [];

            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selectedFiles, row);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selectedFiles.slice(1));
            } else if (selectedIndex === selectedFiles.length - 1) {
                newSelected = newSelected.concat(selectedFiles.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selectedFiles.slice(0, selectedIndex),
                    selectedFiles.slice(selectedIndex + 1),
                );
            }

            setSelectedFiles(newSelected);
        }
    }

    const handleBack = () => {
        if (folderName.length !== 0) {
            setIconComponent(< img src={FolderIcon} style={{ height: '1.8em' }} className='hfd-icon' />);
            setPage(folderPage);
            createDateRange();
            setSelectedFiles([]);
            setFolderName('');
        } else if (blobName.length !== 0) {
            setHashFileTableRows(securityGroupDetails);
            setBlobName('');
        } else if (containerName.length !== 0) {
            setHashFileTableRows(ApplicationConstants.TableRows.HashFileDownload.conatinerNameRows);
            setIconComponent(<img src={StorageAccount} style={{ height: '1.8em' }} className='hfd-icon' />);
            setContainerName('');
        }
    }

    const handleLocationNavigation = (name) => {
        setPage(0);
        setSelectedFiles([]);
        if (ApplicationConstants.Labels.Container === name) {
            setIconComponent(< img src={StorageAccount} style={{ height: '1.8em' }} className='hfd-icon' />);
            setHashFileTableRows(ApplicationConstants.TableRows.HashFileDownload.conatinerNameRows);
            setFolderName('');
            setBlobName('');
            setContainerName('');
        } else if (blobName === name) {
            setIconComponent(< img src={FolderIcon} style={{ height: '1.8em' }} className='hfd-icon' />);
            setFolderName('');
            createDateRange();
        } else if (containerName === name) {
            setHashFileTableRows(securityGroupDetails);
            setIconComponent(< img src={FolderIcon} style={{ height: '1.8em' }} className='hfd-icon' />);
            setBlobName('');
            setFolderName('');
        }
    }

    const isSelected = (row) => selectedFiles.indexOf(row) !== -1;

    const CheckSecurityGroupInfo = async (getSecurityGroupInfo, sgName) => {
        setOpenLoading(true);
        var userMemberOfSecurityGroup = false;
        await ApiCall.CallApiGet(ApplicationConstants.EndPoints.GetSecurityGroupInfoHashFileDownloadEndPoint, getSecurityGroupInfo, props.idToken, props.accessToken)
            .then((response) => {
                setErrorCode(0);
                if (response.securityGroupNameList) {
                    response.securityGroupNameList.forEach(function (securityGroupName, index) {
                        if (sgName === securityGroupName) {
                            userMemberOfSecurityGroup = true;
                        }
                    })
                }
                else {
                    setHashFileTableRows([]);
                    setAlertMessage(ApplicationConstants.AlertMessages.NoFoldersFound);
                    setAlertSelector(2);
                    setOpenAlert(true);
                    setTotalNumberOfRows(0);
                    userMemberOfSecurityGroup = false;
                }
            })
            .catch(error => {
                console.log(error);
                setAlertMessage(ApplicationConstants.AlertMessages.ErrorCode + error.status + ApplicationConstants.AlertMessages.ErrorMessage + error.statusText);
                setAlertSelector(0);
                setOpenAlert(true);
                setErrorCode(error.status);
                userMemberOfSecurityGroup = false;
            });
        setOpenLoading(false);
        return userMemberOfSecurityGroup;
    }

    const FetchSecurityGroupInfo = async (getSecurityGroupInfo) => {
        setOpenLoading(true);
        await ApiCall.CallApiGet(ApplicationConstants.EndPoints.GetSecurityGroupInfoHashFileDownloadEndPoint, getSecurityGroupInfo, props.idToken, props.accessToken)
            .then((response) => {
                console.log('logging response in view history');
                console.log(response);
                setErrorCode(0);
                setContainerName(getSecurityGroupInfo.ContainerName);
                if (response.securityGroupNameList) {
                    var tempSecurityGroupNameList = [];
                    response.securityGroupNameList.forEach(function (securityGroupName, index) {
                        var tempName = { name: securityGroupName };
                        tempSecurityGroupNameList.push(tempName);
                    })
                    setHashFileTableRows(tempSecurityGroupNameList);
                    setSecurityGroupDetails(tempSecurityGroupNameList);
                    setIconComponent(< img src={FolderIcon} style={{ height: '1.8em' }} className='hfd-icon' />);
                }
                else {
                    setHashFileTableRows([]);
                    setAlertMessage(ApplicationConstants.AlertMessages.NoFoldersFound);
                    setAlertSelector(2);
                    setOpenAlert(true);
                    setTotalNumberOfRows(0);
                }
            })
            .catch(error => {
                console.log(error);
                setAlertMessage(ApplicationConstants.AlertMessages.ErrorCode + error.status + ApplicationConstants.AlertMessages.ErrorMessage + error.statusText);
                setAlertSelector(0);
                setOpenAlert(true);
                setErrorCode(error.status);
            });
        setOpenLoading(false);
    }

    const FetchHashFileData = async (getSecurityGroupInfo, folderName) => {
        setOpenLoading(true);
        await ApiCall.CallApiGet(ApplicationConstants.EndPoints.ListHashFilesForBlobEndPoint, getSecurityGroupInfo, props.idToken, props.accessToken)
            .then((response) => {
                console.log('logging response in view history');
                console.log(response);
                setErrorCode(0);
                if (response.length !== 0) {
                    var tempFileNameList = [];
                    response.forEach(function (fileName, index) {
                        var tempName = { name: fileName.name.split('/')[2] };
                        tempFileNameList.push(tempName);
                    })
                    setHashFileTableRows(tempFileNameList);
                    setTotalNumberOfRows(tempFileNameList.length);
                    setIconComponent(< img src={FileIcon} style={{ height: '1.8em' }} className='hfd-icon' />);
                    setFolderPage(page);
                    setPage(0);
                }
                else {
                    setHashFileTableRows([]);
                    setAlertMessage(ApplicationConstants.AlertMessages.NoHashFilesFound);
                    setAlertSelector(2);
                    setOpenAlert(true);
                    setPage(0);
                    setFolderPage(page);
                    setTotalNumberOfRows(0);
                }
            })
            .catch(error => {
                console.log(error);
                setAlertMessage(ApplicationConstants.AlertMessages.ErrorCode + error.status + ApplicationConstants.AlertMessages.ErrorMessage + error.statusText);
                setAlertSelector(0);
                setOpenAlert(true);
                setErrorCode(error.status);
            });

        setFolderName(folderName);
        setOpenLoading(false);
    }

    const handleHashFileDownload = async (fileName, optionSelector) => {
        setOpenLoading(true);
        if (optionSelector.localeCompare(ApplicationConstants.Options.DownloadAll) === 0) {
            var payload = { ContainerName: containerName, PathPrefix: blobName.toLowerCase() + '/' + fileName };
            await ApiCall.CallApiGet(ApplicationConstants.EndPoints.GetFolderDataForDownloadEndPoint, payload, props.idToken, props.accessToken)
                .then((response) => {
                    setErrorCode(0);
                    console.log('logging response in view history');
                    console.log(response);
                    if (response.fileDataBase64) {
                        const fileType = 'application/zip';
                        const fileExtension = '.zip';
                        const buf = Buffer.from(response.fileDataBase64, 'base64');
                        const data = new Blob([buf], { type: fileType });
                        FileSaver.saveAs(data, fileName.split('.')[0] + fileExtension);
                    } else {
                        setAlertMessage(ApplicationConstants.AlertMessages.NoHashFilesFound);
                        setAlertSelector(2);
                        setOpenAlert(true);
                    }
                }
                )
                .catch(error => {
                    console.log(error);
                    setAlertMessage(ApplicationConstants.AlertMessages.ErrorCode + error.status + ApplicationConstants.AlertMessages.ErrorMessage + error.statusText);
                    setAlertSelector(0);
                    setOpenAlert(true);
                    setErrorCode(error.status);
                });
        }
        else if (optionSelector.localeCompare(ApplicationConstants.Options.DownloadSingle) === 0) {
            var payload = { ContainerName: containerName, PathPrefix: blobName.toLowerCase() + '/' + folderName, FileNames: [fileName] };
            await ApiCall.CallApiGet(ApplicationConstants.EndPoints.GetFileDataForDownloadEndPoint, payload, props.idToken, props.accessToken)
                .then((response) => {
                    setErrorCode(0);
                    console.log('logging response in view history');
                    console.log(response);
                    const fileType = 'application/zip';
                    const fileExtension = '.zip';
                    const buf = Buffer.from(response.fileDataBase64, 'base64');
                    const data = new Blob([buf], { type: fileType });
                    FileSaver.saveAs(data, fileName.split('.')[0] + fileExtension);
                }
                )
                .catch(error => {
                    console.log(error);
                    setAlertMessage(ApplicationConstants.AlertMessages.ErrorCode + error.status + ApplicationConstants.AlertMessages.ErrorMessage + error.statusText);
                    setAlertSelector(0);
                    setOpenAlert(true);
                    setErrorCode(error.status);
                });
        }
        else if (optionSelector.localeCompare(ApplicationConstants.Options.DownloadSelected) === 0) {
            var selectedFileNames = [];
            selectedFiles.forEach(function (selectedFile) {
                selectedFileNames.push(selectedFile.name);
            })
            var payload = { ContainerName: containerName, PathPrefix: blobName.toLowerCase() + '/' + folderName, FileNames: selectedFileNames };
            await ApiCall.CallApiGet(ApplicationConstants.EndPoints.GetFileDataForDownloadEndPoint, payload, props.idToken, props.accessToken)
                .then((response) => {
                    setErrorCode(0);
                    console.log('logging response in view history');
                    console.log(response);
                    const fileType = 'application/zip';
                    const fileExtension = '.zip';
                    const buf = Buffer.from(response.fileDataBase64, 'base64');
                    const data = new Blob([buf], { type: fileType });
                    FileSaver.saveAs(data, folderName + fileExtension);
                }
                )
                .catch(error => {
                    console.log(error);
                    setAlertMessage(ApplicationConstants.AlertMessages.ErrorCode + error.status + ApplicationConstants.AlertMessages.ErrorMessage + error.statusText);
                    setAlertSelector(0);
                    setOpenAlert(true);
                    setErrorCode(error.status);
                });
        }
        setOpenLoading(false);
    }

    //selector = 0 for error
    //selector = 1 for success
    //selector = 2 for info
    const AlertSnackBar = (selector) => (
        <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleAlertClose}>
            {selector === 0 ?
                <Alert onClose={handleAlertClose} severity="error">
                    {alertMessage}
                </Alert>
                : selector === 1 ?
                    <Alert onClose={handleAlertClose} severity="success">
                        {alertMessage}
                    </Alert> :
                    <Alert onClose={handleAlertClose} severity="info">
                        {alertMessage}
                    </Alert>
            }
        </Snackbar>
    )

    const BottomSnackBar = (<Snackbar
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        style={{ 'fontSize': '12px' }}
        open={openSnackBar}
        autoHideDuration={6000}
        message={snackBarMessage}
        action={
            <React.Fragment>
                <IconButton size="large" aria-label="close" color="inherit" onClick={handleSnackBarClose}>
                    <CloseIcon fontSize="large" />
                </IconButton>
            </React.Fragment>
        }
    />
    )

    const hashFileDownloadTable = (<Card className='CardOverflow'>
        <TableContainer className={classes.container} >
            <Table id="pkpnTable" stickyHeader aria-label="sticky table" className='hashTable'>
                <TableHead>
                    <TableRow>
                        {folderName.length !== 0 ?
                            <TableCell padding="checkbox" id="TableHeader" size="small" className="headerBorder">
                            </TableCell>
                            : null
                        }
                        {ApplicationConstants.TableColumns.HashFileDownload.hashFileTableColumn.map(column => (
                            <React.Fragment>
                                <TableCell id="TableHeader" className="headerBorder"
                                    key={column.id}
                                    align={column.align}
                                    sortDirection={orderBy === column.id ? order : false}>

                                    <TableSortLabel
                                        active={orderBy === column.id}
                                        direction={orderBy === column.id ? order : 'asc'}
                                        onClick={createSortHandler(column.id)}
                                    >

                                        {column.label}
                                        {orderBy === column.id ? (
                                            <span className={classes.visuallyHidden}>
                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </span>
                                        ) : null}
                                    </TableSortLabel>

                                </TableCell >
                            </React.Fragment>
                        ))}


                        {blobName.length !== 0 ?
                            <TableCell id="TableHeader" className="headerBorder" /> :
                            null
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {containerName && containerName.length !== 0 ?
                        <TableRow hover tabIndex={-1} >
                            {folderName.length !== 0 ?
                                <TableCell padding="checkbox">
                                </TableCell>
                                : null
                            }
                            <TableCell id="HFDTableBody" onClick={event => handleBack()}>
                                < img src={FolderIcon} style={{ height: '1.8em' }} className='hfd-icon' />
                                [..]
                            </TableCell>

                            {blobName.length !== 0 ?
                                <TableCell id="HFDTableBody">
                                </TableCell> : null}
                        </TableRow> : null
                    }
                    {(blobName.length !== 0
                        ? stableSort(hashFileTableRows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : hashFileTableRows
                    ).map(row => {

                        const isItemSelected = isSelected(row);
                        return (
                            <TableRow tabIndex={-1} selected={isItemSelected}>
                                {ApplicationConstants.TableColumns.HashFileDownload.hashFileTableColumn.map(column => {
                                    if ((column.id == "createdDate") && row[column.id] != null) {
                                        row[column.id] = new Date(row[column.id]).toLocaleString();
                                    }
                                    const value = row[column.id];
                                    return (
                                        <React.Fragment>

                                            {folderName.length !== 0 ?
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        checked={isItemSelected}
                                                        onClick={event => handleSelectRow(row)}
                                                    />
                                                </TableCell>
                                                : null
                                            }
                                            <TableCell hover id="HFDTableBody" key={column.id} align={column.align} onClick={event => handleSelectRow(row)} className="HFDTableBody" >
                                                {iconComponenet}
                                                {column.format && typeof value === 'number' ? column.format(value) : value}
                                            </TableCell>

                                            {blobName.length !== 0 && folderName.length === 0 ?
                                                <TableCell hover id="HFDTableBody" align="center">
                                                    <Button onClick={event => handleHashFileDownload(row.name, ApplicationConstants.Options.DownloadAll)}>
                                                        {ApplicationConstants.Labels.Download}
                                                        < img src={DownloadIcon} style={{ height: '1.8em' }} className='hfd-download-icon' />
                                                    </Button>
                                                </TableCell> :

                                                folderName.length !== 0 ?
                                                    <TableCell hover id="HFDTableBody" align="center">
                                                        <Button onClick={event => handleHashFileDownload(row.name, ApplicationConstants.Options.DownloadSingle)} disabled={Array.isArray(selectedFiles) && selectedFiles.length !== 0}>
                                                            {ApplicationConstants.Labels.Download}
                                                            {Array.isArray(selectedFiles) && selectedFiles.length !== 0 ? null : < img src={DownloadIcon} style={{ height: '1.8em' }} className='hfd-download-icon' />}
                                                        </Button>
                                                    </TableCell>
                                                    :

                                                    null
                                            }
                                        </React.Fragment>
                                    );
                                })}
                            </TableRow>
                        );
                    })
                    }
                </TableBody>
            </Table>
        </TableContainer>
        {blobName.length !== 0 ?
            <TablePagination
                rowsPerPageOptions={[15, 30, 60, { label: 'All', value: totalNumberOfRows }]}
                colSpan={folderName.length !== 0 ? 3 : 1}
                count={totalNumberOfRows}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
            /> : null}
    </Card>)

    const TopNavigation = (<div className='hfd-top-bar'>
        <div className='hfd-top-navigation'>
            <div className='hfd-top-link' className='hfd-location-tab'>
                {ApplicationConstants.Labels.Location}
            </div>
            <div className='hfd-top-link' onClick={event => handleLocationNavigation(ApplicationConstants.Labels.Container)}>
                {ApplicationConstants.Labels.Container}
            </div>&nbsp;
                    <div className='hfd-top-link' onClick={event => handleLocationNavigation(containerName)}>
                {containerName.length !== 0 ? '/ ' + containerName : null}
            </div>&nbsp;
                    <div className='hfd-top-link' onClick={event => handleLocationNavigation(blobName)}>
                {blobName.length !== 0 && containerName.length !== 0 ? ' / ' + blobName : null}
            </div>&nbsp;
                    <div>
                {folderName.length !== 0 && blobName.length !== 0 && containerName.length !== 0 ? ' / ' + folderName : null}
            </div>
        </div>
        <div>
            {Array.isArray(selectedFiles) && selectedFiles.length !== 0 ?
                <Button variant="contained" onClick={event => handleHashFileDownload(null, ApplicationConstants.Options.DownloadSelected)}>
                    {ApplicationConstants.Labels.DownloadSelected}
                    < img src={DownloadIcon} style={{ height: '1.8em' }} className='hfd-download-icon' />
                </Button>
                : null
            }
        </div>
    </div>);

    return (
        <div className='hfd-RowFlex'>
            {TopNavigation}
            <div className='hfd-rowflex-section'>
                {hashFileDownloadTable}
                <div className='hfd-bottom-info'>
                    <b>{ApplicationConstants.Labels.Important}</b>
                    {ApplicationConstants.Information.HashFileDownloadInformation}
                </div>
            </div>
            <Backdrop className={classes.backdrop} open={openLoading} style={{ marginLeft: props.drawerWidth }} >
                <CircularProgress />
            </Backdrop>
            {BottomSnackBar}
            {AlertSnackBar(alertSelector)}
        </div>
    );
}

View.propTypes = {
    error: PropTypes.bool,
    errorDescription: PropTypes.string,
    loading: PropTypes.bool,
    keyFulfilmentRequests: PropTypes.array,
    totalRecord: PropTypes.number,
    responseCount: PropTypes.number,
    numberOfWaitingApproval: PropTypes.number,
    numberOfInError: PropTypes.number,
    numberOfInProgress: PropTypes.number,
    containerName: PropTypes.string,
    hashContact: PropTypes.string,
    dateStamp: PropTypes.string,
    drawerWidth: PropTypes.string
}

export class HashFileDownload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorCode: 0,
            errorDescription: '',
            loading: false,
            userPrincipalName: this.props.graphProfile.userPrincipalName,
            idToken: this.props.idToken,
            accessToken: this.props.accessToken,
            containerName: '',
            hashContact: '',
            dateStamp: '',
            drawerWidth: this.props.drawerWidth
        }
    }

    componentDidMount() {
        console.log(this.props?.location?.search)
        const values = queryString.parse(this.props?.location?.search);
        if (values) {
            this.setState({ containerName: values.containerName?.toLowerCase(), hashContact: values.hashContact, dateStamp: values.dateStamp })
        }
    }

    render() {

        return (
            <React.Fragment>
                <View {...this.state} />
            </React.Fragment>
        )
    }
}