import React, { Component, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent'
import { Backdrop, CircularProgress, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Button, Typography, ButtonGroup, TableFooter, TablePagination, ClickAwayListener, FormGroup, TextField, Tooltip, TableSortLabel, Checkbox } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import './KeyCheckTool.css';
import PropTypes from 'prop-types';
import { ApiCall } from '../../Utilities/ApiCall';
import ApplicationConstants from '../../Utilities/ApplicationConstants';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import MuiAlert from '@material-ui/lab/Alert';
import MsiIcon from '../../icons/MsiIcon.png';
import DownloadIcon from '../../icons/DownloadIcon.png';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import * as FileSaver from 'file-saver';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
    title: {
        fontFamily: '"Segoe UI VSS (Regular)","Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
        color: 'black',
        fontWeight: 450,
        fontSize: '18px',
    },
    container: {
        maxHeight: '48em',
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

function View(props) {

    const classes = useStyles();
    const theme = useTheme();
    const [page, setPage] = React.useState(0);
    const [errorCode, setErrorCode] = React.useState(0);
    const [errorDescription, setErrorDescription] = React.useState('');
    const [openLoading, setOpenLoading] = React.useState(props.loading);
    const [openSnackBar, setOpenSnackBar] = React.useState(false);
    const [snackBarMessage, setSnackBarMessage] = React.useState('');
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertSelector, setAlertSelector] = React.useState(0);
    const [openAlert, setOpenAlert] = React.useState(false);
    const [iconComponenet, setIconComponent] = React.useState(<img src={MsiIcon} style={{ height: '1.8em' }} className='hfd-icon' />);
    const [selectedFiles, setSelectedFiles] = React.useState([]);

    useEffect(() => {
    })

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            setOpenAlert(false);
            return;
        }

        setOpenAlert(false);
    };

    const handleSnackBarClose = () => {
        setOpenSnackBar(false);
    }

    const isSelected = (row) => selectedFiles.indexOf(row) !== -1;

    const downloadSelectedTool = async (toolName) => {
        setOpenLoading(true);
        var fileName;
        for (var i = 0; i < ApplicationConstants.TableRows.KeyCheckTool.ToolsDownloadTableRows.length; i++) {
            if (ApplicationConstants.TableRows.KeyCheckTool.ToolsDownloadTableRows[i].toolName === toolName) {
                fileName = ApplicationConstants.TableRows.KeyCheckTool.ToolsDownloadTableRows[i].fileName;
                break;
            }
        }
        var payload = { ToolName: fileName };
        await ApiCall.CallApiGet(ApplicationConstants.EndPoints.DownloadKCTToolFromBlobEndPoint, payload, props.idToken, props.accessToken)
            .then((response) => {
                setErrorCode(0);
                console.log('logging response in view history');
                console.log(response);
                if (response.fileDataBase64) {
                    const fileType = 'application/zip';
                    const fileExtension = '.zip';
                    const buf = Buffer.from(response.fileDataBase64, 'base64');
                    const data = new Blob([buf], { type: fileType });
                    FileSaver.saveAs(data, toolName + fileExtension);
                } else {
                    setAlertMessage(ApplicationConstants.AlertMessages.UnknownErrorOccured);
                    setAlertSelector(0);
                    setOpenAlert(true);
                }
            }
            )
            .catch(error => {
                console.log(error);
                setAlertMessage(ApplicationConstants.AlertMessages.ErrorCode + error.status + ApplicationConstants.AlertMessages.ErrorMessage + error.statusText);
                setAlertSelector(0);
                setOpenAlert(true);
                setErrorCode(error.status);
            });
        setOpenLoading(false);
    }

    //selector = 0 for error
    //selector = 1 for success
    //selector = 2 for info
    const AlertSnackBar = (selector) => (
        <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleAlertClose}>
            {selector === 0 ?
                <Alert onClose={handleAlertClose} severity="error">
                    {alertMessage}
                </Alert>
                : selector === 1 ?
                    <Alert onClose={handleAlertClose} severity="success">
                        {alertMessage}
                    </Alert> :
                    <Alert onClose={handleAlertClose} severity="info">
                        {alertMessage}
                    </Alert>
            }
        </Snackbar>
    )

    const BottomSnackBar = (<Snackbar
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        style={{ 'fontSize': '12px' }}
        open={openSnackBar}
        autoHideDuration={6000}
        message={snackBarMessage}
        action={
            <React.Fragment>
                <IconButton size="large" aria-label="close" color="inherit" onClick={handleSnackBarClose}>
                    <CloseIcon fontSize="large" />
                </IconButton>
            </React.Fragment>
        }
    />
    )

    const ToolsDownloadTable = (<Card className='CardOverflow'>
        <TableContainer className={classes.container} >
            <Table id="pkpnTable" aria-label="sticky table" className='hashTable'>
                <TableHead>
                    <TableRow>
                        {ApplicationConstants.TableColumns.KeyCheckTool.ToolsDownloadTableColumn.map(column => (
                            <React.Fragment>
                                <TableCell id="TableHeader" className="headerBorder"
                                    key={column.id}
                                    align={column.align}>
                                    {column.label}
                                </TableCell >
                            </React.Fragment>
                        ))}
                        <TableCell id="TableHeader" className="headerBorder" />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(ApplicationConstants.TableRows.KeyCheckTool.ToolsDownloadTableRows).map(row => {
                        const isItemSelected = isSelected(row);
                        return (
                            <TableRow tabIndex={-1} selected={isItemSelected}>
                                {ApplicationConstants.TableColumns.KeyCheckTool.ToolsDownloadTableColumn.map(column => {
                                    const value = row[column.id];
                                    return (
                                        <TableCell hover id="HFDTableBody" key={column.id} align={column.align} className="HFDTableBody" >
                                            <div className='kct-tablerow-flex'>
                                                {column.id.localeCompare(ApplicationConstants.TableColumns.KeyCheckTool.ToolsDownloadTableColumn[0].id) === 0 ?
                                                    value.localeCompare(ApplicationConstants.TableRows.KeyCheckTool.ToolsDownloadTableRows[2].toolName) === 0 ?
                                                        <div style={{ fontFamily: "Segoe MDL2 Assets", fontSize: '1.5em', marginRight: '0.5em' }} >&#xF585;</div>
                                                        : iconComponenet
                                                    : null}
                                                {column.format && typeof value === 'number' ? column.format(value) : value}
                                            </div>
                                        </TableCell>
                                    );
                                })}
                                <TableCell hover id="HFDTableBody" align="center">
                                    <Button onClick={event => downloadSelectedTool(row.toolName)}>
                                        {ApplicationConstants.Labels.Download}
                                        < img src={DownloadIcon} style={{ height: '1.8em' }} className='hfd-download-icon' />
                                    </Button>
                                </TableCell>
                            </TableRow>
                        );
                    })
                    }
                </TableBody>
            </Table>
        </TableContainer>
    </Card>)

    return (
        <div className='RowFlex'>
            <div className='kct-rowflex-section'>
                {ToolsDownloadTable}
                <div className='kct-bottom-info'>
                    {ApplicationConstants.Information.KCTInformationLine1}
                    <br />
                    <b>{ApplicationConstants.Labels.Important}</b>
                    {ApplicationConstants.Information.KCTInformationLine2}
                </div>
            </div>
            <Backdrop className={classes.backdrop} open={openLoading} style={{ marginLeft: props.drawerWidth }}>
                <CircularProgress />
            </Backdrop>
            {BottomSnackBar}
            {AlertSnackBar(alertSelector)}
        </div>
    );
}

View.propTypes = {
    error: PropTypes.bool,
    errorDescription: PropTypes.string,
    loading: PropTypes.bool,
    idToken: PropTypes.string,
    accessToken: PropTypes.string,
    userPrincipalName: PropTypes.string,
    drawerWidth: PropTypes.string
}

export class KeyCheckTool extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorCode: 0,
            errorDescription: '',
            loading: false,
            userPrincipalName: this.props.graphProfile.userPrincipalName,
            idToken: this.props.idToken,
            accessToken: this.props.accessToken,
            drawerWidth: this.props.drawerWidth
        }
    }

    componentDidMount() {
    }

    render() {

        return (
            <React.Fragment>
                <View {...this.state} />
            </React.Fragment>
        )
    }
}