import React, { Component, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import FileDownloadIcon from '@material-ui/icons/DescriptionTwoTone';
import TenantIcon from '@material-ui/icons/PersonAddTwoTone';
import DashboardIcon from '@material-ui/icons/DashboardTwoTone';
import ValidationIcon from '@material-ui/icons/DiscFullTwoTone';
import KeyCheckIcon from '@material-ui/icons/PlaylistAddCheckTwoTone';
import ActivitiesIcon from '@material-ui/icons/TrendingUpTwoTone';
import './NavBar.css';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Dashboard from '../../icons/dashboard.png';
import KeyIcon from '../../icons/KeyIcon.png';
import FileDownload from '../../icons/FileDownload.png';
import SLKActivityIcon from '../../icons/SLKActivity.png';
import KeyValidationIcon from '../../icons/KeyValidation.png';
import TenantOnboardingIcon from '../../icons/TenantOnboarding.png';
import PrivacyIcon from '../../icons/Privacy.png';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams
} from "react-router-dom";
import ApplicationConstants from '../../Utilities/ApplicationConstants';



const drawerWidth = 370;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flex: '1 0 auto',
        position: 'relative',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',

    },
    drawerOpen: {
        width: '22em',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        backgroundColor: '#323130',
        zIndex: 'auto',
        paddingTop: '4em',
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: '55px',
        backgroundColor: '#323130',
        zIndex: 'auto',
        paddingTop: '4em',
        color:'white',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: '2.5em',
    },
    icons: {
        color: 'black',
    }
}));

const NavBarActions = [
    {
        name: "Request Dashboard", icon: <DashboardIcon style={{ fontSize: 40 }} />
    },
    {
        name: "Product Key Validation Tool", icon: <ValidationIcon color='primary' style={{ fontSize: 40 }} />
    },
    {
        name: "Key Check Tool", icon: <div style={{ fontFamily: "Segoe MDL2 Assets", fontSize: '1.5em' }} >&#xE896;</div>
    },
    {
        name: "SLK Activities", icon: <ActivitiesIcon color='primary' style={{ fontSize: 40 }} />
    },
    {
        name: "Tenant Onboarding Tool", icon: <TenantIcon color='primary' style={{ fontSize: 40 }} />
    },
    {
        name: "PKPN Hash File Download", icon: <FileDownloadIcon color='primary' style={{ fontSize: 40 }} />
    },
    {
        name: "Privacy Policy", icon: <img src={PrivacyIcon} alt="Privacy Icon" style={{ height: '40px' }} />, link: "/privacypolicy"
    }
]

const subActionsMenu = [
    [
        { name: 'PK Distribution Request', icon: < div style={{ fontFamily: "Segoe MDL2 Assets", fontSize: '1.5em' }} >&#xEBD2;</div > },
        { name: 'PK Validation Request', icon: < div style={{ fontFamily: "Segoe MDL2 Assets", fontSize: '1.5em' }} >&#xE74C;</div > }
    ],
    [
        { name: 'Submit Request', icon: < div style={{ fontFamily: "Segoe MDL2 Assets", fontSize: '1.5em' }} >&#xE8F3;</div > },
        { name: 'View Request History', icon: <div style={{ fontFamily: "Segoe MDL2 Assets", fontSize: '1.5em' }} >&#xE81C;</div> }
    ],
    [],
    [
        { name: 'Office Mac Hash Tool Installation', icon: <div style={{ fontFamily: "Segoe MDL2 Assets", fontSize: '1.5em' }} >&#xE896;</div> },
        { name: 'Office Mac Hash Tool User Guide', icon: <div style={{ fontFamily: "Segoe MDL2 Assets", fontSize: '1.5em' }} >&#xF585;</div> },
        { name: 'Key PKPN SN Range Risk Report', icon: <div style={{ fontFamily: "Segoe MDL2 Assets", fontSize: '1.5em' }} >&#xE9F9;</div> },
        { name: 'Restart KCT Part Data Service', icon: <div style={{ fontFamily: "Segoe MDL2 Assets", fontSize: '1.5em' }} >&#xEBC4;</div> }
    ],
    [
        { name: 'Manage Tenant Profile', icon: <div style={{ fontFamily: "Segoe MDL2 Assets", fontSize: '1.5em' }} >&#xEBD2;</div> },
        { name: 'RSM Org Profile', icon: <div style={{ fontFamily: "Segoe MDL2 Assets", fontSize: '1.5em' }} >&#xE7EE;</div> },
    ],
    [],
    []
]


export default function NavBar(props) {
    const classes = useStyles();
    const theme = useTheme();
    const anchorRef = React.useRef(null);
    const [selectedNav, setSelectedNav] = React.useState(0);
    const [open, setOpen] = React.useState(true);
    const [isShown, setIsShown] = React.useState(false);
    const [openTenantButton, setOpenTenantButton] = React.useState(false);
    const [openFileDownloadButton, setOpenFileDownloadButton] = React.useState(false);
    const [currentActionIndex, setCurrentActionIndex] = React.useState(props.actionSelected);
    const [currentSubActionIndex, setCurrentSubActionIndex] = React.useState(props.subActionSelected);

    useEffect(() => {
        open ? props.setDrawerWidth('22em') : props.setDrawerWidth('55px');
    })

    const handleClick = () => {
        setOpen(!open);
    };

    const handleDrawerToggle = () => {
        setOpen(!open);
    };

    const handleActionClick = (index) => {
        props.setActionSelected(index);
        props.setSubActionSelected(0);
        props.setSubActionOpen(false);
    };

    const handleListHover = (event, index) => {
        if (props.actionSelected !== index && !open) {
            props.setSubActionOpen(true);
            props.setAnchorEl(event.currentTarget);
            props.setIndex(index);
            props.setActionHeader(NavBarActions[index].name);
            props.setShowTooltip(false);
        }
        else {
            props.setSubActionOpen(false);
        }
    }

    const handleHover = (event, index) => {
        if (props.actionSelected === index) {
            props.setSubActionOpen(false);
        }
    }
    const handleSubActionHover = (event, actionIndex, subActionIndex) => {
        if (!open) {
            props.setShowTooltip(true);
            props.setAnchorSubActionEl(event.currentTarget);
            props.setIndex(actionIndex);
            props.setSubActionIndex(subActionIndex);
        }
    }

    const handleNoSubActionHover = (event, actionIndex) => {
        if (!open) {
            props.setSubActionOpen(false);
            props.setShowTooltip(true);
            props.setAnchorSubActionEl(event.currentTarget);
            props.setIndex(actionIndex);
            props.setSubActionIndex(-1);
        }
    }

    const handleSubActionClick = (subActionIndex) => {
        props.setSubActionSelected(subActionIndex);
    }

    return (
        <div className={'navigation-container project-navigation region-navigation'}>
            <Drawer
                variant='permanent'
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
            >
                <div className={classes.toolbar} style={open ? { justifyContent: 'flex-end' } : { justifyContent: 'center' }}>
                    <IconButton className='arrowIcon' onClick={handleDrawerToggle} style={{ paddingTop: '0' }}>
                        {(theme.direction === 'ltr' && !open) ? <ChevronRightIcon style={{ fontSize: 40, color: '#dee2e6' }} /> : <ChevronLeftIcon style={{ fontSize: 40, color: '#dee2e6' }} />}
                    </IconButton>
                </div>
                <Divider />
                <ClickAwayListener onClickAway={event => props.setSubActionOpen(false)}>
                    <List id='MainActions' >
                        <div className={props.actionSelected == 0 ? 'displayed-container hub-group-container expanded-container' : ''} onMouseEnter={event => handleHover(event, 0)}>
                            <Link to={"/" + NavBarActions[0].name.split(" ").join("").toLowerCase().toLowerCase() + "/" + subActionsMenu[0][0].name.split(" ").join("").toLowerCase().toLowerCase()}>

                                <ListItem button onClick={event => handleActionClick(0)} onMouseEnter={event => handleListHover(event, 0)} disabled={!ApplicationConstants.SecurityGroupDetails[ApplicationConstants.EnvironmentType][0].isAuthorized}>
                                    <ListItemIcon>
                                        <div>
                                            <img src={Dashboard} style={{ height: '1.8em' }} />
                                        </div>
                                    </ListItemIcon>
                                    <ListItemText style={{ fontWeight: 500 }} primary="Request Dashboard" />
                                    {props.actionSelected == 0 ? <ExpandLess style={{ display: 'none' }} /> : <ExpandMore style={{ display: 'none' }} />}
                                </ListItem>

                            </Link>
                            <Collapse in={props.actionSelected == 0} timeout="auto" unmountOnExit>
                                <List id='subMenu' component="div" disablePadding >
                                    {subActionsMenu[0].map((subAction, index) => (
                                        <Link to={"/" + NavBarActions[0].name.split(" ").join("").toLowerCase().toLowerCase() + "/" + subAction.name.split(" ").join("").toLowerCase().toLowerCase()}>
                                            <ListItem button onClick={event => handleSubActionClick(index)} id={index === props.subActionSelected ? 'selectedSubAction' : undefined} onMouseEnter={event => handleSubActionHover(event, 0, index)}
                                                onMouseLeave={event => props.setShowTooltip(false)} selected={index === props.subActionSelected} className={index === props.subActionSelected ? 'displayed' : ''}
                                                disabled={!ApplicationConstants.SecurityGroupDetails[ApplicationConstants.EnvironmentType][0].isAuthorized}>
                                                <ListItemIcon style={{ paddingLeft: '0.25em' }}>
                                                    {subAction.icon}
                                                </ListItemIcon>
                                                <ListItemText primary={subAction.name} />
                                            </ListItem>
                                        </Link>
                                    ))}
                                </List>
                            </Collapse>
                        </div>
                        <div className={props.actionSelected == 1 ? 'displayed-container hub-group-container expanded-container' : ''} onMouseEnter={event => handleHover(event, 1)}>
                            <Link to={"/" + NavBarActions[1].name.split(" ").join("").toLowerCase().toLowerCase() + "/" + subActionsMenu[1][0].name.split(" ").join("").toLowerCase().toLowerCase()}>
                                <ListItem button onClick={event => handleActionClick(1)} onMouseEnter={event => handleListHover(event, 1)} disabled={!ApplicationConstants.SecurityGroupDetails[ApplicationConstants.EnvironmentType][1].isAuthorized}>
                                    <ListItemIcon>
                                        <div>
                                            <img src={KeyValidationIcon} style={{ height: '1.8em' }} />
                                        </div>
                                    </ListItemIcon>
                                    <ListItemText primary="Product Key Validation Tool" />
                                    {props.actionSelected == 1 ? <ExpandLess style={{ display: 'none' }} /> : <ExpandMore style={{ display: 'none' }} />}
                                </ListItem>
                            </Link>
                            <Collapse in={props.actionSelected == 1} timeout="auto" unmountOnExit>
                                <List id='subMenu' component="div" disablePadding>
                                    {subActionsMenu[1].map((subAction, index) => (
                                        <Link to={"/" + NavBarActions[1].name.split(" ").join("").toLowerCase().toLowerCase() + "/" + subAction.name.split(" ").join("").toLowerCase()}>
                                            <ListItem button onClick={event => handleSubActionClick(index)} id={index === props.subActionSelected ? 'selectedSubAction' : undefined} onMouseEnter={event => handleSubActionHover(event, 1, index)}
                                                onMouseLeave={event => props.setShowTooltip(false)} selected={index === props.subActionSelected} className={index === props.subActionSelected ? 'displayed' : ''}
                                                disabled={!ApplicationConstants.SecurityGroupDetails[ApplicationConstants.EnvironmentType][1].isAuthorized}>
                                                <ListItemIcon style={{ paddingLeft: '0.25em' }}>
                                                    {subAction.icon}
                                                </ListItemIcon>
                                                <ListItemText primary={subAction.name} />
                                            </ListItem>
                                        </Link>
                                    ))}
                                </List>
                            </Collapse>
                        </div>

                        <div className={props.actionSelected == 2 ? 'displayed hub-group-container expanded-container' : ''} onMouseEnter={event => handleHover(event, 2)}>
                            <Link to={"/" + NavBarActions[2].name.split(" ").join("").toLowerCase()}>
                                <ListItem button onClick={event => handleActionClick(2)}
                                    onMouseEnter={event => handleNoSubActionHover(event, 2)}
                                    onMouseLeave={event => props.setShowTooltip(false)}
                                    disabled={!ApplicationConstants.SecurityGroupDetails[ApplicationConstants.EnvironmentType][2].isAuthorized}>
                                    <ListItemIcon >
                                        <div>
                                            <img src={KeyIcon} style={{ height: '1.8em' }} />
                                        </div>
                                    </ListItemIcon>
                                    <ListItemText primary="Key Check Tool" />
                                    {props.actionSelected == 2 ? <ExpandLess style={{ display: 'none' }} /> : <ExpandMore style={{ display: 'none' }} />}
                                </ListItem>
                            </Link>
                            <Collapse in={props.actionSelected == 2} timeout="auto" unmountOnExit>
                                <List id='subMenu' component="div" disablePadding>
                                    {subActionsMenu[2].map((subAction, index) => (

                                        <ListItem button onClick={event => handleSubActionClick(index)} id={index === props.subActionSelected ? 'selectedSubAction' : undefined} onMouseEnter={event => handleSubActionHover(event, 5, index)}
                                            onMouseLeave={event => props.setShowTooltip(false)} selected={index === props.subActionSelected} className={index === props.subActionSelected ? 'displayed' : ''}
                                            disabled={!ApplicationConstants.SecurityGroupDetails[ApplicationConstants.EnvironmentType][2].isAuthorized}>
                                            <ListItemIcon style={{ paddingLeft: '0.25em' }}>
                                                {subAction.icon}
                                            </ListItemIcon>
                                            <ListItemText primary={subAction.name} />
                                        </ListItem>
                                    ))}
                                </List>
                            </Collapse>

                        </div>
                        <div className={props.actionSelected == 3 ? 'displayed-container hub-group-container expanded-container' : ''} onMouseEnter={event => handleHover(event, 3)}>
                            <Link to={"/" + NavBarActions[3].name.split(" ").join("").toLowerCase() + "/" + subActionsMenu[3][0].name.split(" ").join("").toLowerCase()}>
                                <ListItem button onClick={event => handleActionClick(3)}
                                    onMouseEnter={event => handleListHover(event, 3)}
                                    disabled={!ApplicationConstants.SecurityGroupDetails[ApplicationConstants.EnvironmentType][3].isAuthorized}>
                                    <ListItemIcon >
                                        <div>
                                            <img src={SLKActivityIcon} style={{ height: '1.8em' }} />
                                        </div>                                </ListItemIcon>
                                    <ListItemText primary="SLK Activities" />
                                    {props.actionSelected == 3 ? <ExpandLess style={{ display: 'none' }} /> : <ExpandMore style={{ display: 'none' }} />}
                                </ListItem>
                            </Link>
                            <Collapse in={props.actionSelected == 3} timeout="auto" unmountOnExit>
                                <Router>
                                    <List id='subMenu' component="div" disablePadding>
                                        {subActionsMenu[3].map((subAction, index) => (
                                            <Link to={"/" + NavBarActions[3].name.split(" ").join("").toLowerCase() + "/" + subAction.name.split(" ").join("").toLowerCase()}>
                                                <ListItem button onClick={event => handleSubActionClick(index)} id={index === props.subActionSelected ? 'selectedSubAction' : undefined} onMouseEnter={event => handleSubActionHover(event, 3, index)}
                                                    onMouseLeave={event => props.setShowTooltip(false)} selected={index === props.subActionSelected} className={index === props.subActionSelected ? 'displayed' : ''}
                                                    disabled={!ApplicationConstants.SecurityGroupDetails[ApplicationConstants.EnvironmentType][3].isAuthorized}>
                                                    <ListItemIcon style={{ paddingLeft: '0.25em' }}>
                                                        {subAction.icon}
                                                    </ListItemIcon>
                                                    <ListItemText primary={subAction.name} />
                                                </ListItem>
                                            </Link>
                                        ))}
                                    </List>
                                </Router>
                            </Collapse>
                        </div>
                        <div className={props.actionSelected == 4 ? 'displayed-container hub-group-container expanded-container' : ''} onMouseEnter={event => handleHover(event, 4)}>
                            <Link to={"/" + NavBarActions[4].name.split(" ").join("").toLowerCase() + "/" + subActionsMenu[4][0].name.split(" ").join("").toLowerCase()}>
                                <ListItem button onClick={event => handleActionClick(4)}
                                    onMouseEnter={event => handleListHover(event, 4)}
                                    disabled={!ApplicationConstants.SecurityGroupDetails[ApplicationConstants.EnvironmentType][4].isAuthorized}>
                                    <ListItemIcon >
                                        <div>
                                            <img src={TenantOnboardingIcon} style={{ height: '1.8em' }} />
                                        </div>                                </ListItemIcon>
                                    <ListItemText primary="Tenant Onboarding Tool" />
                                    {props.actionSelected == 4 ? <ExpandLess style={{ display: 'none' }} /> : <ExpandMore style={{ display: 'none' }} />}
                                </ListItem>
                            </Link>
                            <Collapse in={props.actionSelected == 4} timeout="auto" unmountOnExit>
                                <Router>
                                    <List id='subMenu' component="div" disablePadding>
                                        {subActionsMenu[4].map((subAction, index) => (
                                            <Link to={"/" + NavBarActions[4].name.split(" ").join("").toLowerCase() + "/" + subAction.name.split(" ").join("").toLowerCase()}>
                                                <ListItem button onClick={event => handleSubActionClick(index)} id={index === props.subActionSelected ? 'selectedSubAction' : undefined} onMouseEnter={event => handleSubActionHover(event, 4, index)}
                                                    onMouseLeave={event => props.setShowTooltip(false)} selected={index === props.subActionSelected} className={index === props.subActionSelected ? 'displayed' : ''}
                                                    disabled={!ApplicationConstants.SecurityGroupDetails[ApplicationConstants.EnvironmentType][4].isAuthorized}>
                                                    <ListItemIcon style={{ paddingLeft: '0.25em' }}>
                                                        {subAction.icon}
                                                    </ListItemIcon>
                                                    <ListItemText primary={subAction.name} />
                                                </ListItem>
                                            </Link>
                                        ))}
                                    </List>
                                </Router>
                            </Collapse>
                        </div>
                        <div className={props.actionSelected == 5 ? 'displayed hub-group-container expanded-container' : ''} onMouseEnter={event => handleHover(event, 5)}>
                            <Link to={"/" + NavBarActions[5].name.split(" ").join("").toLowerCase()}>
                                <ListItem button onClick={event => handleActionClick(5)}
                                    onMouseEnter={event => handleNoSubActionHover(event, 5)}
                                    onMouseLeave={event => props.setShowTooltip(false)}
                                    disabled={!ApplicationConstants.SecurityGroupDetails[ApplicationConstants.EnvironmentType][5].isAuthorized}>
                                    <ListItemIcon >
                                        <div>
                                            <img src={FileDownload} style={{ height: '1.8em' }} />
                                        </div>
                                    </ListItemIcon>
                                    <ListItemText primary="PKPN Hash File Download" />
                                    {props.actionSelected == 5 ? <ExpandLess style={{ display: 'none' }} /> : <ExpandMore style={{ display: 'none' }} />}
                                </ListItem>
                            </Link>
                            <Collapse in={props.actionSelected == 5} timeout="auto" unmountOnExit>
                                <List id='subMenu' component="div" disablePadding>
                                    {subActionsMenu[5].map((subAction, index) => (

                                        <ListItem button onClick={event => handleSubActionClick(index)} id={index === props.subActionSelected ? 'selectedSubAction' : undefined} onMouseEnter={event => handleSubActionHover(event, 5, index)}
                                            onMouseLeave={event => props.setShowTooltip(false)} selected={index === props.subActionSelected} className={index === props.subActionSelected ? 'displayed' : ''}
                                            disabled={!ApplicationConstants.SecurityGroupDetails[ApplicationConstants.EnvironmentType][5].isAuthorized}>
                                            <ListItemIcon style={{ paddingLeft: '0.25em' }}>
                                                {subAction.icon}
                                            </ListItemIcon>
                                            <ListItemText primary={subAction.name} />
                                        </ListItem>
                                    ))}
                                </List>
                            </Collapse>

                        </div>
                        <div className={props.actionSelected === 6 ? 'displayed hub-group-container expanded-container' : ''} onMouseEnter={event => handleHover(event, 6)}>
                            <ListItem
                                button
                                component={Link}
                                to={NavBarActions[6].link}
                                onClick={event => handleActionClick(6)}
                                onMouseEnter={event => handleNoSubActionHover(event, 6)}
                                onMouseLeave={event => props.setShowTooltip(false)}
                                disabled={!ApplicationConstants.SecurityGroupDetails[ApplicationConstants.EnvironmentType][6].isAuthorized}>
                                <ListItemIcon>
                                    <div>
                                        <img src={PrivacyIcon} style={{ height: '1.8em' }} />
                                    </div>
                                </ListItemIcon>
                                <ListItemText primary={NavBarActions[6].name} />
                                {props.actionSelected == 6 ? <ExpandLess style={{ display: 'none' }} /> : <ExpandMore style={{ display: 'none' }} />}
                            </ListItem>
                        </div>
                    </List>

                </ClickAwayListener>
            </Drawer>
        </div>
    );
}