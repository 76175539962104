export default {
    EnvironmentType: "",
    EndPoints: {
        GetKeyFulfilmentRequestsEndPoint: "KeyFulfilmentRequests/GetKeyFulfilmentRequests",
        GetKeyValidationRequests: "KeyValidation/GetKeyValidationRequests",
        SubmitRequestEndpoint: "KeyValidation/SubmitRequest",
        GetProductKeyValidationRequestsDashboardEndPoint: "KeyValidation/GetKeyValidationRequestsPendingDashboard",
        UpdateKeyFulfilmentAsyncEndPoint: "KeyFulfilmentRequests/UpdateKeyFulfilmentAsync",
        UpdateKeyValidationAsyncEndPoint: "KeyValidation/UpdateKeyValidationAsync",
        GetSecurityGroupInfoHashFileDownloadEndPoint: "HashFileDownload/GetSecurityGroupsInformation",
        ListHashFilesForBlobEndPoint: "HashFileDownload/ListHashFilesForBlob",
        GetFileDataForDownloadEndPoint: "HashFileDownload/GetFileDataForDownload",
        GetFolderDataForDownloadEndPoint: "HashFileDownload/GetFolderDataForDownload",
        GetTenantProfilesEndPoint: "TenantOnboarding/GetTenantProfiles",
        UpdateTenantEndPoint: "TenantOnboarding/Updatetenant",
        NewTenantEndPoint: "TenantOnboarding/newtenant",
        DownloadKCTToolFromBlobEndPoint: "KeyCheckTool/DownloadToolFromBlob",
        GetEnvironmentVariable: "Base/GetEnvironmentVariable",
        DownloadToolUserGuide: "Base/DownloadToolUserGuide"
    },
    AppSettings: {
        Local: {
            ClientId: "c5b6bc9c-821f-4a0f-a82b-af63c552953a",
            RedirectUri: "https://localhost:44302/",
            RSMDailyKeyLimit: "1000000",
            RSMResourceId: ""
        },
        Dev: {
            ClientId: "c5b6bc9c-821f-4a0f-a82b-af63c552953a",
            RedirectUri: "https://slkportaldev.azurewebsites.net",
            RSMDailyKeyLimit: "1000000",
            RSMResourceId: ""
        },
        INT: {
            ClientId: "b2a760b4-2510-444a-9fb4-85ecade3eda8",
            RedirectUri: "https://portal-wus-int-slkv2.azurewebsites.net",
            RSMDailyKeyLimit: "1000000",
            RSMResourceId: ""
        },
        PPE: {
            ClientId: "f8d0ef02-0e7c-4738-b2a4-3464d7fe4b5f",
            RedirectUri: "https://portal-wus-ppe-slkv2.azurewebsites.net",
            RSMDailyKeyLimit: "10000000",
            RSMResourceId: "af107c7b-441e-477f-9be6-0fb99a06ae90"
        },
        ProdDub: {
            ClientId: "bcaadde9-b17a-4164-83ff-d26fa99a643f",
            RedirectUri: "https://portal-neu-prod-slkv2.azurewebsites.net",
            RSMDailyKeyLimit: "10000000",
            RSMResourceId: "4543e2ac-a866-401d-95ee-9ead198ea6ce"
        },
        ProdPR: {
            ClientId: "bcaadde9-b17a-4164-83ff-d26fa99a643f",
            RedirectUri: "https://portal-eus-prod-slkv2.azurewebsites.net",
            RSMDailyKeyLimit: "10000000",
            RSMResourceId: "4543e2ac-a866-401d-95ee-9ead198ea6ce"
        }
    },
    SecurityGroupDetails: {
        Dev: [
            {
                pageTab: "RequestDashboard",
                securityGroupId: ["d5d0b58f-2445-46da-9f50-7c04863d72b9"],
                isAuthorized: false
            },
            {
                pageTab: "KeyValidation",
                securityGroupId: ["d5d0b58f-2445-46da-9f50-7c04863d72b9"],
                isAuthorized: false
            },
            {
                pageTab: "KeyCheckTool",
                securityGroupId: ["d5d0b58f-2445-46da-9f50-7c04863d72b9"],
                isAuthorized: false
            },
            {
                pageTab: "SLKActivities",
                securityGroupId: ["d5d0b58f-2445-46da-9f50-7c04863d72b3"],
                isAuthorized: false
            },
            {
                pageTab: "TenantOnboardingTool",
                securityGroupId: ["d5d0b58f-2445-46da-9f50-7c04863d72b9"],
                isAuthorized: false
            },
            {
                pageTab: "HashFileDownload",
                securityGroupId: ["d5d0b58f-2445-46da-9f50-7c04863d72b9"],
                isAuthorized: false
            },
        ],
        Local: [
            {
                pageTab: "RequestDashboard",
                securityGroupId: ["d5d0b58f-2445-46da-9f50-7c04863d72b9"],
                isAuthorized: false
            },
            {
                pageTab: "KeyValidation",
                securityGroupId: ["d5d0b58f-2445-46da-9f50-7c04863d72b9"],
                isAuthorized: false
            },
            {
                pageTab: "KeyCheckTool",
                securityGroupId: ["d5d0b58f-2445-46da-9f50-7c04863d72b9"],
                isAuthorized: false
            },
            {
                pageTab: "SLKActivities",
                securityGroupId: ["d5d0b58f-2445-46da-9f50-7c04863d72b9"],
                isAuthorized: false
            },
            {
                pageTab: "TenantOnboardingTool",
                securityGroupId: ["d5d0b58f-2445-46da-9f50-7c04863d72b9"],
                isAuthorized: false
            },
            {
                pageTab: "HashFileDownload",
                securityGroupId: ["d5d0b58f-2445-46da-9f50-7c04863d72b9"],
                isAuthorized: false
            },
        ],
        INT: [
            {
                pageTab: "RequestDashboard",
                securityGroupId: ["d5d0b58f-2445-46da-9f50-7c04863d72b9"],
                isAuthorized: false
            },
            {
                pageTab: "KeyValidation",
                securityGroupId: ["d5d0b58f-2445-46da-9f50-7c04863d72b9"],
                isAuthorized: false
            },
            {
                pageTab: "KeyCheckTool",
                securityGroupId: ["d5d0b58f-2445-46da-9f50-7c04863d72be"],
                isAuthorized: false
            },
            {
                pageTab: "SLKActivities",
                securityGroupId: ["d5d0b58f-2445-46da-9f50-7c04863d72b9"],
                isAuthorized: false
            },
            {
                pageTab: "TenantOnboardingTool",
                securityGroupId: ["d5d0b58f-2445-46da-9f50-7c04863d72b9"],
                isAuthorized: false
            },
            {
                pageTab: "HashFileDownload",
                securityGroupId: ["d5d0b58f-2445-46da-9f50-7c04863d72b9"],
                isAuthorized: false
            },
        ],
        PPE: [
            {
                pageTab: "RequestDashboard",
                securityGroupId: ["1f758150-14aa-49c0-b712-92ba250308a9", "435d4e4d-2ae2-40a3-819f-2feef4da1671"],
                isAuthorized: false
            },
            {
                pageTab: "KeyValidation",
                securityGroupId: ["1f758150-14aa-49c0-b712-92ba250308a9", "435d4e4d-2ae2-40a3-819f-2feef4da1671", "d7f88607-92eb-4b02-b1cf-9cbf57065c5b"],
                isAuthorized: false
            },
            {
                pageTab: "KeyCheckTool",
                securityGroupId: ["1f758150-14aa-49c0-b712-92ba250308a9", "435d4e4d-2ae2-40a3-819f-2feef4da1671", "077d17bc-de07-42f6-9789-60c1f90a1792"],
                isAuthorized: false
            },
            {
                pageTab: "SLKActivities",
                securityGroupId: ["d5d0b58f-2445-46da-9f50-7c04863d72b3"],
                isAuthorized: false
            },
            {
                pageTab: "TenantOnboardingTool",
                securityGroupId: ["1f758150-14aa-49c0-b712-92ba250308a9", "435d4e4d-2ae2-40a3-819f-2feef4da1671"],
                isAuthorized: false
            },
            {
                pageTab: "HashFileDownload",
                securityGroupId: [
                    "1f758150-14aa-49c0-b712-92ba250308a9",
                    "435d4e4d-2ae2-40a3-819f-2feef4da1671",
                    "95fd7fd6-e822-437e-8bd4-2d0ea27366b7",
                    "5b05ac86-704b-46c8-8b31-30bd8272db41",
                    "e46b4bc3-84f0-4b39-9e7d-b9a8377f691c",
                    "790f9d44-d4f2-445d-bbbb-7b74fa3daa6a",
                    "02c5b40b-9e8d-4437-8fab-2d14952e0d40",
                    "cf383649-d73c-47da-93b3-d4b9bb930840",
                    "1e597304-696c-469e-8798-e089bcf16719",
                    "6b79d45a-a97b-446d-a750-08b1e2a2ecab",
                    "61bea211-4fc5-4994-8985-8074056c9189"
                ],
                isAuthorized: false
            },
            {
                pageTab: "MicrosoftDataProtection",
                securityGroupId: ["1f758150-14aa-49c0-b712-92ba250308a9", "435d4e4d-2ae2-40a3-819f-2feef4da1671"],
                isAuthorized: true
            }
        ],
        ProdDub: [
            {
                pageTab: "RequestDashboard",
                securityGroupId: ["1f758150-14aa-49c0-b712-92ba250308a9", "435d4e4d-2ae2-40a3-819f-2feef4da1671"],
                isAuthorized: false
            },
            {
                pageTab: "KeyValidation",
                securityGroupId: ["1f758150-14aa-49c0-b712-92ba250308a9", "435d4e4d-2ae2-40a3-819f-2feef4da1671", "d7f88607-92eb-4b02-b1cf-9cbf57065c5b"],
                isAuthorized: false
            },
            {
                pageTab: "KeyCheckTool",
                securityGroupId: ["1f758150-14aa-49c0-b712-92ba250308a9", "435d4e4d-2ae2-40a3-819f-2feef4da1671", "077d17bc-de07-42f6-9789-60c1f90a1792"],
                isAuthorized: false
            },
            {
                pageTab: "SLKActivities",
                securityGroupId: ["d5d0b58f-2445-46da-9f50-7c04863d72b3"],
                isAuthorized: false
            },
            {
                pageTab: "TenantOnboardingTool",
                securityGroupId: ["1f758150-14aa-49c0-b712-92ba250308a9", "435d4e4d-2ae2-40a3-819f-2feef4da1671"],
                isAuthorized: false
            },
            {
                pageTab: "HashFileDownload",
                securityGroupId: [
                    "1f758150-14aa-49c0-b712-92ba250308a9",
                    "435d4e4d-2ae2-40a3-819f-2feef4da1671",
                    "95fd7fd6-e822-437e-8bd4-2d0ea27366b7",
                    "5b05ac86-704b-46c8-8b31-30bd8272db41",
                    "e46b4bc3-84f0-4b39-9e7d-b9a8377f691c",
                    "790f9d44-d4f2-445d-bbbb-7b74fa3daa6a",
                    "02c5b40b-9e8d-4437-8fab-2d14952e0d40",
                    "cf383649-d73c-47da-93b3-d4b9bb930840",
                    "1e597304-696c-469e-8798-e089bcf16719",
                    "6b79d45a-a97b-446d-a750-08b1e2a2ecab",
                    "61bea211-4fc5-4994-8985-8074056c9189"
                ],
                isAuthorized: false
            },
            {
                pageTab: "MicrosoftDataProtection",
                securityGroupId: ["1f758150-14aa-49c0-b712-92ba250308a9", "435d4e4d-2ae2-40a3-819f-2feef4da1671"],
                isAuthorized: true
            }
        ],
        ProdPR: [
            {
                pageTab: "RequestDashboard",
                securityGroupId: ["1f758150-14aa-49c0-b712-92ba250308a9", "435d4e4d-2ae2-40a3-819f-2feef4da1671"],
                isAuthorized: false
            },
            {
                pageTab: "KeyValidation",
                securityGroupId: ["1f758150-14aa-49c0-b712-92ba250308a9", "435d4e4d-2ae2-40a3-819f-2feef4da1671", "d7f88607-92eb-4b02-b1cf-9cbf57065c5b"],
                isAuthorized: false
            },
            {
                pageTab: "KeyCheckTool",
                securityGroupId: ["1f758150-14aa-49c0-b712-92ba250308a9", "435d4e4d-2ae2-40a3-819f-2feef4da1671", "077d17bc-de07-42f6-9789-60c1f90a1792"],
                isAuthorized: false
            },
            {
                pageTab: "SLKActivities",
                securityGroupId: ["d5d0b58f-2445-46da-9f50-7c04863d72b3"],
                isAuthorized: false
            },
            {
                pageTab: "TenantOnboardingTool",
                securityGroupId: ["1f758150-14aa-49c0-b712-92ba250308a9", "435d4e4d-2ae2-40a3-819f-2feef4da1671"],
                isAuthorized: false
            },
            {
                pageTab: "HashFileDownload",
                securityGroupId: [
                    "1f758150-14aa-49c0-b712-92ba250308a9",
                    "435d4e4d-2ae2-40a3-819f-2feef4da1671",
                    "95fd7fd6-e822-437e-8bd4-2d0ea27366b7",
                    "5b05ac86-704b-46c8-8b31-30bd8272db41",
                    "e46b4bc3-84f0-4b39-9e7d-b9a8377f691c",
                    "790f9d44-d4f2-445d-bbbb-7b74fa3daa6a",
                    "02c5b40b-9e8d-4437-8fab-2d14952e0d40",
                    "cf383649-d73c-47da-93b3-d4b9bb930840",
                    "1e597304-696c-469e-8798-e089bcf16719",
                    "6b79d45a-a97b-446d-a750-08b1e2a2ecab",
                    "61bea211-4fc5-4994-8985-8074056c9189"
                ],
                isAuthorized: false
            },
            {
                pageTab: "MicrosoftDataProtection",
                securityGroupId: ["1f758150-14aa-49c0-b712-92ba250308a9", "435d4e4d-2ae2-40a3-819f-2feef4da1671"],
                isAuthorized: true
            }
        ]
    },
    DashboardRequestStatus: {
        WaitingForApproval: "WaitingForApproval",
        Waiting: "Waiting",
        Cancelled: "Cancelled",
        Failed: "Failed",
        WaitingForRanges: "WaitingForRanges",
        InProgress: "InProgress",
        NotStarted: "NotStarted",
        Completed: "Completed",
        Approved: "Approved",
        Rejected: "Rejected",
        PartialCompleted: "PartialCompleted"
    },
    ValidationErrors: {
        InvalidGuid: "Invalid GUID",
        InvalidDate: "Invalid Date",
        IncorrectPKPNs: "Incorrect PKPNs in line numbers ",
        AplhanumericProductKeysAccepeted: "Only alphanumeric product keys accepted"
    },
    AlertMessages: {
        ExportingExcel: "Exporting Excel",
        ExecutingQuery: "Executing Query",
        DeselectSelectAll: "Deselect select all if you wish to select individual rows",
        ErrorCode: "Error Code: ",
        ErrorMessage: " Error Message: ",
        RequestSubmitted: "Request Submitted",
        SuccesfullySubmitted: "Succesfully Submitted",
        GettingData: "Getting Data",
        NoRecordsFound: "No Records Found",
        Success: "Success",
        NoFoldersFound: "No folders found",
        NoHashFilesFound: "No hash file found in the selected storage blob",
        UnknownErrorOccured: "Unknown Error Occured",
        PleaseWait: "Please wait...",
        RequestId: "RequestId : ",
        CertificateSuccessfullyUploaded: "Certificate successfully uploaded",
        TenantCodeAlreadyExists: "Tenant code already exists. Please provide different code"
    },
    Buttons: {
        Retry: "Retry",
        Cancel: "Cancel",
        Reject: "Reject",
        Approve: "Approve",
        Complete: "Complete",
        Submit: "Submit",
        ExportToExcel: "Export To Excel",
        Add: "Add",
        Upload: "Upload",
        Renew: "Renew",
        SendExport: "Send/Export",
        Activate: "Activate",
        Save: "Save",
        RenewAllOrgCertificates: "Renew All Org Certificates",
        Create: "Create",
        ToolUserGuide: "Tool User Guide",
        HashWorkOrderReport: "SLK v1 PKPN Hash Work Order Report",
        Refresh: "Refresh",
        Clear: "Clear",
        AddTenant: "Add Tenant"
    },
    Labels: {
        ProductKeys: "Product Keys(*)",
        EnterComments: "Please enter your comments(*)",
        Container: "containers ",
        Location: "Location:",
        Download: "Download",
        DownloadSelected: "Download Selected",
        OrganizationCode: "Organization Code",
        EnterOrganizationCode: "Enter Organization Code",
        TenantRole: "Tenant Role",
        TenantOrganization: "Tenant Organization",
        EnterTenantOrganization: "Enter Tenant Organization",
        DailyKeyLimit: "Daily Key Limit",
        EnterDailyKeyLimit: "Enter Daily Key Limit",
        EncryptionCertificate: "Encryption Certificate",
        IsVLK: "Is VLK",
        CustomerProfile: "Customer Profile",
        TenantCertificate: "Tenant Certificate",
        EmailContactInformation: "Email Contact Information",
        JIT: "JIT",
        RSM: "RSM",
        SLK: "SLK",
        Alias: "Email",
        EnterAlias: "Enter email",
        TenantProfiles: "Tenant Profile",
        EditTenant: "Edit Tenant",
        AddTenant: "Add Tenant",
        TenantResourceId: "Tenant Resource Id",
        EnterTenantResourceId: "Enter Tenant Resource Id",
        Important: "Important:",
        True: "True",
        False: "False"
    },
    Information: {
        KCTInformationLine1: "The Microsoft KeyCheck Tool (KCT) is used to identify the individual Product Key attributes and decrypt RSM Product Key files.",
        KCTInformationLine2: " Per policy, KCT is used for business purposes only; KCT users are prohibited from distributing the tool, and disclosing information produced by the tool, to other internal users or individuals outside of Microsoft. KCT users who fail to enforce this policy are subject to having their KCT Access revoked, and will be reported to management.",
        SessionExpired: "Session Expired",
        SessionExpiredInfo: "Your session has expired, kindly refresh the page.",
        HashFileDownloadInformation: " The hash files are generated and saved as per PST timezone.",
        RSM: "RSM"
    },
    Options: {
        DownloadAll: "DownloadAll",
        DownloadSelected: "DownloadSelected",
        DownloadSingle: "DownloadSingle"
    },
    VisualBar: {
        NumberofInProgessRequests: "Number of In Progess Requests",
        NumberofApprovalRequests: "Number of Approval Requests",
        NumberofErrorRequests: "Number of Error Requests"
    },
    InfoIconsText: {
        RequiredMax256AphaNumeric: "Required max 256 alphanumeric characters",
        SubmitRequest: "Input max 100 5x5 alphanumeric product keys with or without dashes.",
        RSMDailyKeyLimit: "Daily Key Limit for RSM tenants is set by default and cannot be modified. ",
        EmailAddress: "eg: xyz@domain.com. Press Enter to add multiple email addresses."
    },
    TextField: {
        Placeholder: {
            SubmitRequest: "XXXXX-XXXXX-XXXXX-XXXXX-XXXXX",
        },
        Label: {
            EnterProductKeys: "Enter Product Keys"
        }
    },
    TableColumns: {
        ProductKeyDistributionDashboard: {
            InProcessColumns: [
                {
                    id: 'requestStatus',
                    label: 'Status',
                    align: 'right',
                    format: (value) => value.toLocaleString(),
                },
                {
                    id: 'comments',
                    label: 'Error',
                    align: 'right',
                    format: (value) => value.toLocaleString(),
                },
                {
                    id: 'keyFulfilmentRequestId',
                    label: 'Request Id',
                    align: 'right',
                    format: (value) => value.toLocaleString(),
                },
                {
                    id: 'tenantCode',
                    label: 'Tenant',
                    align: 'right',
                    format: (value) => value.toLocaleString(),
                },
                {
                    id: 'tenantUserName',
                    label: 'Sub Tenant',
                    align: 'right',
                    format: (value) => value.toLocaleString(),
                },
                {
                    id: 'numberOfKeys',
                    label: 'Request Quantity',
                    align: 'right',
                    format: (value) => value.toLocaleString(),
                },
                {
                    id: 'partNumber',
                    label: 'PKPN',
                    align: 'right',
                    format: (value) => value.toLocaleString(),
                },
                {
                    id: 'createdDate',
                    label: 'Created Date',
                    align: 'right',
                    format: (value) => value.toLocaleString(),
                },
                {
                    id: 'lastModifiedDate',
                    label: 'Last Modified Date',
                    align: 'right',
                    format: (value) => value.toLocaleString(),
                }
            ]
        },
        ProductKeyValidationDashboard: {
            InProcessTableColumn: [
                {
                    id: 'keyValidationRequestId',
                    label: 'Request Id',
                    align: 'center',
                    format: (value) => value.toLocaleString(),
                },
                {
                    id: 'keyValidationRequestStatus',
                    label: 'Status',
                    align: 'center',
                    format: (value) => value.toLocaleString(),
                },
                {
                    id: 'createdDate',
                    label: 'Created Date',
                    align: 'center',
                    format: (value) => value.toLocaleString(),
                },
                {
                    id: 'userEmailId',
                    label: 'Created By',
                    align: 'center',
                    format: (value) => value.toLocaleString(),
                },
                {
                    id: 'totalNumberOfLineItems',
                    label: 'Total Number Of Line Items',
                    align: 'center',
                    format: (value) => value.toLocaleString(),
                },
                {
                    id: 'totalNumberOfLineItemsCompleted',
                    label: 'Total Number Of Line Items Completed',
                    align: 'center',
                    format: (value) => value.toLocaleString(),
                }
            ]
        },
        ViewRequestHistory: {
            RequestTableColumn: [
                {
                    id: 'lineItemId',
                    label: 'Line Item Id',
                    align: 'right'
                },
                {
                    id: 'lineItemStatus',
                    label: 'Line Item Status',
                    align: 'right'
                },
                {
                    id: 'keyFindings',
                    label: 'Valid Key Findings',
                    align: 'right'
                },
                {
                    id: 'productKeyLastFiveDigits',
                    label: 'Product Key(Last 5 digits)',
                    align: 'right'
                },
                {
                    id: 'groupId',
                    label: 'Group Id',
                    align: 'right'
                },
                {
                    id: 'serialNumber',
                    label: 'Serial Number',
                    align: 'right'
                },
                {
                    id: 'upgradeFlag',
                    label: 'Upgrade Flag',
                    align: 'right',
                    format: value => value.toLocaleString()
                },
                {
                    id: 'pkid',
                    label: 'PKID',
                    align: 'right'
                },
                {
                    id: 'partNumber',
                    label: 'Part Number',
                    align: 'right'
                },
                {
                    id: 'partNumberDescription',
                    label: 'Part Number Description',
                    align: 'right'
                },
                {
                    id: 'partNumberProductFamily',
                    label: 'Product Family',
                    align: 'right'
                },
                {
                    id: 'algorithmId',
                    label: 'Algorithm',
                    align: 'right'
                },
                {
                    id: 'isPKIDSlkDistributed',
                    label: 'PKID Slk Distributed',
                    align: 'right',
                    format: value => value.toLocaleString()
                }
            ],
            MainTableColumns: [
                {
                    id: 'keyValidationRequestId',
                    label: 'Key Validation Request Id'
                },
                {
                    id: 'keyValidationRequestStatus',
                    label: 'Status'
                },
                {
                    id: 'createdDate',
                    label: 'Created Date'
                }]
        },
        HashFileDownload: {
            hashFileTableColumn: [
                {
                    id: 'name',
                    label: 'Name',
                    align: 'left'
                }
            ]
        },
        ManageTenantProfile: {
            TenantProfileTableColumn: [
                {
                    id: 'tenantName',
                    label: 'Organization Name',
                    align: 'right'
                },
                {
                    id: 'tenantCode',
                    label: 'Organization Code',
                    align: 'right'
                },
                {
                    id: 'typeOfTenant',
                    label: 'Tenant Role Name',
                    align: 'right'
                },
                {
                    id: 'hasVolumeLicenseRestriction',
                    label: 'Is VLK',
                    align: 'right'
                },
                {
                    id: 'maximumQuantityPerDay',
                    label: 'Daily Key Limit',
                    align: 'right'
                }
            ],
            TenantCertificateTableColumn: [
                {
                    id: 'certificateId',
                    label: 'Certificate Id',
                    align: 'right'
                },
                {
                    id: 'isActive',
                    label: 'Is Active',
                    align: 'right'
                },
                {
                    id: 'thumbprint',
                    label: 'Thumbprint',
                    align: 'right'
                },
                {
                    id: 'expiryDate',
                    label: 'Expiration Date',
                    align: 'right'
                }
            ]
        },
        RSMOrgProfileCert: {
            RSMCertTableColumn: [
                {
                    id: 'tenantName',
                    label: 'Organization Name',
                    align: 'right'
                },
                {
                    id: 'certificateId',
                    label: 'Cert Version',
                    align: 'right'
                },
                {
                    id: 'expiryDate',
                    label: 'Expiration Date',
                    align: 'right'
                },
                {
                    id: 'isActive',
                    label: 'Is Active',
                    align: 'right'
                },
                {
                    id: 'isLoaded',
                    label: 'Is Loaded',
                    align: 'right'
                },
            ]
        },
        KeyCheckTool: {
            ToolsDownloadTableColumn: [
                {
                    id: 'toolName',
                    label: 'Tool Name',
                    align: 'left'
                },
                {
                    id: 'shortDescription',
                    label: 'Short Description',
                    align: 'left'
                },
            ]
        },
        SubmitRequest: {
            ToolUserGuideDownloadTableColumn: [
                {
                    id: 'name',
                    label: 'Name',
                    align: 'left'
                }
            ]
        }
    },
    TableRows: {
        HashFileDownload: {
            conatinerNameRows: [
                {
                    name: 'hashfilestorage'
                }
            ]
        },
        KeyCheckTool: {
            ToolsDownloadTableRows: [
                {
                    toolName: 'KeyCheck Tool',
                    shortDescription: 'KCT is used to identify the individual Product Key attributes and decrypt RSM Product Key files',
                    fileName: 'KeyCheckToolSetup.msi'
                },
                {
                    toolName: 'KCT updated PKPN Configuration Data',
                    shortDescription: 'Updated PKPN Configuration data KCT file',
                    fileName: 'S007831.exe'
                },
                {
                    toolName: 'KCT User Guide',
                    shortDescription: 'Guide on how to use KeyCheck Tool',
                    fileName: 'Keycheck Tool User Guide Internal Version.docx'
                }
            ]

        },
        SubmitRequest: {
            ToolUserGuideDownloadTableRows: [
                {
                    name: 'Tool User Guide'
                }
            ]
        }
    }
}