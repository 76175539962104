import React, { Component, useEffect } from 'react';
import { makeStyles, useTheme, lighten } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Button, TextField, ButtonGroup, TableFooter, TablePagination, ClickAwayListener, FormGroup, Collapse, Tooltip, TableSortLabel, FormLabel } from '@material-ui/core';
import './RSMCertManagement.css';
import { ApiCall } from '../../Utilities/ApiCall';
import { Backdrop, CircularProgress} from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import MuiAlert from '@material-ui/lab/Alert';
import ApplicationConstants from '../../Utilities/ApplicationConstants';
import PropTypes from 'prop-types';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
    title: {
        fontFamily: '"Segoe UI VSS (Regular)","Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
        color: 'black',
        fontWeight: 450,
        fontSize: '18px'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    container: {
        maxHeight: '40em',
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
        fontFamily: '"Segoe UI VSS (Regular)","Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
}));

const useStyles1 = makeStyles(theme => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = event => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = event => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = event => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = event => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root} >
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

const dummyData = [{ 'tenantOrganization': 'Microsoft' }, { 'tenantOrganization': 'Amazon' }];

function View(props) {

    const classes = useStyles();
    const theme = useTheme();
    const anchorRef = React.useRef(null);
    const [openLoading, setOpenLoading] = React.useState(props.loading);
    const [openSnackBar, setOpenSnackBar] = React.useState(false);
    const [snackBarMessage, setSnackBarMessage] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(15);
    const [selectedRow, setSelectedRow] = React.useState([]);
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('createdDate');
    const [rsmCertificates, setRsmCertificates] = React.useState([]);
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertSelector, setAlertSelector] = React.useState(0);
    const [openAlert, setOpenAlert] = React.useState(false);
    const [propsLoading, setPropsLoading] = React.useState(false);
    const [totalRecords, setTotalRecords] = React.useState(0);

    useEffect(() => {
        if (props.errorCode !== 0 && propsLoading === false && props.loading === false) {
            setAlertMessage(ApplicationConstants.AlertMessages.ErrorCode + props.errorCode + ApplicationConstants.AlertMessages.ErrorMessage + props.errorDescription);
            setAlertSelector(0);
            setOpenAlert(true);
            setOpenLoading(false);
            setPropsLoading(true);
        } else if (propsLoading === false && props.loading === false) {
            if (Array.isArray(rsmCertificates) && rsmCertificates.length === 0) {
                setRsmCertificates(props.rsmCertificates);
                setTotalRecords(props.totalRecords);
            }
            setOpenLoading(props.loading)
            setPropsLoading(true);
        }
    });

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            setOpenAlert(false);
            return;
        }

        setOpenAlert(false);
    };
    const handleRequestSort = (event, property, tableSelector) => {
        if (tableSelector === 0) {
            const isAsc = orderBy === property && order === 'asc';
            setOrder(isAsc ? 'desc' : 'asc');
            setOrderBy(property);
        }
    };

    const handleRowClick = (event, row) => {
        const selectedIndex = selectedRow.indexOf(row);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedRow, row);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedRow.slice(1));
        } else if (selectedIndex === selectedRow.length - 1) {
            newSelected = newSelected.concat(selectedRow.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedRow.slice(0, selectedIndex),
                selectedRow.slice(selectedIndex + 1),
            );
        }

        setSelectedRow(newSelected);
    };
    const createSortHandler = (property, tableSelector) => (event) => {
        handleRequestSort(event, property, tableSelector);
    };

    const handleSnackBarClose = () => {
        setOpenSnackBar(false);
    }

    const handleChangePage = async (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = async event => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };

    const isSelected = (row) => selectedRow.indexOf(row) !== -1;

    const BottomSnackBar = (<Snackbar
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        style={{ 'fontSize': '12px' }}
        open={openSnackBar}
        autoHideDuration={6000}
        message={snackBarMessage}
        action={
            <React.Fragment>
                <IconButton size="large" aria-label="close" color="inherit" onClick={handleSnackBarClose}>
                    <CloseIcon fontSize="large" />
                </IconButton>
            </React.Fragment>
        }
    />
    )

    //selector = 0 for error
    //selector = 1 for success
    //selector = 2 for info
    const AlertSnackBar = (selector) => (
        <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleAlertClose}>
            {selector === 0 ?
                <Alert onClose={handleAlertClose} severity="error">
                    {alertMessage}
                </Alert>
                : selector === 1 ?
                    <Alert onClose={handleAlertClose} severity="success">
                        {alertMessage}
                    </Alert> :
                    <Alert onClose={handleAlertClose} severity="info">
                        {alertMessage}
                    </Alert>
            }
        </Snackbar>
    )

    const RSMOrgCertTable = (<Card className='CardOverflow'>
        <TableContainer className={classes.container} >
            <Table id="pkpnTable" aria-label="sticky table" className='MTPTable'>
                <TableHead>
                    <TableRow>
                        {ApplicationConstants.TableColumns.RSMOrgProfileCert.RSMCertTableColumn.map(column => (
                            <TableCell id="RSMOrgCertTableHeader" className="headerBorder"
                                key={column.id}
                                style={{ minWidth: column.minWidth }}
                                sortDirection={orderBy === column.id ? order : false}>

                                <TableSortLabel
                                    active={orderBy === column.id}
                                    direction={orderBy === column.id ? order : 'asc'}
                                    onClick={createSortHandler(column.id, 0)}
                                >

                                    {column.label}
                                    {orderBy === column.id ? (
                                        <span className={classes.visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                    ) : null}
                                </TableSortLabel>

                            </TableCell>

                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rsmCertificates ?
                        (rowsPerPage > 0
                            ? stableSort(rsmCertificates, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : rsmCertificates
                        ).map(row => {
                            const isItemSelected = isSelected(row);
                            const tenantName = row.tenantName;
                            if (row?.encryptionCertificates && row?.encryptionCertificates.length > 0) {
                                return(
                                row.encryptionCertificates.map(certificateRow => {
                                    console.log(certificateRow);
                                    return (
                                        <React.Fragment>
                                            <TableRow hover role="checkbox" tabIndex={-1} selected={isItemSelected} className='MTPTableBody'>
                                                {ApplicationConstants.TableColumns.RSMOrgProfileCert.RSMCertTableColumn.map(column => {
                                                    if ((column.id == "expiryDate") && certificateRow[column.id] != null) {
                                                        certificateRow[column.id] = new Date(certificateRow[column.id]).toLocaleString();
                                                    }
                                                    if (column.id == "tenantName") {
                                                        certificateRow[column.id] = tenantName;
                                                    }
                                                    if (column.id == "isActive") {
                                                        certificateRow[column.id] = certificateRow[column.id].toString();
                                                    }
                                                    var value = certificateRow[column.id];
                                                    if (!value) {
                                                        value = "-";
                                                    }
                                                    return (
                                                        <TableCell id="RSMCertTableBody" key={column.id}>
                                                            {column.format && typeof value === 'number' ? column.format(value) : value}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        </React.Fragment>
                                    );
                                })
                            
                            )}
                        })
                        : null
                    }
                </TableBody>
            </Table>
        </TableContainer>
        <TablePagination
            rowsPerPageOptions={[15, 30, 45, { label: 'All', value: totalRecords }]}
            colSpan={5}
            count={totalRecords}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
        />
    </Card>)

    return (
        <div className='RowFlex'>
            <Button variant="contained" id='rsmcert-top-button' disabled>
                <div className='rsmcert-top-button-text-div'>
                    {ApplicationConstants.Buttons.RenewAllOrgCertificates}
                    <div style={{ fontFamily: "Segoe MDL2 Assets", fontSize: '1.5em', marginLeft: '1em' }} >&#xEBC4;</div>
                </div>
            </Button>
            {RSMOrgCertTable}
            <Backdrop className={classes.backdrop} open={openLoading} style={{ marginLeft: props.drawerWidth }} >
                <CircularProgress />
            </Backdrop>
            {BottomSnackBar}
            {AlertSnackBar(alertSelector)}
        </div>
    );
}

View.propTypes = {
    error: PropTypes.bool,
    errorDescription: PropTypes.string,
    loading: PropTypes.bool,
    rsmCertificates: PropTypes.array,
    responseCount: PropTypes.number,
    idToken: PropTypes.string,
    accessToken: PropTypes.string,
    totalRecords: PropTypes.number,
    drawerWidth: PropTypes.string
}

export class RSMCertManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorCode: 0,
            errorDescription: '',
            loading: true,
            rsmCertificates: [],
            userPrincipalName: this.props.graphProfile.userPrincipalName,
            idToken: this.props.idToken,
            accessToken: this.props.accessToken,
            totalRecords: 0,
            drawerWidth: this.props.drawerWidth
        }
    }

    componentDidMount() {
        const rsmCertRequest = { TypeOfTenant: ApplicationConstants.Information.RSM };
        this.FetchRSMCertificates(rsmCertRequest);
    }

    async FetchRSMCertificates(rsmCertRequest) {
        console.log(this.state.idToken)
        await ApiCall.CallApiGet(ApplicationConstants.EndPoints.GetTenantProfilesEndPoint, rsmCertRequest, this.props.idToken, this.props.accessToken)
            .then((response) => {
                console.log('logging response in view history');
                console.log(response);
                var totalRecords = 0;
                if (response.tenants && response.tenants.length > 0) {
                    response.tenants.forEach(function (tenant) {
                        totalRecords += tenant.encryptionCertificates.length;
                    })
                }       
                this.setState({
                    errorCode: response.errorCode,
                    errorDescription: response.errorDescription,
                    loading: false,
                    rsmCertificates: response.tenants ? response.tenants : null,
                    totalRecords: totalRecords
                })

            })
            .catch(error => {
                console.log(error);
                this.setState({
                    errorCode: error.status,
                    errorDescription: error.statusText,
                    loading: false,
                    rsmCertificates: null,
                    totalRecords: 0
                })
            });
    }

    render() {

        return (
            <React.Fragment>
                <View {...this.state} />
            </React.Fragment>
        )
    }
}