import React, { Component } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import NavBar from '../NavBar/NavBar';
import './home.css'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid';
import Popper from '@material-ui/core/Popper';
import DownloadIcon from '../../icons/DownloadIcon.png';
import Typography from '@material-ui/core/Typography';
import { List, ListItem, ListItemText, ListItemIcon, Button, Backdrop, CircularProgress, MenuList, MenuItem, Paper, Grow, ButtonGroup, ClickAwayListener} from '@material-ui/core';
import FileDownloadIcon from '@material-ui/icons/DescriptionTwoTone';
import TenantIcon from '@material-ui/icons/PersonAddTwoTone';
import DashboardIcon from '@material-ui/icons/DashboardTwoTone';
import PrivacyIcon from '../../icons/Privacy.png';
import ValidationIcon from '@material-ui/icons/DiscFullTwoTone';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import MuiAlert from '@material-ui/lab/Alert';
import ActivitiesIcon from '@material-ui/icons/TrendingUpTwoTone';
import SubmitRequest from '../KeyValidation/SubmitRequest';
import Header from '../Header/Header';
import { ProductKeyDistributionDashboard } from '../RequestDashboard/ProductKeyDistributionDashboard';
import { ProductKeyValidationDashboard } from '../RequestDashboard/ProductKeyValidationDashboard';
import { ViewRequestHistory } from '../KeyValidation/ViewRequestHistory';
import { HashFileDownload } from '../HashFileDownload/HashFileDownload';
import { ManageTenantProfile } from '../TenantOnboardingTool/ManageTenantProfile';
import { RSMCertManagement } from '../TenantOnboardingTool/RSMCertManagement';
import { KeyCheckTool } from '../KeyCheckTool/KeyCheckTool';
import { PrivacyPolicy } from '../PrivacyPolicy/PrivacyPolicy';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams
} from "react-router-dom";
import UnauthorizedAccess from '../UnauthorizedAccess/UnauthorizedAccess';
import ApplicationConstants from '../../Utilities/ApplicationConstants';
import { ApiCall } from '../../Utilities/ApiCall';
import * as FileSaver from 'file-saver';
import IconButton from '@material-ui/core/IconButton';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
    title: {
        fontFamily: '"Segoe UI VSS (Regular)","Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
        color: 'black',
        fontWeight: 450,
        fontSize: '18px',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));


export default function Home(props) {

    const classes = useStyles();
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorSubActionEl, setAnchorSubActionEl] = React.useState(null);
    const [subActionOpen, setSubActionOpen] = React.useState(false);
    const [actionHeader, setActionHeader] = React.useState('');
    const [index, setIndex] = React.useState(0);
    const anchorRef = React.useRef(null);
    const [showTooltip, setShowTooltip] = React.useState(false)
    const [subActionIndex, setSubActionIndex] = React.useState(0);
    const [actionSelected, setActionSelected] = React.useState(props.actionSelected);
    const [subActionSelected, setSubActionSelected] = React.useState(props.subActionSelected);
    const [openLoading, setOpenLoading] = React.useState(props.loading);
    const [openSnackBar, setOpenSnackBar] = React.useState(false);
    const [snackBarMessage, setSnackBarMessage] = React.useState('');
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertSelector, setAlertSelector] = React.useState(0);
    const [openAlert, setOpenAlert] = React.useState(false);
    const [selectedSplitButtonIndex, setSelectedSplitButtonIndex] = React.useState(0);
    const [drawerWidth, setDrawerWidth] = React.useState("22em");
    const [openSplitButton, setOpenSplitButton] = React.useState(false);

    const NavBarActions = [
        {
            name: "Request Dashboard", icon: <DashboardIcon style={{ fontSize: 40 }} />
        },
        {
            name: "Product Key Validation Tool", icon: <ValidationIcon color='primary' style={{ fontSize: 40 }} />
        },
        {
            name: "Key Check Tool", icon: <div style={{ fontFamily: "Segoe MDL2 Assets", fontSize: '1.5em' }} >&#xE896;</div>, action: <KeyCheckTool {...props} drawerWidth={drawerWidth} />, 
        },
        {
            name: "SLK Activities", icon: <ActivitiesIcon color='primary' style={{ fontSize: 40 }} />
        },
        {
            name: "Tenant Onboarding Tool", icon: <TenantIcon color='primary' style={{ fontSize: 40 }} />
        },
        {
            name: "PKPN Hash File Download", icon: <FileDownloadIcon style={{ fontSize: 40 }} />, action: <HashFileDownload {...props} drawerWidth={drawerWidth} />, 
            downloadGuideOptions: [
                { buttonName: ApplicationConstants.Buttons.ToolUserGuide, guideFileName: 'PKPN Hash File Download User Guide.docx' },
                { buttonName: ApplicationConstants.Buttons.HashWorkOrderReport, guideFileName: 'SLK V1 PKPN Hash Work Order Report.xlsx' }]
        }
        ,
        {
            name: "Privacy Policy", icon: <img src={PrivacyIcon} alt="Privacy Icon" style={{ height: '40px' }} />, link: "/privacypolicy", action: <PrivacyPolicy {...props} drawerWidth={drawerWidth} />,
        }
    ]
    const subActionsMenu = [
        [
            {
                name: 'PK Distribution Request', icon: < div style={{ fontFamily: "Segoe MDL2 Assets", fontSize: '1.5em' }} >&#xEBD2;</div >, action: <ProductKeyDistributionDashboard {...props} drawerWidth={drawerWidth}/>, subHeaderPrefix: 'Dashboard -'
            },
            { name: 'PK Validation Request', icon: < div style={{ fontFamily: "Segoe MDL2 Assets", fontSize: '1.5em' }} >&#xE74C;</div >, action: <ProductKeyValidationDashboard  {...props} drawerWidth={drawerWidth}/>, subHeaderPrefix: 'Dashboard -'}
        ],
        [
            {
                name: 'Submit Request', icon: < div style={{ fontFamily: "Segoe MDL2 Assets", fontSize: '1.5em' }} >&#xE8F3;</div >, action: <SubmitRequest {...props} drawerWidth={drawerWidth} />, subHeaderPrefix: 'Product Key Validation - ',
                downloadGuideOptions: [{ buttonName: ApplicationConstants.Buttons.ToolUserGuide, guideFileName: 'Product Key Validation Tool User Guide.docx' }]},
            {
                name: 'View Request History', icon: <div style={{ fontFamily: "Segoe MDL2 Assets", fontSize: '1.5em' }} >&#xE81C;</div>, action: <ViewRequestHistory {...props} drawerWidth={drawerWidth} />, subHeaderPrefix: 'Product Key Validation -',
                downloadGuideOptions: [{ buttonName: ApplicationConstants.Buttons.ToolUserGuide, guideFileName: 'Product Key Validation Tool User Guide.docx' }]},
        ],
        [],
        [
            { name: 'Office Mac Hash Tool Installation', icon: <div style={{ fontFamily: "Segoe MDL2 Assets", fontSize: '1.5em' }} >&#xE896;</div>},
            { name: 'Office Mac Hash Tool User Guide', icon: <div style={{ fontFamily: "Segoe MDL2 Assets", fontSize: '1.5em' }} >&#xF585;</div>},
            { name: 'Key PKPN SN Range Risk Report', icon: <div style={{ fontFamily: "Segoe MDL2 Assets", fontSize: '1.5em' }} >&#xE9F9;</div>},
            { name: 'Restart KCT Part Data Service', icon: <div style={{ fontFamily: "Segoe MDL2 Assets", fontSize: '1.5em' }} >&#xEBC4;</div>}
        ],
        [
            {
                name: 'Manage Tenant Profile', icon: <div style={{ fontFamily: "Segoe MDL2 Assets", fontSize: '1.5em' }} >&#xEBD2;</div>, action: <ManageTenantProfile {...props} drawerWidth={drawerWidth} />, subHeaderPrefix: 'Tenant Onboarding Tool - ',
                downloadGuideOptions: [{ buttonName: ApplicationConstants.Buttons.ToolUserGuide, guideFileName: 'Tenant Onboarding Tool User Guide.docx' }]},
            { name: 'RSM Org Profile', icon: <div style={{ fontFamily: "Segoe MDL2 Assets", fontSize: '1.5em' }} >&#xE7EE;</div>, action: <RSMCertManagement {...props} drawerWidth={drawerWidth} />, subHeaderPrefix: 'Tenant Onboarding Tool - ',
                downloadGuideOptions: [{ buttonName: ApplicationConstants.Buttons.ToolUserGuide, guideFileName: 'Tenant Onboarding Tool User Guide.docx' }]}
        ],
        [],
        []
    ]

    const handleDropDownClick = (i) => {
        console.log(props.location);
        setActionSelected(index);
        setSubActionSelected(i);
    }

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            setOpenAlert(false);
            return;
        }

        setOpenAlert(false);
    };

    const handleSnackBarClose = () => {
        setOpenSnackBar(false);
    }

    const downloadToolUserGuide = async (fileName) => {
        setOpenLoading(true);
        var payload = { PathPrefix: fileName };
        await ApiCall.CallApiGet(ApplicationConstants.EndPoints.DownloadToolUserGuide, payload, props.idToken, props.accessToken)
            .then((response) => {
                console.log('logging response in view history');
                console.log(response);
                const fileType = 'application/zip';
                const fileExtension = '.zip';
                const buf = Buffer.from(response.fileDataBase64, 'base64');
                const data = new Blob([buf], { type: fileType });
                FileSaver.saveAs(data, fileName.split('.')[0] + fileExtension);
            }
            )
            .catch(error => {
                console.log(error);
                setAlertMessage(ApplicationConstants.AlertMessages.ErrorCode + error.status + ApplicationConstants.AlertMessages.ErrorMessage + error.statusText);
                setAlertSelector(0);
                setOpenAlert(true);
            });
        setOpenLoading(false);
    }

    const handleMenuItemClick = (event, index) => {
        setSelectedSplitButtonIndex(index);
        setOpenSplitButton(false);
    };

    const handleSplitButtonClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
    }

    const handleToggle = () => {
        setOpenSplitButton((prevOpen) => !prevOpen);
    };

    //selector = 0 for error
    //selector = 1 for success
    //selector = 2 for info
    const AlertSnackBar = (selector) => (
        <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleAlertClose}>
            {selector === 0 ?
                <Alert onClose={handleAlertClose} severity="error">
                    {alertMessage}
                </Alert>
                : selector === 1 ?
                    <Alert onClose={handleAlertClose} severity="success">
                        {alertMessage}
                    </Alert> :
                    <Alert onClose={handleAlertClose} severity="info">
                        {alertMessage}
                    </Alert>
            }
        </Snackbar>
    )

    const BottomSnackBar = (<Snackbar
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        style={{ 'fontSize': '12px' }}
        open={openSnackBar}
        autoHideDuration={6000}
        message={snackBarMessage}
        action={
            <React.Fragment>
                <IconButton size="large" aria-label="close" color="inherit" onClick={handleSnackBarClose}>
                    <CloseIcon fontSize="large" />
                </IconButton>
            </React.Fragment>
        }
    />
    )
    const GuideSplitButton = (splitButtonOptions) => (<div className='SplitButton'>
        <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button">
            <Button variant="contained" id="ActionButton" onClick={event => downloadToolUserGuide(splitButtonOptions[selectedSplitButtonIndex].guideFileName)}>
                {splitButtonOptions[selectedSplitButtonIndex].buttonName}  < img src={DownloadIcon} style={{ height: '1.8em' }} className='hfd-download-icon' />
            </Button>
            <Button
                id="ActionButton"
                aria-controls={openSplitButton ? 'split-button-menu' : undefined}
                aria-expanded={openSplitButton ? 'true' : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                onClick={handleToggle}
            >
                <ArrowDropDownIcon />
            </Button>
        </ButtonGroup>
        <Popper open={openSplitButton} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{
                        transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                >
                    <Paper style={{
                        background: 'rgb(224 224 224)', fontFamily: '"Segoe UI VSS (Regular)","Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";' }}>
                        <ClickAwayListener onClickAway={handleSplitButtonClose}>
                            <MenuList id="split-button-menu">
                                {splitButtonOptions.map((option, index) => (
                                    <MenuItem
                                        key={option}
                                        selected={index === selectedSplitButtonIndex}
                                        onClick={(event) => handleMenuItemClick(event, index)}
                                    >
                                        {option.buttonName}
                                    </MenuItem>
                                ))}
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    </div>)

    const SubActionCard = (
        <Grid container direction="row" alignItems="center" id='cardActionBar'>
            <Grid item xs={12}>

                <Popper id='popper' open={subActionOpen} anchorEl={anchorEl} role={undefined} transition disablePortal placement='right-start' >
                    {({ TransitionProps, placement }) => (
                        <Card style={{ backgroundColor:'#d5dbe0' }} onMouseLeave={event => setSubActionOpen(false)}>
                            <CardContent>
                                <Typography className={classes.title, 'SubActionBottomBorder'} gutterBottom>
                                    {actionHeader}
                                </Typography>
                                <List>
                                    {subActionsMenu[index].map((subAction, subactionIndex) => (
                                        <Link to={"/" + NavBarActions[index].name.split(" ").join("").toLowerCase() + "/" + subAction.name.split(" ").join("").toLowerCase()}>
                                            <ListItem button onClick={event => handleDropDownClick(subactionIndex)} disabled={!ApplicationConstants.SecurityGroupDetails[ApplicationConstants.EnvironmentType][index].isAuthorized}>
                                            <ListItemIcon>
                                                {subAction.icon}
                                            </ListItemIcon>
                                            <ListItemText primary={subAction.name} />
                                            </ListItem>
                                        </Link>
                                    ))}
                                </List>
                            </CardContent>
                        </Card>
                    )}
                </Popper>
            </Grid>
        </Grid>)

    const SubActionToolTip = (
        <Grid container direction="row" alignItems="center" id='cardActionBar' className='bolt-tooltip'>
            <Grid item xs={12}>

                <Popper id='popper' className='popper' open={showTooltip} anchorEl={anchorSubActionEl} role={undefined} transition disablePortal placement='right' >
                    {({ TransitionProps, placement }) => (
                        <div className='bolt-tooltip bolt-tooltip-content '>
                            {subActionIndex !== -1 ? subActionsMenu[index][subActionIndex].name : NavBarActions[index].name}
                        </div>
                    )}
                </Popper>
            </Grid>
        </Grid>)

    return (
        <React.Fragment>
            <Header {...props}/>
            <div className="fxs-portal-main"
                onMouseEnter={event => setSubActionOpen(false)}>
                <NavBar setSubActionOpen={setSubActionOpen} setAnchorEl={setAnchorEl} setActionHeader={setActionHeader} setIndex={setIndex} setShowTooltip={setShowTooltip} setAnchorSubActionEl={setAnchorSubActionEl}
                    setSubActionIndex={setSubActionIndex} actionSelected={actionSelected} setActionSelected={setActionSelected} subActionSelected={subActionSelected} setSubActionSelected={setSubActionSelected} setDrawerWidth={setDrawerWidth} />
                {props.tabAccess ?
                    <main className="fxs-panorama fxs-portal-content">
                        {subActionOpen ? SubActionCard : null}
                        {showTooltip ? SubActionToolTip : null}
                        <div className="fxs-home-target">
                            <div className="SubHeader">
                                {subActionsMenu[actionSelected][subActionSelected] ? <div className="SubHeaderWithActionButton"><div className="SubHeaderName">{subActionsMenu[actionSelected][subActionSelected].icon} {subActionsMenu[actionSelected][subActionSelected].subHeaderPrefix ?
                                    <React.Fragment><div style={{ fontWeight: '500' }}>&nbsp; {subActionsMenu[actionSelected][subActionSelected].subHeaderPrefix}&nbsp; </div><React.Fragment> {subActionsMenu[actionSelected][subActionSelected].name}</React.Fragment></React.Fragment>
                                    : subActionsMenu[actionSelected][subActionSelected].name}</div>
                                    {(subActionsMenu[actionSelected][subActionSelected].downloadGuideOptions && subActionsMenu[actionSelected][subActionSelected].downloadGuideOptions.length === 1) ?
                                        <Button variant="contained" id='ActionButton' onClick={event => downloadToolUserGuide(subActionsMenu[actionSelected][subActionSelected].downloadGuideOptions[0].guideFileName)}>
                                            {subActionsMenu[actionSelected][subActionSelected].downloadGuideOptions[0].buttonName} < img src={DownloadIcon} style={{ height: '1.8em' }} className='hfd-download-icon' />
                                        </Button>
                                        : (subActionsMenu[actionSelected][subActionSelected].downloadGuideOptions && subActionsMenu[actionSelected][subActionSelected].downloadGuideOptions.length > 0) ?
                                            GuideSplitButton(subActionsMenu[actionSelected][subActionSelected].downloadGuideOptions)
                                            :
                                            null}
                                    </div>
                                    :
                                    <div className="SubHeaderWithActionButton">
                                        <div className="SubHeaderName" style={{ fontWeight: '500' }}>{NavBarActions[actionSelected].icon} {NavBarActions[actionSelected].name}</div>
                                        {(NavBarActions[actionSelected].downloadGuideOptions && NavBarActions[actionSelected].downloadGuideOptions.length === 1) ?
                                            <Button variant="contained" id='ActionButton' onClick={event => downloadToolUserGuide(NavBarActions[actionSelected].guideFileName)}>
                                                {ApplicationConstants.Buttons.ToolUserGuide} < img src={DownloadIcon} style={{ height: '1.8em' }} className='hfd-download-icon' />
                                            </Button>
                                            : (NavBarActions[actionSelected].downloadGuideOptions && NavBarActions[actionSelected].downloadGuideOptions.length > 0) ?
                                                GuideSplitButton(NavBarActions[actionSelected].downloadGuideOptions)
                                                :
                                                null}
                                    </div>
                                    }
                            </div>
                            {subActionsMenu[actionSelected][subActionSelected] ? subActionsMenu[actionSelected][subActionSelected].action : NavBarActions[actionSelected].action}
                        </div>
                    </main> :
                    <main className="fxs-panorama fxs-portal-content">
                        {subActionOpen ? SubActionCard : null}
                        {showTooltip ? SubActionToolTip : null}
                        <UnauthorizedAccess />
                    </main>
                }
            </div>
            <Router>
                <Switch>
                </Switch>
            </Router>
            <Backdrop className={classes.backdrop} open={openLoading} style={{ marginLeft: drawerWidth }}>
                <CircularProgress />
            </Backdrop>
            {BottomSnackBar}
            {AlertSnackBar(alertSelector)}
        </React.Fragment>
    );
}