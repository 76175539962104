import React, { Component, useEffect } from 'react';
import { makeStyles, useTheme, lighten } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Button, TextField, TablePagination, FormGroup, Collapse, TableSortLabel, FormLabel } from '@material-ui/core';
import './ManageTenantProfile.css';
import { ApiCall } from '../../Utilities/ApiCall';
import { Backdrop, CircularProgress, MenuItem, Grid, Menu, Select, FormControl, Tooltip } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import MuiAlert from '@material-ui/lab/Alert';
import ApplicationConstants from '../../Utilities/ApplicationConstants';
import PropTypes from 'prop-types';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import InfoIcon from '@material-ui/icons/Info';
import AddIcon from '@material-ui/icons/Add';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import axios from 'axios';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
    title: {
        fontFamily: '"Segoe UI VSS (Regular)","Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
        color: 'black',
        fontWeight: 450,
        fontSize: '18px'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    container: {
        maxHeight: '39em',
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
        fontFamily: '"Segoe UI VSS (Regular)","Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    input: {
        display: "none"
    }
}));

const useStyles1 = makeStyles(theme => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));
const useStyles2 = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#137cbd',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#106ba3',
        },
    },
});

function StyledRadio(props) {
    const classes = useStyles2();

    return (
        <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            {...props}
        />
    );
}

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = event => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = event => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = event => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = event => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root} >
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

function View(props) {

    const classes = useStyles();
    const theme = useTheme();
    const anchorRef = React.useRef(null);
    const [openLoading, setOpenLoading] = React.useState(props.loading);
    const [openSnackBar, setOpenSnackBar] = React.useState(false);
    const [snackBarMessage, setSnackBarMessage] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [selectedRow, setSelectedRow] = React.useState([]);
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('createdDate');
    const [tenantProfiles, setTenantProfiles] = React.useState([]);
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertSelector, setAlertSelector] = React.useState(0);
    const [openAlert, setOpenAlert] = React.useState(false);
    const [tenantRole, setTenantRole] = React.useState(ApplicationConstants.Labels.JIT);
    const [editTenantProfle, setEditTenantProfile] = React.useState(false);
    const [addTenant, setAddTenant] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [menuOpen, setMenuOpen] = React.useState(false);
    const [expandedFirstSection, setExpandedFirstSection] = React.useState(true);
    const [expandedSecondSection, setExpandedSecondSection] = React.useState(true);
    const [expandedThirdSection, setExpandedThirdSection] = React.useState(true);
    const [propsLoading, setPropsLoading] = React.useState(false);
    const [selectedTenant, setSelectedTenant] = React.useState([]);
    const [contactEmailAddress, setContactEmailAddress] = React.useState("");
    const [newTenant, setNewTenant] = React.useState({});
    const [DailyKeyLimit, setDailyKeyLimit] = React.useState("0");
    const [TenantResourceId, setTenantResourceId] = React.useState("");
    const [tenantCode, setTenantCode] = React.useState("");
    const [tenantName, setTenantName] = React.useState("");
    const [openDialog, setOpenDialog] = React.useState(false);
    useEffect(() => {
        if (props.errorCode !== 0 && propsLoading === false && props.loading === false) {
            setAlertMessage(ApplicationConstants.AlertMessages.ErrorCode + props.errorCode + ApplicationConstants.AlertMessages.ErrorMessage + props.errorDescription);
            setAlertSelector(0);
            setOpenAlert(true);
            setOpenLoading(false);
            setPropsLoading(true);
        } else if (propsLoading === false && props.loading === false) {
            if (Array.isArray(tenantProfiles) && tenantProfiles.length === 0) {
                setTenantProfiles(props.tenantProfiles);
            }

            setOpenLoading(props.loading)
            setPropsLoading(true);
        }
    });

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            setOpenAlert(false);
            return;
        }

        setOpenAlert(false);
    };

    const handleExpandFirstSectionClick = () => {
        setExpandedFirstSection(!expandedFirstSection);
    };

    const handleExpandSecondSectionClick = () => {
        setExpandedSecondSection(!expandedSecondSection);
    };

    const handleExpandThirdSectionClick = () => {
        setExpandedThirdSection(!expandedThirdSection);
    };

    const handleRequestSort = (event, property, tableSelector) => {
        if (tableSelector === 0) {
            const isAsc = orderBy === property && order === 'asc';
            setOrder(isAsc ? 'desc' : 'asc');
            setOrderBy(property);
        }
    };

    const handleRowClick = (event, row) => {
        const selectedIndex = selectedRow.indexOf(row);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedRow, row);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedRow.slice(1));
        } else if (selectedIndex === selectedRow.length - 1) {
            newSelected = newSelected.concat(selectedRow.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedRow.slice(0, selectedIndex),
                selectedRow.slice(selectedIndex + 1),
            );
        }

        setSelectedRow(newSelected);
    };
    const createSortHandler = (property, tableSelector) => (event) => {
        handleRequestSort(event, property, tableSelector);
    };

    const handleSnackBarClose = () => {
        setOpenSnackBar(false);
    }

    const handleChangePage = async (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = async event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleTenantRoleChange = (event) => {
        setTenantRole(event.target.value);        
        newTenant.typeOfTenant = event.target.value;        
        if (newTenant.typeOfTenant === ApplicationConstants.Labels.RSM) {
            newTenant.maximumQuantityPerDay = ApplicationConstants.AppSettings[ApplicationConstants.EnvironmentType]?.RSMDailyKeyLimit;
            setDailyKeyLimit(ApplicationConstants.AppSettings[ApplicationConstants.EnvironmentType]?.RSMDailyKeyLimit);
            newTenant.callbackResourceId = ApplicationConstants.AppSettings[ApplicationConstants.EnvironmentType]?.RSMResourceId;
            
            setTenantResourceId(ApplicationConstants.AppSettings[ApplicationConstants.EnvironmentType]?.RSMResourceId);
        }
        else {
            setTenantResourceId("");
            newTenant.callbackResourceId = "";
            setDailyKeyLimit("");
            newTenant.maximumQuantityPerDay = 0;
        }
    };

    const isSelected = (row) => selectedRow.indexOf(row) !== -1;

    const handleFormCancel = () => {
        setEditTenantProfile(false);
        setExpandedFirstSection(true);
        setExpandedSecondSection(true);
        setExpandedThirdSection(true);
    }

    const handleAddFormCancel = () => {
        setAddTenant(false);
        setExpandedFirstSection(true);
        setExpandedSecondSection(true);
        setExpandedThirdSection(true);
    }

    const handleMoreIconClick = (event, row) => {
        setAnchorEl(event.currentTarget);
        setMenuOpen(true);
        setSelectedTenant(JSON.parse(JSON.stringify(row)));
        setTenantRole(row.typeOfTenant);
        var contact = "";
        if (row.contactEmailAddresses && row.contactEmailAddresses.length > 0) {
            for (var y = 0; y < row.contactEmailAddresses.length; y++) {
                contact += row.contactEmailAddresses[y] + "\n";
            }
        }
        setContactEmailAddress(contact)
    };

    const handleMenuClose = () => {
        setMenuOpen(false);
    };

    const handleEditRowClick = () => {
        setMenuOpen(false);
        setEditTenantProfile(true);
    };

    const handleDeleteRowClick = () => {
        selectedTenant.isActive = false;        
        setOpenDialog(true);
        setMenuOpen(false);
    };

    const handleAddTenant = () => {
        setAddTenant(true);
        setNewTenant({});
        setTenantRole(null);
        setTenantResourceId('');
        setDailyKeyLimit('');
        setContactEmailAddress('');
        setTenantName("");
        setTenantCode("");
    }

    const Sleep = (milliseconds) => {
        const date = Date.now();
        let currentDate = null;
        do {
            currentDate = Date.now();
        } while (currentDate - date < milliseconds);
    }

    const handleEditFormSave = async () => {
        setOpenLoading(true);
        setOpenSnackBar(true);
        setSnackBarMessage(ApplicationConstants.AlertMessages.PleaseWait)
        await ApiCall.CallApiPost(ApplicationConstants.EndPoints.UpdateTenantEndPoint, { Tenant : selectedTenant }, props.idToken, props.accessToken)
            .then(async (response) => {
                console.log('logging response in view history');
                if (parseInt(response.errorCode) === 0) {
                    Sleep(2000);
                    await FetchTenantProfiles({ TenantCode: "" });
                    setAlertMessage(ApplicationConstants.AlertMessages.Success);
                    setAlertSelector(1);
                    setOpenAlert(true);
                    setEditTenantProfile(false);
                }
                else {
                    setAlertMessage(ApplicationConstants.AlertMessages.ErrorCode + response.errorCode + ApplicationConstants.AlertMessages.ErrorMessage + response.errorDescription);
                    setAlertSelector(0);
                    setOpenAlert(true);
                }
                console.log(response);
            })
            .catch(error => {
                console.log(error);
                setAlertMessage(ApplicationConstants.AlertMessages.ErrorCode + error.status + ApplicationConstants.AlertMessages.ErrorMessage + error.statusText);
                setAlertSelector(0);
                setOpenAlert(true);
            });
        setOpenLoading(false);
        setOpenSnackBar(false);
    }

    const handleAddFormSave = async () => {
        newTenant.isActive = true;
        // Validate If tenantcode already exists before making call to backend API.
        var isTenantCodeExists = false;
        tenantProfiles.forEach(profile => { if (profile.tenantCode.localeCompare(newTenant.tenantCode, undefined, { sensitivity: 'accent' }) === 0) { isTenantCodeExists = true; } })
        if (isTenantCodeExists === true) {
            setAlertMessage(ApplicationConstants.AlertMessages.TenantCodeAlreadyExists);
            setAlertSelector(0);
            setOpenAlert(true);
        }
        else {
            setOpenLoading(true);
            setOpenSnackBar(true);
            setSnackBarMessage(ApplicationConstants.AlertMessages.PleaseWait)
            await ApiCall.CallApiPost(ApplicationConstants.EndPoints.NewTenantEndPoint, { Tenant: newTenant }, props.idToken, props.accessToken)
                .then(async (response) => {
                    console.log('logging response in view history');
                    if (parseInt(response.errorCode) === 0) {
                        Sleep(2000);
                        await FetchTenantProfiles({ TenantCode: "" });
                        setAlertMessage(ApplicationConstants.AlertMessages.Success);
                        setAlertSelector(1);
                        setOpenAlert(true);
                        setAddTenant(false);
                    }
                    else {
                        setAlertMessage(ApplicationConstants.AlertMessages.ErrorCode + response.errorCode + ApplicationConstants.AlertMessages.ErrorMessage + response.errorDescription);
                        setAlertSelector(0);
                        setOpenAlert(true);
                    }
                    console.log(response);
                })
                .catch(error => {
                    console.log(error);
                    setAlertMessage(ApplicationConstants.AlertMessages.ErrorCode + error.status + ApplicationConstants.AlertMessages.ErrorMessage + error.statusText);
                    setAlertSelector(0);
                    setOpenAlert(true);
                });
            setOpenLoading(false);
            setOpenSnackBar(false);
        }
    }

    const handleLocationNavigation = () => {
        if (editTenantProfle || addTenant) {
            setEditTenantProfile(false);
            setAddTenant(false);
        }
    }

    const FetchTenantProfiles = async (tenantProfileRequest) =>{
        await ApiCall.CallApiGet(ApplicationConstants.EndPoints.GetTenantProfilesEndPoint, tenantProfileRequest, props.idToken, props.accessToken)
                .then((response) => {
                    if (parseInt(response.errorCode) === 0) {
                        setTenantProfiles(response.tenants);
                    }
                })
            .catch(error => {
                console.log(error);
            });
    }

    const handleEditUploadCert = async (event) => {
        setOpenLoading(true);
        setOpenSnackBar(true);
        setSnackBarMessage(ApplicationConstants.AlertMessages.PleaseWait)
        const url = "TenantOnboarding/uploadcertificate";
        const formData = new FormData();
        formData.append('request', event.target.files[0]);
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${props.idToken}`,
                'Authorization-AccessToken': `Bearer ${props.accessToken}`
            },
        };
        const response = await axios.post(url, formData, config);
        const data = await response.data;

        console.log(response);
        if (data.errorCode === 0) {
            selectedTenant.encryptionCertificates.push({ ExpiryDate: data.expiryDate, Thumbprint: data.thumbprint, IsActive: data.isActive, CertificateId: data.certificateId });
            setAlertMessage(ApplicationConstants.AlertMessages.CertificateSuccessfullyUploaded);
            setAlertSelector(2);
            setOpenAlert(true);
        } else {
            setAlertMessage(ApplicationConstants.AlertMessages.ErrorCode + response.errorCode + ApplicationConstants.AlertMessages.ErrorMessage + response.errorDescription);
            setAlertSelector(0);
            setOpenAlert(true);
        }
        setOpenLoading(false);
        setOpenSnackBar(false);
    }

    const handleAddUploadCert = async (event) => {
        setOpenLoading(true);
        setOpenSnackBar(true);
        setSnackBarMessage(ApplicationConstants.AlertMessages.PleaseWait)
        const url = "TenantOnboarding/uploadcertificate";
        const formData = new FormData();
        formData.append('request', event.target.files[0]);
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${props.idToken}`,
                'Authorization-AccessToken': `Bearer ${props.accessToken}`
            },
        };
        const response = await axios.post(url, formData, config);
        const data = await response.data;

        console.log(response);
        if (data.errorCode === 0) {
            newTenant.encryptionCertificates = [{ ExpiryDate: data.expiryDate, Thumbprint: data.thumbprint, IsActive: data.isActive, CertificateId: data.certificateId }];
            setAlertMessage(ApplicationConstants.AlertMessages.CertificateSuccessfullyUploaded);
            setAlertSelector(2);
            setOpenAlert(true);
        } else {
            setAlertMessage(ApplicationConstants.AlertMessages.ErrorCode + response.errorCode + ApplicationConstants.AlertMessages.ErrorMessage + response.errorDescription);
            setAlertSelector(0);
            setOpenAlert(true);
        }
        setOpenLoading(false);
        setOpenSnackBar(false);
    }

    const BottomSnackBar = (<Snackbar
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        style={{ 'fontSize': '12px' }}
        open={openSnackBar}
        autoHideDuration={6000}
        message={snackBarMessage}
        action={
            <React.Fragment>
                <IconButton size="large" aria-label="close" color="inherit" onClick={handleSnackBarClose}>
                    <CloseIcon fontSize="large" />
                </IconButton>
            </React.Fragment>
        }
    />
    )

    //selector = 0 for error
    //selector = 1 for success
    //selector = 2 for info
    const AlertSnackBar = (selector) => (
        <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleAlertClose}>
            {selector === 0 ?
                <Alert onClose={handleAlertClose} severity="error">
                    {alertMessage}
                </Alert>
                : selector === 1 ?
                    <Alert onClose={handleAlertClose} severity="success">
                        {alertMessage}
                    </Alert> :
                    <Alert onClose={handleAlertClose} severity="info">
                        {alertMessage}
                    </Alert>
            }
        </Snackbar>
    )

    const handleClose = () => {
        setOpenDialog(false);        
    };

    const handleDeleteRecord = async () => {
        setOpenDialog(false);
        await handleEditFormSave();        
    };
    const ShowDialog = (properties) => (
        <Dialog open={openDialog} onClose={handleClose}
                aria-labelledby="Deleting tenant"
        >
            <DialogContent>
                <DialogContentText id="deleteTenant">
                    Do you want to delete tenant - {properties.tenantCode} ?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDeleteRecord} color="primary" >
                    Yes
                </Button>
                <Button onClick={handleClose} color="primary" >
                    No
                </Button>
            </DialogActions>
        </Dialog>
    );
    const TenantCertificateTable = (tenant) =>
        (<Card className='CardOverflow'>
        <TableContainer className={classes.container} >
                <Table id="mtp-certificateTable" aria-label="sticky table">
                <TableHead>
                    <TableRow>
                        {ApplicationConstants.TableColumns.ManageTenantProfile.TenantCertificateTableColumn.map(column => (
                            <TableCell id="TenantCertTableHeader" className="headerBorder"
                                key={column.id}
                                style={{ minWidth: column.minWidth }}>
                                {column.label}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                        {tenant.activeEncryptionCertificate != null?
                        <TableRow hover role="checkbox" tabIndex={-1} >
                            {ApplicationConstants.TableColumns.ManageTenantProfile.TenantCertificateTableColumn.map(column => {
                                if ((column.id == "expiryDate") && tenant[column.id] != null) {
                                    tenant.activeEncryptionCertificate[column.id] = new Date(tenant.activeEncryptionCertificate[column.id]).toLocaleString();
                                }
                                if ((column.id == "isActive") && tenant[column.id] != null) {
                                    tenant.activeEncryptionCertificate[column.id] = tenant.activeEncryptionCertificate[column.id].toString();
                                }
                                var value = tenant?.activeEncryptionCertificate[column.id];
                                if (!value) {
                                    value = '-';
                                }
                                return (
                                    <TableCell id="TenantCertTableBody" key={column.id}>
                                        {column.format && typeof value === 'number' ? column.format(value) : value}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                        : null
                    }
                </TableBody>
            </Table>
        </TableContainer>
    </Card>)

    const EditTenantProfileForm = (<FormGroup id="mtp-form-flex-root">
        <Card className='mtp-form-card-margin' id='mtp-form-card'>
            <FormControl id="mtp-form-flex-control">
                <div>
                    <FormLabel className="mtp-form-label">{ApplicationConstants.Labels.CustomerProfile}</FormLabel>
                    <IconButton
                        className={clsx(classes.expand, {
                            [classes.expandOpen]: expandedFirstSection,
                        })}
                        onClick={handleExpandFirstSectionClick}
                        aria-expanded={expandedFirstSection}
                        aria-label="show more"
                    >

                        <ExpandMoreIcon />
                    </IconButton>
                </div>
                <Collapse in={expandedFirstSection} timeout="auto" unmountOnExit>

                    <Grid spacing={2} className='mtp-edit-form'>
                        <FormGroup id="mtp-form-flex">
                            <FormGroup className='mtp-form-width-columns'>
                                <Grid item xs={6}>
                                    <label for="OrganizationCode" className="productSelectFormLabel">{ApplicationConstants.Labels.OrganizationCode}</label>
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField id="OrganizationCode" className="" variant="outlined" value={selectedTenant.tenantCode} disabled />
                                </Grid>
                            </FormGroup>
                            <FormGroup>
                                <Grid item xs={6}>
                                    <label for="TenantRole" className="productSelectFormLabel">{ApplicationConstants.Labels.TenantRole}</label>
                                </Grid>
                                <Grid item xs={8}>
                                    <FormControl className={classes.formControl}>
                                        <Select
                                            defaultValue={selectedTenant.typeOfTenant}
                                            disabled
                                            displayEmpty
                                        >
                                            <MenuItem value={ApplicationConstants.Labels.JIT} selected={selectedTenant.typeOfTenant == ApplicationConstants.Labels.JIT}>{ApplicationConstants.Labels.JIT}</MenuItem>
                                            <MenuItem value={ApplicationConstants.Labels.RSM} selected={selectedTenant.typeOfTenant == ApplicationConstants.Labels.RSM}>{ApplicationConstants.Labels.RSM}</MenuItem>
                                            <MenuItem value={ApplicationConstants.Labels.SLK} selected={selectedTenant.typeOfTenant == ApplicationConstants.Labels.SLK}>{ApplicationConstants.Labels.SLK}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </FormGroup>
                        </FormGroup>
                        <FormGroup className='mtp-form-width-columns'>
                            <Grid item xs={6}>
                                <label for="enterVersion" className="productSelectFormLabel">{ApplicationConstants.Labels.TenantOrganization}</label>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField id="enterVersion" className="" variant="outlined" defaultValue={selectedTenant.tenantName} onChange={event => selectedTenant.tenantName = event.target.value} />
                            </Grid>
                        </FormGroup>
                        <FormGroup className='mtp-form-width-columns'>
                            <Grid item xs={6}>
                                <label for="maximumQuantityPerDay" className="productSelectFormLabel">{ApplicationConstants.Labels.DailyKeyLimit}</label>
                                {selectedTenant.typeOfTenant == ApplicationConstants.Labels.RSM ?
                                    <Tooltip title={ApplicationConstants.InfoIconsText.RSMDailyKeyLimit}>
                                        <IconButton aria-label={ApplicationConstants.InfoIconsText.RSMDailyKeyLimit}>
                                            <InfoIcon style={{ fontSize: 18 }} />
                                        </IconButton>
                                    </Tooltip> :
                                    null
                                }
                            </Grid>
                            <Grid item xs={8}>
                                <TextField id="maximumQuantityPerDay" className="" variant="outlined" defaultValue={selectedTenant.maximumQuantityPerDay} onChange={e => { selectedTenant.maximumQuantityPerDay = e.target.value }} disabled={selectedTenant.typeOfTenant == ApplicationConstants.Labels.RSM }/>
                            </Grid>
                        </FormGroup>
                        <FormGroup className='mtp-form-width-columns'>
                            <Grid item xs={6}>
                                <label for="IsVLK" className="productSelectFormLabel">{ApplicationConstants.Labels.IsVLK}</label>
                            </Grid>
                            <Grid item xs={8}>
                                <RadioGroup defaultValue={selectedTenant?.hasVolumeLicenseRestriction?.toString()} aria-label="IsVLK" name="customized-radios" onChange={e => { selectedTenant.hasVolumeLicenseRestriction = e.target.value }}>
                                    <FormControlLabel value="true" id="mtp-radioButtons" control={<StyledRadio />} label={ApplicationConstants.Labels.True} />
                                    <FormControlLabel value="false" id="mtp-radioButtons" control={<StyledRadio />} label={ApplicationConstants.Labels.False} />
                                </RadioGroup>
                            </Grid>
                        </FormGroup>
                        <FormGroup className='mtp-form-width-columns'>
                            <Grid item xs={6}>
                                <label for="editTenantResourceIdField" className="productSelectFormLabel">{ApplicationConstants.Labels.TenantResourceId}</label>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField id="editTenantResourceIdField" className="" variant="outlined" defaultValue={selectedTenant.callbackResourceId} onChange={e => { selectedTenant.callbackResourceId = e.target.value }}
                                    disabled={selectedTenant.typeOfTenant == ApplicationConstants.Labels.RSM}/>
                            </Grid>
                        </FormGroup>
                    </Grid>
                </Collapse>
            </FormControl>
        </Card>
        <Card className='mtp-form-card-margin' id='mtp-form-card'>
            <FormControl id="mtp-tenant-cert-table">
                <div>
                    <FormLabel className="mtp-form-label">{ApplicationConstants.Labels.EncryptionCertificate}</FormLabel>
                    <IconButton
                        className={clsx(classes.expand, {
                            [classes.expandOpen]: expandedSecondSection,
                        })}
                        onClick={handleExpandSecondSectionClick}
                        aria-expanded={expandedSecondSection}
                        aria-label="show more"
                    >

                        <ExpandMoreIcon />
                    </IconButton>
                </div>

                <Collapse in={expandedSecondSection} timeout="auto" unmountOnExit>
                    <FormGroup>
                        {TenantCertificateTable(selectedTenant)}
                        {tenantRole?.localeCompare(ApplicationConstants.Labels.JIT) === 0 || tenantRole?.localeCompare(ApplicationConstants.Labels.SLK) === 0 ?
                            <React.Fragment>
                            <input
                                accept=".pfx,.p12, .crt"
                                className={classes.input}
                                    id="certUpload"
                                    required
                                onChange={event => handleEditUploadCert(event)}
                                type="file"
                                />
                                <Button color="primary" style={{ margin: "1em 0em" }} onClick={event => { document.getElementById('certUpload').click(); }}>
                                {ApplicationConstants.Buttons.Upload}
                                </Button>
                            </React.Fragment>
                            : <div className='mtp-table-margin'>
                                <Button color="primary" disabled>
                                    {ApplicationConstants.Buttons.Renew}
                                </Button>
                                <Button color="primary" style={{ margin: "0em 5em" }} disabled>
                                    {ApplicationConstants.Buttons.SendExport}
                                </Button>
                                <Button color="primary" disabled>
                                    {ApplicationConstants.Buttons.Activate}
                                </Button>
                            </div>}
                    </FormGroup>
                </Collapse>
            </FormControl>
        </Card>
        <Card className='mtp-form-card-margin' id='mtp-form-card'>
            <FormControl id="mtp-form-flex-control">
                <div>
                    <FormLabel className="mtp-form-label">{ApplicationConstants.Labels.EmailContactInformation}</FormLabel>
                    <IconButton
                        className={clsx(classes.expand, {
                            [classes.expandOpen]: expandedThirdSection,
                        })}
                        onClick={handleExpandThirdSectionClick}
                        aria-expanded={expandedThirdSection}
                        aria-label="show more"
                    >

                        <ExpandMoreIcon />
                    </IconButton>
                </div>

                <Collapse in={expandedThirdSection} timeout="auto" unmountOnExit>
                    <FormGroup className='mtp-form-width'>
                        <Grid item xs={3}>
                            <label for="enterVersion" className="productSelectFormLabel">{ApplicationConstants.Labels.Alias}</label>
                        </Grid>
                        <Grid item xs={10}>
                            <TextField
                                id="outlined-multiline-static"
                                required
                                label={ApplicationConstants.Labels.EnterAlias}
                                multiline
                                rows="1"
                                rowsMax="100"
                                margin="normal"
                                variant="outlined"
                                size="medium"
                                defaultValue={contactEmailAddress}
                                onChange={e => {
                                    var aliases = e.target.value.split('\n');
                                    var tempAlias = [];
                                    if (aliases && aliases.length > 0) {
                                        aliases.forEach(function (alias, index) {
                                            if (alias.length !== 0) {
                                                tempAlias.push(alias)
                                            }
                                        })
                                    }
                                    selectedTenant.contactEmailAddresses = tempAlias;
                                    }
                                }
                            />
                        </Grid>
                    </FormGroup>
                </Collapse>
            </FormControl>
        </Card>
        <div className='mtp-form-bottom-buttons'>
            <Button variant="contained" color="secondary" className='mtp-form-cancel' onClick={event => handleFormCancel()}>
                {ApplicationConstants.Buttons.Cancel}
            </Button>
            <Button variant="contained" className='mtp-form-save' color="primary" onClick={event => handleEditFormSave()} >
                {ApplicationConstants.Buttons.Save}
            </Button>
        </div>
    </FormGroup>)

    const AddTenantProfileForm = (<FormGroup id="mtp-form-flex-root">
        <Card className='mtp-form-card-margin' id='mtp-form-card'>
            <FormControl id="mtp-form-flex-control">
                <div>
                    <FormLabel className="mtp-form-label">{ApplicationConstants.Labels.CustomerProfile}</FormLabel>
                    <IconButton
                        className={clsx(classes.expand, {
                            [classes.expandOpen]: expandedFirstSection,
                        })}
                        onClick={handleExpandFirstSectionClick}
                        aria-expanded={expandedFirstSection}
                        aria-label="show more"
                    >

                        <ExpandMoreIcon />
                    </IconButton>
                </div>
                <Collapse in={expandedFirstSection} timeout="auto" unmountOnExit>
                    <Grid spacing={2} className='mtp-add-form'>
                        <FormGroup id="mtp-form-flex">
                            <FormGroup className='mtp-form-width-columns'>
                                <Grid item xs={6}>
                                    <label for="addTenantCodeField" className="productSelectFormLabel">{ApplicationConstants.Labels.OrganizationCode}</label>
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField id="addTenantCodeField" label={ApplicationConstants.Labels.EnterOrganizationCode} className="" variant="outlined"
                                        onChange={event => { newTenant.tenantCode = event.target.value; setTenantCode(event.target.value); }} />
                                </Grid>
                            </FormGroup>
                            <FormGroup>
                                <Grid item xs={6}>
                                    <label className="productSelectFormLabel">{ApplicationConstants.Labels.TenantRole}</label>
                                </Grid>
                                <Grid item xs={8}>
                                    <FormControl className={classes.formControl}>
                                        <Select
                                            defaultValue={tenantRole}
                                            onChange={event => handleTenantRoleChange(event)} 
                                            displayEmpty
                                        >
                                            <MenuItem value={ApplicationConstants.Labels.JIT}>{ApplicationConstants.Labels.JIT}</MenuItem>
                                            <MenuItem value={ApplicationConstants.Labels.RSM}>{ApplicationConstants.Labels.RSM}</MenuItem>
                                            <MenuItem value={ApplicationConstants.Labels.SLK}>{ApplicationConstants.Labels.SLK}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </FormGroup>
                        </FormGroup>
                        <FormGroup className='mtp-form-width-columns'>
                            <Grid item xs={6}>
                                <label for="addtenantNameField" className="productSelectFormLabel">{ApplicationConstants.Labels.TenantOrganization}</label>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField id="addTenantNameField" label={ApplicationConstants.Labels.EnterTenantOrganization} className="" variant="outlined"
                                    onChange={event => { newTenant.tenantName = event.target.value; setTenantName(event.target.value); }} />
                            </Grid>
                        </FormGroup>
                        <FormGroup className='mtp-form-width-columns'>
                            <Grid item xs={6}>
                                <label for="tenantDailyKeyLimit" className="productSelectFormLabel">{ApplicationConstants.Labels.DailyKeyLimit}</label>
                                {newTenant.typeOfTenant == ApplicationConstants.Labels.RSM ? 
                                    <Tooltip title={ApplicationConstants.InfoIconsText.RSMDailyKeyLimit}>
                                        <IconButton aria-label={ApplicationConstants.InfoIconsText.RSMDailyKeyLimit}>
                                            <InfoIcon style={{ fontSize: 18 }} />
                                        </IconButton>
                                    </Tooltip> :
                                    null
                                }
                            </Grid>

                            <Grid item xs={8}>
                                <TextField id="tenantDailyKeyLimit" label={ApplicationConstants.Labels.EnterDailyKeyLimit} className="" variant="outlined"
                                    onChange={event => { newTenant.maximumQuantityPerDay = event.target.value; setDailyKeyLimit(event.target.value);  }}
                                    value={DailyKeyLimit} disabled={newTenant.typeOfTenant == ApplicationConstants.Labels.RSM} />
                            </Grid>
                        </FormGroup>
                        <FormGroup className='mtp-form-width-columns'>
                            <Grid item xs={6}>
                                <label for="isVLK" className="productSelectFormLabel">{ApplicationConstants.Labels.IsVLK}</label>
                            </Grid>
                            <Grid item xs={8}>
                                <RadioGroup defaultValue={ApplicationConstants.Labels.True.toString()} aria-label="IsVLK" name="customized-radios" onChange={e => { newTenant.hasVolumeLicenseRestriction = e.target.value }}>
                                    <FormControlLabel value="true" id="mtp-radioButtons" control={<StyledRadio />} label={ApplicationConstants.Labels.True} />
                                    <FormControlLabel value="false" id="mtp-radioButtons" control={<StyledRadio />} label={ApplicationConstants.Labels.False} />
                                </RadioGroup>
                            </Grid>
                        </FormGroup>
                        <FormGroup className='mtp-form-width-columns'>
                            <Grid item xs={6}>
                                <label for="AddTenantResourceIdField" className="productSelectFormLabel">{ApplicationConstants.Labels.TenantResourceId}</label>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField id="AddTenantResourceIdField" label={ApplicationConstants.Labels.EnterTenantResourceId} className="" variant="outlined"
                                    onChange={e => {
                                        newTenant.callbackResourceId = e.target.value; setTenantResourceId(e.target.value);
                                        }}
                                    value={TenantResourceId} disabled={newTenant.typeOfTenant == ApplicationConstants.Labels.RSM}/>
                            </Grid>
                        </FormGroup>
                        <FormGroup className='mtp-form-width-columns'>
                            <Grid item xs={6}>
                                <label for="certUploadForAddTenant" className="productSelectFormLabel">{ApplicationConstants.Labels.EncryptionCertificate}</label>
                            </Grid>
                            <Grid item xs={8}>
                                {tenantRole?.localeCompare(ApplicationConstants.Labels.JIT) === 0 || tenantRole?.localeCompare(ApplicationConstants.Labels.SLK) === 0 ?
                                    <React.Fragment>
                                        <input
                                            accept=".cer"
                                            className={classes.input}
                                            id="certUploadForAddTenant"                                            
                                            onChange={event => handleAddUploadCert(event)}
                                            type="file"
                                        />
                                        <Button color="primary" style={{ fontSize: "1.01em" }} onClick={event => { document.getElementById('certUploadForAddTenant').click(); }}>
                                        {ApplicationConstants.Buttons.Upload}
                                        </Button>
                                    </React.Fragment> :
                                    <Button color="primary" style={{ fontSize: "1.01em" }} disabled>
                                        {ApplicationConstants.Buttons.Create}
                                    </Button>
                                }
                            </Grid>
                        </FormGroup>
                    </Grid>
                </Collapse>
            </FormControl>
        </Card>
        <Card className='mtp-form-card-margin' id='mtp-form-card'>
            <FormControl id="mtp-form-flex-control">
                <div>
                    <FormLabel className="mtp-form-label">{ApplicationConstants.Labels.EmailContactInformation}</FormLabel>
                    <IconButton
                        className={clsx(classes.expand, {
                            [classes.expandOpen]: expandedSecondSection,
                        })}
                        onClick={handleExpandSecondSectionClick}
                        aria-expanded={expandedSecondSection}
                        aria-label="show more"
                    >

                        <ExpandMoreIcon />
                    </IconButton>
                </div>
                <Collapse in={expandedSecondSection} timeout="auto" unmountOnExit>

                    <FormGroup className='mtp-form-width'>
                        <Grid item xs={3}>
                            <label for="enterVersion" className="productSelectFormLabel">{ApplicationConstants.Labels.Alias}</label>
                            <Tooltip title={ApplicationConstants.InfoIconsText.EmailAddress}>
                                <IconButton aria-label={ApplicationConstants.InfoIconsText.EmailAddress}>
                                    <InfoIcon style={{ fontSize: 18 }} />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={10}>
                            <TextField
                                id="outlined-multiline-static"                                
                                label={ApplicationConstants.Labels.EnterAlias}
                                multiline
                                rows="1"
                                rowsMax="100"
                                margin="normal"
                                variant="outlined"
                                size="medium"
                                onChange={e => {
                                    var aliases = e.target.value.split('\n');
                                    var tempAlias = [];
                                    if (aliases && aliases.length > 0) {
                                        aliases.forEach(function (alias, index) {
                                            if (alias.length !== 0) {
                                                tempAlias.push(alias)
                                            }
                                        })
                                    }
                                    newTenant.contactEmailAddresses = tempAlias;
                                    setContactEmailAddress(tempAlias);

                                }
                                }
                            />
                        </Grid>
                    </FormGroup>
                </Collapse>
            </FormControl>
        </Card>
        <div className='mtp-form-bottom-buttons'>
            <Button variant="contained" color="secondary" className='mtp-form-cancel' onClick={event => handleAddFormCancel()}>
                {ApplicationConstants.Buttons.Cancel}
            </Button>
            <Button variant="contained" className='mtp-form-save' color="primary" onChangeCapture={e => { console.log(e); }} onClick={event => handleAddFormSave()}
                disabled={(newTenant == null) || (newTenant?.typeOfTenant == null) ||
                    ((newTenant?.typeOfTenant == ApplicationConstants.Labels.RSM) && contactEmailAddress.length <= 0) ||
                    (newTenant.maximumQuantityPerDay == 0) || (TenantResourceId.trim().length <= 0) ||
                    (tenantCode.trim().length <= 0) || (tenantName.trim().length <=0)}>
                {ApplicationConstants.Buttons.Save}
            </Button>
        </div>
    </FormGroup>)

    const TenantProfileTable = (<Card className='CardOverflow'>
        <TableContainer className={classes.container} >
            <Table id="pkpnTable" aria-label="sticky table" className='MTPTable'>
                <TableHead>
                    <TableRow>
                        {ApplicationConstants.TableColumns.ManageTenantProfile.TenantProfileTableColumn.map(column => (
                            <TableCell id="TenatProfileTableHeader" className="headerBorder"
                                key={column.id}
                                style={{ minWidth: column.minWidth }}
                                sortDirection={orderBy === column.id ? order : false}>

                                <TableSortLabel
                                    active={orderBy === column.id}
                                    direction={orderBy === column.id ? order : 'asc'}
                                    onClick={createSortHandler(column.id, 0)}
                                >

                                    {column.label}
                                    {orderBy === column.id ? (
                                        <span className={classes.visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                    ) : null}
                                </TableSortLabel>

                            </TableCell>

                        ))}

                        <TableCell padding="checkbox" id="TenatProfileTableHeader" size="small" className="headerBorder">
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tenantProfiles ?
                        (rowsPerPage > 0
                            ? stableSort(tenantProfiles, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : tenantProfiles
                        ).map(row => {
                            const isItemSelected = isSelected(row);

                            return (
                                <React.Fragment>
                                    <TableRow hover role="checkbox" tabIndex={-1} selected={isItemSelected} className='MTPTableBody'>
                                        {ApplicationConstants.TableColumns.ManageTenantProfile.TenantProfileTableColumn.map(column => {
                                            if ((column.id == "hasVolumeLicenseRestriction") && row[column.id] != null) {
                                                row[column.id] = row[column.id].toString();
                                            }
                                            const value = row[column.id]
                                            return (
                                                <TableCell id="MTPTableBody" key={column.id}>
                                                    {column.format && typeof value === 'number' ? column.format(value) : value}
                                                </TableCell>
                                            );
                                        })}

                                        <TableCell id="MTPTableBody" onClick={event => handleMoreIconClick(event, row)}>
                                            <MoreHoriz />
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            );
                        })
                        : null
                    }
                </TableBody>
            </Table>
        </TableContainer>
        <TablePagination
            rowsPerPageOptions={[10, 20, 30, { label: 'All', value: tenantProfiles.length }]}
            colSpan={5}
            count={tenantProfiles.length}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
        />
    </Card>)

    const TopNavigation = (<div className='hfd-top-bar'>
        <div className='hfd-top-navigation'>
            <div className='hfd-top-link' className='hfd-location-tab'>
                {ApplicationConstants.Labels.Location}
            </div>
            <div className='hfd-top-link' onClick={event => handleLocationNavigation()}>
                {ApplicationConstants.Labels.TenantProfiles}
            </div>&nbsp;
            {editTenantProfle ?
                < div className='hfd-top-link' >
                    {'/ ' + ApplicationConstants.Labels.EditTenant}
                </div> : addTenant ?
                    <div className='hfd-top-link'>
                        {'/ ' + ApplicationConstants.Labels.AddTenant}
                    </div> :
                    null}&nbsp;
        </div>
    </div>);

    return (
        <div className='RowFlex'>
            {TopNavigation}
            {editTenantProfle ? EditTenantProfileForm : null}
            {addTenant ? AddTenantProfileForm : null}
            {addTenant === false && editTenantProfle === false ?
                <div className='mtp-tenant-section'>
                    {TenantProfileTable}
                    <Button variant="contained" color="primary" aria-label="add" onClick={event => handleAddTenant()} style={{
                        width: "15%",
                        marginTop: "2rem",
                        textTransform: "none",
                        fontFamily: '"Segoe UI VSS (Regular)","Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
                        fontWeight: 370}}>
                        <AddIcon className={classes.extendedIcon} />
                        {ApplicationConstants.Buttons.AddTenant}
                    </Button>
                </div>
                : null}

            <Backdrop className={classes.backdrop} open={openLoading} style={{ marginLeft: props.drawerWidth }} >
                <CircularProgress />
            </Backdrop>
            {BottomSnackBar}
            {AlertSnackBar(alertSelector)}
            {ShowDialog({ tenantCode: selectedTenant.tenantCode })}
            <Menu
                id="mtp-menu"
                anchorEl={anchorEl}
                keepMounted
                open={menuOpen}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={event => handleEditRowClick()}>Edit</MenuItem>
                <MenuItem onClick={event => handleDeleteRowClick()}>Delete</MenuItem>
            </Menu>
        </div>
    );
}

View.propTypes = {
    error: PropTypes.bool,
    errorDescription: PropTypes.string,
    loading: PropTypes.bool,
    tenantProfiles: PropTypes.array,
    responseCount: PropTypes.number,
    idToken: PropTypes.string,
    accessToken: PropTypes.string,
    drawerWidth: PropTypes.string
}

export class ManageTenantProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorCode: 0,
            errorDescription: '',
            loading: true,
            tenantProfiles: [],
            userPrincipalName: this.props.graphProfile.userPrincipalName,
            idToken: this.props.idToken,
            accessToken: this.props.accessToken,
            drawerWidth: this.props.drawerWidth
        }
    }

    componentDidMount() {
        const tenantProfileRequest = { TenantCode : ""};
        this.FetchTenantProfiles(tenantProfileRequest);
    }

    async FetchTenantProfiles(tenantProfileRequest) {
        await ApiCall.CallApiGet(ApplicationConstants.EndPoints.GetTenantProfilesEndPoint, tenantProfileRequest, this.props.idToken, this.props.accessToken)
            .then((response) => {
                console.log('logging response in view history');
                console.log(response);
                this.setState({
                    errorCode: response.errorCode,
                    errorDescription: response.errorDescription,
                    loading: false,
                    tenantProfiles: response.tenants ? response.tenants : null
                })

            })
            .catch(error => {
                console.log(error);
                this.setState({
                    errorCode: error.status,
                    errorDescription: error.statusText,
                    loading: false,
                    tenantProfiles: null,
                })
            });
    }

    render() {

        return (
            <React.Fragment>
                <View {...this.state} />
            </React.Fragment>
        )
    }
}