import React, { Component, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Card, CardContent, Typography, Grid, Backdrop, CircularProgress, Snackbar, IconButton } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import PrivacyIcon from '../../icons/Privacy.png';
import CloseIcon from '@material-ui/icons/Close';
import './PrivacyPolicy.css';
import PropTypes from 'prop-types';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
    title: {
        fontFamily: '"Segoe UI VSS (Regular)","Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
        color: 'black',
        fontWeight: 450,
        fontSize: '18px',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

function View(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [openLoading, setOpenLoading] = React.useState(props.loading);
    const [openSnackBar, setOpenSnackBar] = React.useState(false);
    const [snackBarMessage, setSnackBarMessage] = React.useState('');
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertSelector, setAlertSelector] = React.useState(0);
    const [openAlert, setOpenAlert] = React.useState(false);

    useEffect(() => {
    });

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            setOpenAlert(false);
            return;
        }
        setOpenAlert(false);
    };

    const handleSnackBarClose = () => {
        setOpenSnackBar(false);
    };

    //selector = 0 for error
    //selector = 1 for success
    //selector = 2 for info
    const AlertSnackBar = (selector) => (
        <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleAlertClose}>
            {selector === 0 ?
                <Alert onClose={handleAlertClose} severity="error">
                    {alertMessage}
                </Alert>
                : selector === 1 ?
                    <Alert onClose={handleAlertClose} severity="success">
                        {alertMessage}
                    </Alert> :
                    <Alert onClose={handleAlertClose} severity="info">
                        {alertMessage}
                    </Alert>
            }
        </Snackbar>
    );

    const BottomSnackBar = (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            style={{ 'fontSize': '12px' }}
            open={openSnackBar}
            autoHideDuration={6000}
            message={snackBarMessage}
            action={
                <React.Fragment>
                    <IconButton size="large" aria-label="close" color="inherit" onClick={handleSnackBarClose}>
                        <CloseIcon fontSize="large" />
                    </IconButton>
                </React.Fragment>
            }
        />
    );

    return (
        <div className='RowFlex'>
            <Card className='CardOverflow'>
                <CardContent>
                    <Typography className={classes.title} gutterBottom>
                        <strong>PRIVACY AT MICROSOFT</strong>
                    </Typography>
                    <div style={{ margin: '20px 0' }}></div>
                    <Grid container direction="column" spacing={3}>
                        <Grid item>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography style={{ marginRight: '8px', fontSize: '1em', lineHeight: '1.5em' }}>
                                    Detailed information about privacy and GDPR policies at Microsoft can be found at
                                </Typography>
                                <a
                                    href="https://go.microsoft.com/fwlink/?LinkId=518021"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ textDecoration: 'none', color: 'blue', fontSize: '1em', lineHeight: '1.5em' }}
                                >
                                    Microsoft Data Protection Notice
                                </a>
                            </div>
                        </Grid>
                        {/* Add more content here as needed */}
                    </Grid>
                </CardContent>
            </Card>
            <Backdrop className={classes.backdrop} open={openLoading} style={{ marginLeft: props.drawerWidth }}>
                <CircularProgress />
            </Backdrop>
            {BottomSnackBar}
            {AlertSnackBar(alertSelector)}
        </div>
    );
}

View.propTypes = {
    loading: PropTypes.bool,
    drawerWidth: PropTypes.string,
};

export class PrivacyPolicy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            drawerWidth: this.props.drawerWidth,
        };
    }

    componentDidMount() {
    }

    render() {
        return (
            <React.Fragment>
                <View {...this.state} />
            </React.Fragment>
        );
    }
}
