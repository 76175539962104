import React, { Component, useEffect, useReducer  } from 'react';
import { makeStyles, useTheme, lighten } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Button, TextField, ButtonGroup, TableFooter, TablePagination, ClickAwayListener, FormGroup, Collapse, Tooltip, TableSortLabel } from '@material-ui/core';
import './ViewRequestHistory.css';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { ApiCall } from '../../Utilities/ApiCall';
import { Backdrop, CircularProgress, Grid, MenuList, MenuItem, Paper, Grow, Popper, Toolbar, Typography } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import { ExportToExcel } from '../../Utilities/ExportToExcel';
import GetAppIcon from '@material-ui/icons/GetApp';
import MuiAlert from '@material-ui/lab/Alert';
import ApplicationConstants from '../../Utilities/ApplicationConstants';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
    title: {
        fontFamily: '"Segoe UI VSS (Regular)","Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
        color: 'black',
        fontWeight: 450,
        fontSize: '18px'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    container: {
        maxHeight: '34em',
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
        fontFamily:'"Segoe UI VSS (Regular)","Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    },
}));

const useStyles1 = makeStyles(theme => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {

    array.forEach(function (object, index) {
        object.createdDate = new Date(object.createdDate).getTime();
    })
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = event => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = event => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = event => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = event => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root} >
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

const splitButtonOptions = ['Request ID', 'Query'];

var excelHeaderPrecedence = ['keyValidationRequestId', 'status'];

var heading =[ ["Key Validation Request ID", "Status", "Line Item Id", 'Line Item Status', 'Valid Key Findings', 'Product Key(Last 5 digits)', 'Group Id', 'Serial Number',
    'Upgrade Flag', 'PKID', 'Part Number', 'Part Number Description', 'Product Family', 'Algorithm', 'PKID Slk Distributed', 'Created Date']
];

function View(props) {

    const classes = useStyles();
    const theme = useTheme();
    const [index, setIndex] = React.useState(0);
    const anchorRef = React.useRef(null);
    const [requestId, setRequestId] = React.useState('');
    const [openLoading, setOpenLoading] = React.useState(props.loading);
    const [openSnackBar, setOpenSnackBar] = React.useState(false);
    const [snackBarMessage, setSnackBarMessage] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [selectedRow, setSelectedRow] = React.useState([]);
    const [clickedRow, setClickedRow] = React.useState([]);
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('createdDate');
    const [pageRequestTable, setPageRequestTable] = React.useState(0);
    const [rowsPerPageRequestTable, setRowsPerPageRequestTable] = React.useState(5);
    const [selectedRowRequestTable, setSelectedRowRequestTable] = React.useState([]);
    const [orderRequestTable, setOrderRequestTable] = React.useState('asc');
    const [orderByRequestTable, setOrderByRequestTable] = React.useState('lineItemId');
    const [openSplitButton, setOpenSplitButton] = React.useState(false);
    const [selectedSplitButtonIndex, setSelectedSplitButtonIndex] = React.useState(0);
    const [selectedStartDate, setSelectedStartDate] = React.useState(new Date('01-01-2000'));
    const [selectedEndDate, setSelectedEndDate] = React.useState(new Date());
    const [queryResultCount, setQueryResultCount] = React.useState(-1);
    const [queryResult, setQueryResult] = React.useState([]);
    const [validationError, setValidationError] = React.useState(false);
    const [validationErrorMessage, setValidationErrorMessage] = React.useState('');
    const [queryErrorCode, setQueryErrorCode] = React.useState(-1);
    const [queryErrorDescription, setQueryErrorDescription] = React.useState('');
    const [selectAll, setSelectAll] = React.useState(false);
    const [openInnerTable, setOpenInnerTable] = React.useState(false);
    const [queryDateButtonClicked, setQueryDateButtonClicked] = React.useState(false);
    const [openAlert, setOpenAlert] = React.useState(false);
    const [errorWarningMessage, setErrorWarningMessage] = React.useState('');
    const [errorWaringSelector, setErrorWarningSelector] = React.useState(0);
    const [mainTableIndex, setMainTableIndex] = React.useState(-1);
    const [rowsPerPageInnerTable, setRowsPerPageInnerTable] = React.useState(5);
    const [pageInnerTable, setPageInnerTable] = React.useState(0);
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

    useEffect(() => {
        if (queryResultCount === -1) {
            setQueryResultCount(props.totalRecord)
            console.log(props.totalRecord);
            setOpenLoading(props.loading)
        }
        if ((isNaN(selectedStartDate.getTime()) || isNaN(selectedEndDate.getTime())) && selectedSplitButtonIndex === 1) {
            setValidationError(true);
        } else if (selectedSplitButtonIndex === 1) {
            setValidationError(false);
        }
        if ((queryResult === null || queryResult.length === 0) && queryResultCount === -1) {
            setQueryResult(props.keyValidationRequests)
        }
        if (queryErrorCode !== -1) {
            setQueryErrorCode(props.errorCode);
            setQueryErrorDescription(props.errorDescription);
        }
        if (queryErrorCode > 0) {
            setErrorWarningSelector(0);
            setErrorWarningMessage(ApplicationConstants.AlertMessages.ErrorCode + queryErrorCode + ApplicationConstants.AlertMessages.ErrorMessage + queryErrorDescription);
            setOpenAlert(true);
        }
    });

    const handleErrorWarningAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            setOpenAlert(false);
            return;
        }

        setOpenAlert(false);
    };

    const handleStartDateChange = (date) => {
        setSelectedStartDate(date);
        //setQueryResult(handleDateQuery(date, selectedEndDate));
    };

    const handleEndDateChange = (date) => {
        setSelectedEndDate(date);
        //setQueryResult(handleDateQuery(selectedStartDate, date));
    };

    const handleMenuItemClick = (event, index) => {
        setSelectedSplitButtonIndex(index);
        setOpenSplitButton(false);
        setSelectAll(false);
    };

    const handleToggle = () => {
        setOpenSplitButton((prevOpen) => !prevOpen);
    };

    const handleSplitButtonClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
    }
    const handleRequestSort = (event, property, tableSelector) => {
        if (tableSelector === 0) {
            const isAsc = orderBy === property && order === 'asc';
            setOrder(isAsc ? 'desc' : 'asc');
            setOrderBy(property);
        } else {
            const isAsc = orderByRequestTable === property && order === 'asc';
            setOrderRequestTable(isAsc ? 'desc' : 'asc');
            setOrderByRequestTable(property);
        }
    };


    const handleRowClick = (event, row, toggleInnerTable) => {
        if (!toggleInnerTable) {
            const selectedIndex = selectedRow.indexOf(row);
            let newSelected = [];

            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selectedRow, row);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selectedRow.slice(1));
            } else if (selectedIndex === selectedRow.length - 1) {
                newSelected = newSelected.concat(selectedRow.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selectedRow.slice(0, selectedIndex),
                    selectedRow.slice(selectedIndex + 1),
                );
            }

            setSelectedRow(newSelected);
        } else {
            const selectedIndex = clickedRow.indexOf(row);
            let newSelected = [];

            if (selectedIndex === -1) {
                newSelected = newSelected.concat(clickedRow, row);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(clickedRow.slice(1));
            } else if (selectedIndex === clickedRow.length - 1) {
                newSelected = newSelected.concat(clickedRow.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    clickedRow.slice(0, selectedIndex),
                    clickedRow.slice(selectedIndex + 1),
                );
            }

            setClickedRow(newSelected);
            setOpenInnerTable(!openInnerTable);
        }
    };
    const createSortHandler = (property, tableSelector) => (event) => {
        handleRequestSort(event, property, tableSelector);
    };

    const handleChangePage = (tableSelector, requestTableParams, index ) => async (event, newPage) => {
        if (tableSelector === 0) {
            setPage(newPage);
            if (queryDateButtonClicked && selectedSplitButtonIndex === 1) {
                var payload = { Limit: rowsPerPage, PageNumber: newPage + 1, StartDate: selectedStartDate.toJSON(), EndDate: selectedEndDate.toJSON() };
                await FetchKeyValidationRequests(payload);
            } else {
                var payload = { Limit: rowsPerPage, PageNumber: newPage + 1 };
                await FetchKeyValidationRequests(payload);
            }
        } else {
            requestTableParams.pageRequestTableObject = newPage;
            console.log(requestTableParams.pageRequestTableObject)
            forceUpdate();
            if (index !== -1) {
                console.log(index);
                setMainTableIndex(index)
                setPageInnerTable(requestTableParams.pageRequestTableObject);
                if (index !== mainTableIndex) {
                    setRowsPerPageInnerTable(5);
                }
            }
        }
    };

    const handleChangeRowsPerPage = (tableSelector, requestTableParams, index ) => async event => {
        if (tableSelector === 0) {
            console.log(event.target.value);
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
            if (queryDateButtonClicked && selectedSplitButtonIndex === 1) {
                var payload = { Limit: event.target.value, PageNumber: 1, StartDate: selectedStartDate.toJSON(), EndDate: selectedEndDate.toJSON() };
                await FetchKeyValidationRequests(payload);
            }
            else {
                var payload = { Limit: event.target.value, PageNumber: 1 };
                await FetchKeyValidationRequests(payload);
            }
        } else {
            requestTableParams.rowsPerPageRequestTableObject = parseInt(event.target.value, 10);
            requestTableParams.pageRequestTableObject = 0;
            if (index !== -1) {
                setMainTableIndex(index)
                setPageInnerTable(requestTableParams.pageRequestTableObject);
                setRowsPerPageInnerTable(requestTableParams.rowsPerPageRequestTableObject)
            }
        }
    }

    const handleSnackBarClose = () => {
        setOpenSnackBar(false);
    }

    const handleExportClick = async () => {
        var exportToExcelData = [];
        setOpenLoading(true);
        setOpenSnackBar(true);
        setSnackBarMessage(ApplicationConstants.AlertMessages.ExportingExcel);
        if (selectAll && queryResultCount !== 1) {
            var response;
            if (queryDateButtonClicked && selectedSplitButtonIndex === 1) {
                payload = { StartDate: selectedStartDate.toJSON(), EndDate: selectedEndDate.toJSON() };
                response = await FetchAllKeyValidationRequests(payload);
            }
            else {
                var payload = {};
                response = await FetchAllKeyValidationRequests(payload);
                console.log(response);
            }
            if (response.errorCode === 0) {
                response.keyValidationRequests.forEach(function (row, index) {
                    var temp = {};
                    row.productKeyList.forEach(function (productKeyInfo, pkIndex) {
                        temp.lineItemId = productKeyInfo.lineItemId
                        temp.lineItemStatus = productKeyInfo.lineItemStatus
                        temp.validKeyFindings = productKeyInfo.keyFindings
                        temp.productKeyLastFiveDigits = productKeyInfo.productKeyLastFiveDigits
                        temp.groupId = productKeyInfo.groupId
                        temp.serialNumber = productKeyInfo.serialNumber
                        temp.upgradeFlag = productKeyInfo.upgradeFlag
                        temp.pkid = productKeyInfo.pkid
                        temp.partNumber = productKeyInfo.partNumber
                        temp.partNumberDescription = productKeyInfo.partNumberDescription
                        temp.partNumberProductFamily = productKeyInfo.partNumberProductFamily
                        temp.algorithmId = productKeyInfo.algorithmId
                        temp.isPKIDSlkDistributed = productKeyInfo.isPKIDSlkDistributed
                        temp.keyValidationRequestId = row.keyValidationRequestId;
                        temp.createdDate = row.createdDate;
                        temp.status = row.keyValidationRequestStatus;

                        exportToExcelData.push(temp);
                    })
                })
            }
        }
        else {
            selectedRow.forEach(function (row, index) {
                var temp = {};
                row.productKeyList.forEach(function (productKeyInfo, pkIndex) {
                    temp = {};
                    temp.lineItemId = productKeyInfo.lineItemId
                    temp.lineItemStatus = productKeyInfo.lineItemStatus
                    temp.validKeyFindings = productKeyInfo.keyFindings
                    temp.productKeyLastFiveDigits = productKeyInfo.productKeyLastFiveDigits
                    temp.groupId = productKeyInfo.groupId
                    temp.serialNumber = productKeyInfo.serialNumber
                    temp.upgradeFlag = productKeyInfo.upgradeFlag
                    temp.pkid = productKeyInfo.pkid
                    temp.partNumber = productKeyInfo.partNumber
                    temp.partNumberDescription = productKeyInfo.partNumberDescription
                    temp.partNumberProductFamily = productKeyInfo.partNumberProductFamily
                    temp.algorithmId = productKeyInfo.algorithmId
                    temp.isPKIDSlkDistributed = productKeyInfo.isPKIDSlkDistributed
                    temp.keyValidationRequestId = row.keyValidationRequestId;
                    temp.createdDate = row.createdDate;
                    temp.status = row.keyValidationRequestStatus;

                    exportToExcelData.push(temp);
                })
            })
        }
        ExportToExcel(exportToExcelData, heading, excelHeaderPrecedence, 'KeyValidationList_' + new Date().toISOString());
        setSelectAll(false);
        setOpenLoading(false);
        setOpenSnackBar(false);
    }

    const handleRequestId = event => {
        setRequestId(event.target.value);
        setValidationError(false);
    }

    const handleDateQuery = (startDate, endDate) => {
        var tempResult = [];
        props.keyValidationRequests.forEach(function (keyValidationRequest, index) {

            if (keyValidationRequest.createdDate != null) {
                keyValidationRequest.createdDate = new Date(keyValidationRequest.createdDate).toLocaleString();
            }
            if ((new Date(keyValidationRequest.createdDate) >= new Date(startDate))
                && (new Date(keyValidationRequest.createdDate) <= new Date(endDate))) {
                tempResult.push(keyValidationRequest);
            }
        });
        setQueryResultCount(tempResult ? tempResult.length : 0);
        return tempResult;
    }

    const handleSplitButtonSearchClick = async () => {
        setOpenLoading(true);
        setOpenSnackBar(true);
        setSnackBarMessage(ApplicationConstants.AlertMessages.ExecutingQuery);
        var regexGuid = /^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/gi;
        if (selectedSplitButtonIndex === 0) {
            if (regexGuid.test(requestId)) {
                setValidationError(false);
                setValidationErrorMessage('');
                setPage(0);
                var payload = { Limit: rowsPerPage, PageNumber: page + 1, KeyValidationRequestId: requestId };
                await FetchKeyValidationRequests(payload);
            } else {
                setValidationError(true);
                setValidationErrorMessage(ApplicationConstants.ValidationErrors.InvalidGuid);
            }
        } else {
            if (isNaN(selectedStartDate.getTime()) || isNaN(selectedEndDate.getTime())) {
                setValidationError(true);
                setValidationErrorMessage(ApplicationConstants.ValidationErrors.InvalidDate);
            } else {
                setValidationError(false);
                setValidationErrorMessage('');
                setPage(0);
                setQueryDateButtonClicked(true);
                console.log(selectedStartDate);
                console.log(selectedEndDate);
                var payload = { Limit: rowsPerPage, PageNumber: page + 1, StartDate: selectedStartDate.toJSON(), EndDate: selectedEndDate.toJSON() };
                await FetchKeyValidationRequests(payload);
            }
        }
        setOpenLoading(false);
        setOpenSnackBar(false);
    }

    const handleCheckBoxClick = () => {
        if (selectAll) {
            setOpenAlert(true);
            setErrorWarningMessage(ApplicationConstants.AlertMessages.DeselectSelectAll);
            setErrorWarningSelector(1);
        }
    }

    const isSelected = (row) => selectedRow.indexOf(row) !== -1;
    const isClicked = (row) => clickedRow.indexOf(row) !== -1;

    const FetchKeyValidationRequests = async (getKeyValidationRequest) => {
        setSelectAll(false);
        setSelectedRow([]);
        console.log(queryResult)
        setOpenLoading(true);
        await ApiCall.CallApiGet(ApplicationConstants.EndPoints.GetKeyValidationRequests, getKeyValidationRequest, props.idToken, props.accessToken)
            .then((response) => {
                console.log('logging response in view history');
                console.log(response);
                if (response.totalRecord > 0) {
                    setQueryResult(response.keyValidationRequests);
                } else {
                    setQueryResult([]);
                }
                setQueryResultCount(response.totalRecord);
                setQueryErrorCode(response.errorCode);
                setQueryErrorDescription(response.errorDescription);
            })
            .catch(error => {
                setQueryErrorCode(error.errorCode);
                setQueryErrorDescription(error.errorDescription)
                setQueryResult(null);
                setQueryResultCount(null);
            });
        setOpenLoading(false);
    }

    const FetchAllKeyValidationRequests = async (getKeyValidationRequest) => {
        return await ApiCall.CallApiGet(ApplicationConstants.EndPoints.GetKeyValidationRequests, getKeyValidationRequest, props.idToken)
            .then((response) => {
                console.log('logging response in view history');
                return response;
            })
            .catch(error => {
                return error;
            });
    }

    const EnhancedTableToolbar = (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: selectedRow.length > 0 || selectAll,
            })}
        >
            {selectAll ? (
                <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                    {queryResultCount} selected
                </Typography>
            ) : selectedRow.length > 0 ? (
                <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                    {selectedRow.length} selected
                </Typography>
            )
                    : null
            }
        </Toolbar>
    )

    const BottomSnackBar = (<Snackbar
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        style={{ 'fontSize': '12px' }}
        open={openSnackBar}
        autoHideDuration={6000}
        message={snackBarMessage}
        action={
            <React.Fragment>
                <IconButton size="large" aria-label="close" color="inherit" onClick={handleSnackBarClose}>
                    <CloseIcon fontSize="large" />
                </IconButton>
            </React.Fragment>
        }
    />
    )

    const ErrorWarningSnackBar = (selector) => (
        <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleErrorWarningAlertClose}>
            {selector === 0 ?
                <Alert onClose={handleErrorWarningAlertClose} severity="error">
                    {errorWarningMessage}
                </Alert>
                :
                <Alert onClose={handleErrorWarningAlertClose} severity="warning">
                    {errorWarningMessage}
                </Alert>
            }
        </Snackbar>
    )

    const RequestIdQuerySplitButton = (<div className='SplitButton'>
        <ButtonGroup variant="contained" color="primary" ref={anchorRef} aria-label="split button">
            <Button startIcon={<SearchIcon />} onClick={event => handleSplitButtonSearchClick()} disabled={validationError}>{splitButtonOptions[selectedSplitButtonIndex]}</Button>
            <Button
                color="primary"
                size="small"
                aria-controls={openSplitButton ? 'split-button-menu' : undefined}
                aria-expanded={openSplitButton ? 'true' : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                onClick={handleToggle}
            >
                <ArrowDropDownIcon />
            </Button>
        </ButtonGroup>
        <Popper open={openSplitButton} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{
                        transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                >
                    <Paper style={{ background: '#323130' }}>
                        <ClickAwayListener onClickAway={handleSplitButtonClose}>
                            <MenuList id="split-button-menu">
                                {splitButtonOptions.map((option, index) => (
                                    <MenuItem
                                        key={option}
                                        selected={index === selectedSplitButtonIndex}
                                        onClick={(event) => handleMenuItemClick(event, index)}
                                    >
                                        {option}
                                    </MenuItem>
                                ))}
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    </div>)

    const RequestTable = (row, requestTableParams, index) => (
        <React.Fragment>
        <TableContainer className={classes.container} id="RequestTable">
            <Table fixedHeader={true}  id="pkpnTable" aria-label="sticky table">
                <TableHead>
                    <TableRow>
                        {ApplicationConstants.TableColumns.ViewRequestHistory.RequestTableColumn.map(column => (
                            <TableCell id="TableHeaderRequest" className="headerBorder"
                                key={column.id}
                                style={{ minWidth: column.minWidth }}
                                sortDirection={orderByRequestTable === column.id ? orderRequestTable : false}>
                                {column.label}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {row.productKeyList ?
                            (requestTableParams.rowsPerPageRequestTableObject > 0
                                ? stableSort(row.productKeyList, getComparator(orderRequestTable, orderByRequestTable)).slice(requestTableParams.pageRequestTableObject * requestTableParams.rowsPerPageRequestTableObject, requestTableParams.pageRequestTableObject * requestTableParams.rowsPerPageRequestTableObject + requestTableParams.rowsPerPageRequestTableObject)
                            : row.productKeyList
                            ).map(row => {
                                console.log(requestTableParams);
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} >
                                    {ApplicationConstants.TableColumns.ViewRequestHistory.RequestTableColumn.map(column => {
                                        const value = row[column.id]
                                        if (value !== null) {
                                            return (
                                                <TableCell id="PKPNTableBodyRequest" key={column.id}>
                                                    {column.format ? column.format(value) : value}
                                                </TableCell>
                                            );
                                        } else {
                                            return (
                                                <TableCell id="PKPNTableBodyRequest" key={column.id}>
                                                    -
                                                </TableCell>
                                            );
                                        }
                                    })}
                                </TableRow>
                            );
                        })
                        : null
                    }
                </TableBody>
            </Table>
        </TableContainer>
            {
                row.productKeyList && row.productKeyList.length > 5 ?
                    <TablePagination
                        id='requestTableFooter'
                        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                        colSpan={16}
                        count={row.productKeyList.length}
                        rowsPerPage={requestTableParams.rowsPerPageRequestTableObject}
                        page={requestTableParams.pageRequestTableObject}
                        SelectProps={{
                            inputProps: { 'aria-label': 'rows per page' },
                            native: true,
                        }}
                        onChangePage={handleChangePage(1, requestTableParams, index)}
                        onChangeRowsPerPage={handleChangeRowsPerPage(1, requestTableParams, index)}
                        ActionsComponent={TablePaginationActions}
                    />
                    : null
            }
        </React.Fragment>
    )

    const MainTable = (<Card className='CardOverflow'>
        {selectedRow.length > 0 || selectAll ? EnhancedTableToolbar : null}
        <TableContainer className={classes.container} style={selectedRow.length > 0 || selectAll ? { maxHeight: "30em" } : { maxHeight: "33em" } } >
            <Table id="pkpnTable" stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>
                        <TableCell className='checkboxCell' padding="checkbox" colspan='1'>
                            <Checkbox
                                onClick={event => setSelectAll(!selectAll)}
                                checked={selectAll}
                                inputProps={{ 'aria-labelledby': 'bleh' }}
                            />
                        </TableCell>
                        {ApplicationConstants.TableColumns.ViewRequestHistory.MainTableColumns.map(column => (
                            <TableCell id="TableHeaderMain" className="headerBorder"
                                key={column.id}
                                style={{ minWidth: column.minWidth }}
                                sortDirection={orderBy === column.id ? order : false}>
                                {column.id == "keyValidationRequestStatus" || column.id == "createdDate" ?
                                    < TableSortLabel
                                        active={orderBy === column.id}
                                        direction={orderBy === column.id ? order : 'asc'}
                                        onClick={createSortHandler(column.id, 0)}
                                    >

                                        {column.label}
                                        {orderBy === column.id ? (
                                            <span className={classes.visuallyHidden}>
                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </span>
                                        ) : null}
                                    </TableSortLabel> :
                                    column.label
                                }
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {queryResult !== null && queryResult.length > 0 ?
                        (queryResult
                        ).map(function(row, index, arr ) {
                            const isItemSelected = isSelected(row);
                            const isItemClicked = isClicked(row);
                            let requestTableParams = { rowsPerPageRequestTableObject: 5, pageRequestTableObject: 0 };
                            if (mainTableIndex === index) {
                                requestTableParams.rowsPerPageRequestTableObject = rowsPerPageInnerTable;
                                requestTableParams.pageRequestTableObject = pageInnerTable;
                            }
                            return (
                                <React.Fragment>
                                    <TableRow hover role="checkbox" tabIndex={-1}  selected={isItemSelected}>
                                        <TableCell className='checkboxCell' padding="checkbox" onClick={event => handleRowClick(event, row, false)}>
                                            <Checkbox
                                                checked={isItemSelected || selectAll}
                                                inputProps={{ 'aria-labelledby': 'bleh' }}
                                                onClick={event => handleCheckBoxClick()}
                                            />
                                        </TableCell>
                                        {ApplicationConstants.TableColumns.ViewRequestHistory.MainTableColumns.map(column => {
                                            if ((column.id == "createdDate") && row[column.id] != null) {
                                                row[column.id] = new Date(row[column.id]).toLocaleString();
                                            }
                                            const value = row[column.id]
                                            return (
                                                <TableCell id="PKPNTableBodyMain" key={column.id} onClick={event => handleRowClick(event, row, true)}>
                                                    {column.format && typeof value === 'number' ? column.format(value) : value}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                    {isItemClicked ?
                                        <TableRow className='selectedRowExpanded'>
                                            <TableCell colspan='1' className='checkboxCell' />
                                            <TableCell colSpan="3">
                                                <Collapse in={isItemClicked}>
                                                    {RequestTable(row, requestTableParams, index)}
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                        : null}
                                </React.Fragment>
                            );
                        })
                        : null
                    }
                </TableBody>
            </Table>
        </TableContainer>
        {queryResultCount !== -1 && queryResultCount > 1 ?
            <TablePagination
                rowsPerPageOptions={[10, 20, 30, { label: 'All', value: queryResultCount }]}
                colSpan={3}
                count={queryResultCount}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                }}
                onChangePage={handleChangePage(0)}
                onChangeRowsPerPage={handleChangeRowsPerPage(0)}
                ActionsComponent={TablePaginationActions}
            />
            : null
        }
    </Card>)

    const QueryDateSelector = (
        <div className='DatePicker'>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Start Date"
                    value={selectedStartDate}
                    onChange={handleStartDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    style={{ paddingRight: '2em' }}
                />
                <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="End Date"
                    value={selectedEndDate}
                    onChange={handleEndDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
            </MuiPickersUtilsProvider>
        </div>
    )

    return (
        <div className='RowFlex'>
            <div className='StartContent'>
                <div className='TopButtonRow'>
                    {selectedSplitButtonIndex === 0 ?
                        <TextField
                            id="outlined-size-small"
                            fullWidth
                            required
                            label="Enter Request Id"
                            margin="normal"
                            variant="outlined"
                            size="small"
                            onChange={event => handleRequestId(event)}
                            error={validationError}
                            helperText={validationError ? validationErrorMessage : null}
                        />
                        : QueryDateSelector
                    }

                    {RequestIdQuerySplitButton}
                </div>
                <div className='ColumnFlex'>
                    {MainTable}
                    <Button variant='contained' color='primary' onClick={event => handleExportClick()} startIcon={<GetAppIcon />} disabled={selectedRow.length === 0 && !selectAll}>
                        {ApplicationConstants.Buttons.ExportToExcel}
                    </Button>
                </div>
            </div>
            <Backdrop className={classes.backdrop} open={openLoading} style={{ marginLeft: props.drawerWidth }} >
                <CircularProgress />
            </Backdrop>
            {BottomSnackBar}
            {ErrorWarningSnackBar(errorWaringSelector)}
        </div>
    );
}

View.propTypes = {
    error: PropTypes.bool,
    errorDescription: PropTypes.string,
    loading: PropTypes.bool,
    keyValidationRequests: PropTypes.array,
    totalRecord: PropTypes.number,
    responseCount: PropTypes.number,
    idToken: PropTypes.string,
    accessToken: PropTypes.string,
    drawerWidth: PropTypes.string
}

export class ViewRequestHistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorCode: 0,
            errorDescription: '',
            loading: true,
            keyValidationRequests: [],
            totalRecord: -1,
            userPrincipalName: this.props.graphProfile.userPrincipalName,
            idToken: this.props.idToken,
            accessToken: this.props.accessToken,
            drawerWidth: this.props.drawerWidth
        }
    }

    componentDidMount() {
        const keyValidationQueryRequest = { Limit: 10, PageNumber: 1 };
        this.FetchKeyValidationRequests(keyValidationQueryRequest);
    }

    async FetchKeyValidationRequests(getKeyValidationRequest) {
        console.log(this.state.idToken)
        await ApiCall.CallApiGet(ApplicationConstants.EndPoints.GetKeyValidationRequests, getKeyValidationRequest, this.props.idToken, this.props.accessToken)
            .then((response) => {
                console.log('logging response in view history');
                console.log(response);
                this.setState({
                    errorCode: response.errorCode,
                    errorDescription: response.errorDescription,
                    loading: false,
                    keyValidationRequests: response.keyValidationRequests ? response.keyValidationRequests : null,
                    totalRecord: response ? response.totalRecord : null
                })

            })
            .catch(error => {
                console.log(error);
                this.setState({
                    errorCode: error.status,
                    errorDescription: error.statusText,
                    loading: false,
                    keyValidationRequests: null,
                    totalRecord: null
                })
            });
    }

    render() {

        return (
            <React.Fragment>
                <View {...this.state} />
            </React.Fragment>
        )
    }
}