import React, { Component } from 'react';
import './Header.css';
import { Grid, Popper, Card, CardContent, ClickAwayListener } from '@material-ui/core';

export default class Header extends Component {
    static displayName = Header.name;

    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            signOutOpen: false
        }
    }


    render() {
        return (
            <header>
                <Popper id='popper' style={{ width: '20em' }} open={this.state.signOutOpen} anchorEl={this.state.anchorEl} role={undefined} transition disablePortal placement='bottom-end' >
                    {({ TransitionProps, placement }) => (
                        <Card className='mectrl_currentAccount'>
                            <CardContent className='mectrl_accountInfo'>
                                <div id='mectrl_currentAccount_picture'>
                                    <img className='mectrl_profilepic' src={this.props.userPhoto} />
                                </div>
                                <div className='mectrl_accountDetails'>
                                    <div className='mectrl_name'>
                                        {this.props.graphProfile.displayName}
                                    </div>
                                    <div className=''>
                                        {this.props.graphProfile.userPrincipalName}
                                    </div>
                                    <div >
                                        <a className='mectrl_link mectrl_resetStyle' onClick={event => this.props.onSignOut()}>
                                            Sign Out
                                        </a>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    )}
                </Popper>
                <Grid container justify="center">
                    <div className="fxs-topbar fxs-topbar msportalfx-shadow-level4" role="presentation" id="_tsx_e_0">
                        <div className="fxs-topbar-content fxs-hide-in-customize" role="banner" aria-labelledby="bannerlabel" id="_tsx_e_26" >
                            <a className="fxs-topbar-preview fxs-topbar-home fxs-topbar-search-activated-hidden" title="Home" href="#home" id="bannerlabel">
                                <div className="fxs-topbar-internal fxs-internal-full">
                                    Secure License Key Portal
                                </div>
                            </a>
                            <div className="fxs-topbar-avatarmenu fxs-topbar-search-activated-hidden fxs-avatarmenu">
                                <div className="fxs-dropmenu" role="presentation">
                                    <ClickAwayListener onClickAway={event => this.setState({ signOutOpen: false, anchorEl: null })}>
                                        <button aria-controls="mectrl_main_body" className="fxs-dropmenu-button fxs-popup-button fxs-menu-account" aria-haspopup="true" aria-expanded="true" id="fxs-avatarmenu-button" aria-label="Account menu Currently signed in as Microsoft, under {1} tenant"
                                            title={"Name: " + this.props.graphProfile.displayName + "\nEmail: " + this.props.graphProfile.userPrincipalName} onClick={event => this.setState({ signOutOpen: !this.state.signOutOpen, anchorEl: event.currentTarget })}>
                                            <div className="fxs-avatarmenu-header fxs-avatarmenu-open" title={"Name: " + this.props.graphProfile.displayName + "\nEmail: " + this.props.graphProfile.userPrincipalName}>
                                                <div className="fxs-avatarmenu-tenant-container">
                                                    <div className="fxs-avatarmenu-username" id="_tsx_e_22">
                                                        {this.props.graphProfile ? this.props.graphProfile.userPrincipalName : null}
                                                    </div>
                                                </div>
                                                <div className="fxs-avatarmenu-tenant-image-container">
                                                    <img className='fxs-avatarmenu-tenant-image' src={this.props.userPhoto} />
                                                </div>
                                            </div>
                                        </button>
                                    </ClickAwayListener>
                                </div>
                            </div>
                        </div>
                    </div>
                </Grid>
            </header>
        );
    }
}