import React, { Component } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import { TextField, Button, FormGroup, Grid, Popper, Tooltip, IconButton } from '@material-ui/core';
import DownloadIcon from '../../icons/DownloadIcon.png';
import './SubmitRequest.css';
import InfoIcon from '@material-ui/icons/Info';
import { ApiCall } from '../../Utilities/ApiCall';
import { Backdrop, CircularProgress } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import MuiAlert from '@material-ui/lab/Alert';
import ApplicationConstants from '../../Utilities/ApplicationConstants';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const useStyles = makeStyles(theme => ({
    title: {
        fontFamily: '"Segoe UI VSS (Regular)","Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
        color: 'black',
        fontWeight: 450,
        fontSize: '18px'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

function CreateGuid() {
    function _p8(s) {
        var p = (Math.random().toString(16) + "000000000").substr(2, 8);
        return s ? "-" + p.substr(0, 4) + "-" + p.substr(4, 4) : p;
    }
    return _p8() + _p8(true) + _p8(true) + _p8();
}  

export default function SubmitReuest(props) {

    const classes = useStyles();
    const theme = useTheme();
    const [index, setIndex] = React.useState(0);
    const anchorRef = React.useRef(null);
    const [actionSelected, setActionSelected] = React.useState(0);
    const [subActionSelected, setSubActionSelected] = React.useState(0);
    const [productKeys, setProductKeys] = React.useState('');
    const [validationError, setValidationError] = React.useState(false);
    const [validationErrorMessage, setValidationErrorMessage] = React.useState('');
    const [openLoading, setOpenLoading] = React.useState(false);
    const [openSnackBar, setOpenSnackBar] = React.useState(false);
    const [snackBarMessage, setSnackBarMessage] = React.useState('');
    const [openAlert, setOpenAlert] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertSelector, setAlertSelector] = React.useState(0);

    const handleProductKey = (event) => {
        console.log(event.target.value)
        var keys = event.target.value.split('\n');
        var errorFlag = aplhaNumbericCheck(event.target.value);
        if (!errorFlag) {
            if (keys[0] === "") {
                document.getElementById('keyvalidation-submit-textbox').value = '';
                setProductKeys("");
            } else {
                setProductKeys(keys);
            }
        }
    }

    const handleErrorWarningAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            setOpenAlert(false);
            return;
        }

        setOpenAlert(false);
    };

    const aplhaNumbericCheck = (inputChar) => {
        var letters = /^[0-9a-zA-Z-\n]+$/;
        var errorFlag = false;
        if (inputChar.match(letters) || inputChar.length == 0) {
            setValidationError(false);
            setValidationErrorMessage('');
        }
        else {
            errorFlag = true;
            setValidationError(true);
            setValidationErrorMessage(ApplicationConstants.ValidationErrors.AplhanumericProductKeysAccepeted);
        }
        return errorFlag;
    }

    const handlePkpnSubmitClick = async () => {
        var errorIndex = [];
        var errorFlag = false;
        var payload = [];
        productKeys.forEach(function (productKey, index) {
            console.log(productKeys);
            if (index === productKeys.length - 1 && productKey.length === 0) {
                productKeys.pop();
            }
            else {
                var temp = {};
                temp.LineItemId = index + 1;
               /* if (productKey.length === 25) {
                    productKeys[index] = productKey = productKey.slice(0, 5) + '-' + productKey.slice(5, 10) + '-' + productKey.slice(10, 15) + '-' + productKey.slice(15, 20) + '-' + productKey.slice(20, 25);
                    temp.ProductKey = productKey;
                    payload.push(temp);
                }*/

                var validProductKeyRegex = /^([a-zA-Z0-9]{5}-){4}([a-zA-Z0-9]{5})$/;
                var validProductKeyRegex2 = /^([a-zA-Z0-9]{5}){4}([a-zA-Z0-9]{5})$/;
                if (validProductKeyRegex.test(productKey) || validProductKeyRegex2.test(productKey)) {
                    if (validProductKeyRegex2.test(productKey)) {
                        productKeys[index] = productKey = productKey.slice(0, 5) + '-' + productKey.slice(5, 10) + '-' + productKey.slice(10, 15) + '-' + productKey.slice(15, 20) + '-' + productKey.slice(20, 25);
                    }
                    temp.ProductKey = productKey;
                    payload.push(temp);
                }
                else {
                    setValidationError(true);
                    errorFlag = true;
                    errorIndex.push(index + 1);
                    setValidationErrorMessage(ApplicationConstants.ValidationErrors.IncorrectPKPNs + errorIndex);
                }
            }
        })
        console.log(productKeys);
        console.log(payload);
        if (!errorFlag) {
            setOpenLoading(true);
            setOpenSnackBar(true);
            setSnackBarMessage(ApplicationConstants.AlertMessages.PleaseWait);
            var keyValidationRequest = { KeyValidationRequestId: CreateGuid(), UserEmailId: props.graphProfile.userPrincipalName, ProductKeyList: payload, KeyValidationRequestStatus: ApplicationConstants.DashboardRequestStatus.NotStarted, CreatedDate: new Date() };
            await ApiCall.CallApiPost(ApplicationConstants.EndPoints.SubmitRequestEndpoint, keyValidationRequest, props.idToken, props.accessToken)
                .then((response) => {
                    console.log('logging response in submit request');
                    console.log(response);
                    if (response.errorCode !== 0) {
                        setAlertMessage(ApplicationConstants.AlertMessages.ErrorCode + response.errorCode + ApplicationConstants.AlertMessages.ErrorMessage + response.errorDescription);
                        setAlertSelector(0);
                        setOpenAlert(true);
                    } else {
                        setOpenAlert(true);
                        setAlertMessage(ApplicationConstants.AlertMessages.RequestId + response.keyValidationRequestId);
                        setAlertSelector(1)
                    }
                })
                .catch(error => {
                    setAlertMessage(ApplicationConstants.AlertMessages.ErrorCode + error.ErrorCode + ApplicationConstants.AlertMessages.ErrorMessage + error.errorDescription);
                    setAlertSelector(0);
                    setOpenAlert(true);
                    //setValidationErrorMessage(error);
                    console.log(error);
                });

            setOpenLoading(false);
            setOpenSnackBar(false);
        }
    }

    const handleSnackBarClose = () => {
        setOpenSnackBar(false);
    }

    const handleRequestsClear = () => {
        setProductKeys('');
        document.getElementById('keyvalidation-submit-textbox').value = '';
    }

    const AlertSnackBar = (selector) => (
        <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleErrorWarningAlertClose}>
            {selector === 0 ?
                <Alert onClose={handleErrorWarningAlertClose} severity="error">
                    {alertMessage}
                </Alert>
                :
                <Alert onClose={handleErrorWarningAlertClose} severity="success">
                    {alertMessage}
                </Alert>
            }
        </Snackbar>
    )

    const BottomSnackBar = (<Snackbar
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        style={{ 'fontSize': '12px' }}
        open={openSnackBar}
        autoHideDuration={6000}
        message={snackBarMessage}
        action={
            <React.Fragment>
                <IconButton size="large" aria-label="close" color="inherit" onClick={handleSnackBarClose}>
                    <CloseIcon fontSize="large" />
                </IconButton>
            </React.Fragment>
        }
    />
    )

    const submitRequest = (
        <div>
            <FormGroup style={{ width: '27em' }}>
                <div className='FlexLabel'>
                    <label for="outlined-multiline-static" >{ApplicationConstants.Labels.ProductKeys}</label>
                    <Tooltip title={ApplicationConstants.InfoIconsText.SubmitRequest}>
                        <IconButton aria-label={ApplicationConstants.InfoIconsText.SubmitRequest}>
                            <InfoIcon style={{ fontSize: 18 }} />
                        </IconButton>
                    </Tooltip>
                </div>
                <TextField
                    error={validationError}
                    helperText={validationError ? validationErrorMessage : null}
                    id="keyvalidation-submit-textbox"
                    required
                    label={ApplicationConstants.TextField.Label.EnterProductKeys}
                    multiline
                    rows="20"
                    rowsMax="100"
                    margin="normal"
                    placeholder={ApplicationConstants.TextField.Placeholder.SubmitRequest}
                    variant="outlined"
                    size="medium"
                    onChange={event => handleProductKey(event)}
                />
                <div className="submitBottomButtons">
                    <Button variant="contained" color="secondary" style={{ marginTop: "2em", marginBottom: "2em", width:"30%" }} className='mtp-form-cancel' onClick={event => handleRequestsClear()} disabled={productKeys === ''}>
                        {ApplicationConstants.Buttons.Clear}
                    </Button>
                    <Button variant="contained" color="primary" style={{ marginTop: "2em", marginBottom: "2em", width: "30%" }} disabled={productKeys === '' || validationError} onClick={event => handlePkpnSubmitClick()}>
                    {ApplicationConstants.Buttons.Submit}
                    </Button>
                </div>
            </FormGroup>
            {AlertSnackBar(alertSelector)}
        </div>)

    const DownloadToolUserGuideTable = (<Card className='CardOverflow' style={{width:"40%"}}>
        <TableContainer className={classes.container} >
            <Table fixedHeader={true} stickyHeader id="pkpnTable" aria-label="sticky table" className='pendingTable'>
                <TableHead>
                    <TableRow>
                        {ApplicationConstants.TableColumns.SubmitRequest.ToolUserGuideDownloadTableColumn.map(column => (
                            <React.Fragment>
                            <TableCell id="TableHeader" className="headerBorder"
                                key={column.id}
                                style={{ minWidth: column.minWidth }}>
                                        {column.label}
                            </TableCell>
                                <TableCell id="TableHeader" className="headerBorder" />
                            </React.Fragment>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {ApplicationConstants.TableRows.SubmitRequest.ToolUserGuideDownloadTableRows.map(row => {
                        return (
                            <TableRow hover role="checkbox" tabIndex={-1} >
                                {ApplicationConstants.TableColumns.SubmitRequest.ToolUserGuideDownloadTableColumn.map(column => {
                                    const value = row[column.id]
                                    return (
                                        <React.Fragment>
                                            <TableCell id="HFDTableBody" key={column.id}>
                                                {column.format && typeof value === 'number' ? column.format(value) : value}
                                            </TableCell>
                                            <TableCell hover id="HFDTableBody" align="center" >
                                                <Button >
                                                    {ApplicationConstants.Labels.Download}
                                                    < img src={DownloadIcon} style={{ height: '1.8em' }} className='hfd-download-icon' />
                                                </Button>
                                            </TableCell>
                                        </React.Fragment>
                                    );
                                })}
                            </TableRow>
                        );
                    }
                    )
                    }
                </TableBody>
            </Table>
        </TableContainer>
    </Card>)

    return (
        <React.Fragment>
            <div className="RowFlex-submitRequest">
                {submitRequest}
                </div>
            <Backdrop className={classes.backdrop} open={openLoading} style={{ marginLeft: props.drawerWidth }}>
                <CircularProgress />
            </Backdrop>
            {BottomSnackBar}
        </React.Fragment>
    );
}