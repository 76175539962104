import React from 'react'
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Component } from 'react';


const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';


export function ExportToExcel(csvData, Heading, headerPrecedence, fileName) {
    //const cell_format = workbook.add_format({ 'bold': True, 'font_color': 'red' })
    const ws = XLSX.utils.aoa_to_sheet(Heading, { cellStyles:true});
    XLSX.utils.sheet_add_json(ws, csvData, { skipHeader: true, origin: "A2", header: headerPrecedence });
    

    var range = XLSX.utils.decode_range(ws['!ref']);
    for (var D = range.s.c; D <= range.e.c; ++D) {
        var address = XLSX.utils.encode_col(D) + "1"; // <-- first row, column number C
        if (!ws[address]) continue;
        ws[address].v = ws[address].v.toUpperCase();
    }
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
}