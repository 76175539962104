import { UserAgentApplication } from "msal";
import ApplicationConstants from './Utilities/ApplicationConstants';

export const requiresInteraction = errorMessage => {
    if (!errorMessage || !errorMessage.length) {
        return false;
    }

    return (
        errorMessage.indexOf("consent_required") > -1 ||
        errorMessage.indexOf("interaction_required") > -1 ||
        errorMessage.indexOf("login_required") > -1
    );
};

export const fetchMsGraph = async (url, accessToken) => {
    const response = await fetch(url, {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    });

    return response.json();
};

export const getUserPhoto = async (url, accessToken) => {

    const response = await fetch(url, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'image/jpg'
        },
        responseType: 'arraybuffer'
    }).then(response => {
        if (response != null && response.ok) {
            return response.blob().
                then(data => {
                    if (data !== null) {
                        window.URL = window.URL || window.webkitURL;
                        return window.URL.createObjectURL(data);
                    }
                });
        }
    })
    return response;
};

export const checkMemberGroup = async (url, accessToken, groupIdArray) => {
    var payload = {
        "groupIds": groupIdArray
    }
    const response = await fetch(url, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(payload)
    });

    return response.json();
};

export const isIE = () => {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf("MSIE ") > -1;
    const msie11 = ua.indexOf("Trident/") > -1;

    // If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
    // const isEdge = ua.indexOf("Edge/") > -1;

    return msie || msie11;
};

export const GRAPH_SCOPES = {
    OPENID: "openid",
    PROFILE: "profile",
    USER_READ: "User.Read",
    MAIL_READ: "Mail.Read"
};

export const GRAPH_ENDPOINTS = {
    ME: "https://graph.microsoft.com/v1.0/me",
    MAIL: "https://graph.microsoft.com/v1.0/me/messages",
    GROUP: "https://graph.microsoft.com/v1.0/me/checkMemberGroups",
    PHOTO: "https://graph.microsoft.com/v1.0/me/photo/$value"
};

export const GRAPH_REQUESTS = {
    LOGIN: {
        scopes: [
            GRAPH_SCOPES.OPENID,
            GRAPH_SCOPES.PROFILE,
            GRAPH_SCOPES.USER_READ
        ],
        forceRefresh: true,
        redirectUri: ApplicationConstants.AppSettings[ApplicationConstants.EnvironmentType]?.RedirectUri
    },
    EMAIL: {
        scopes: [GRAPH_SCOPES.MAIL_READ]
    }
};

export const msalApp = new UserAgentApplication({
    auth: {
        clientId: ApplicationConstants.AppSettings[ApplicationConstants.EnvironmentType]?.ClientId,
        authority: "https://login.microsoftonline.com/72f988bf-86f1-41af-91ab-2d7cd011db47",
        validateAuthority: true,
        postLogoutRedirectUri: "http://localhost:44325",
        navigateToLoginRequestUrl: true
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: isIE()
    },
    system: {
        navigateFrameWait: 0,
        tokenRenewalOffsetSeconds:300
    }
});