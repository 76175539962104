import React, { useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import { Backdrop, CircularProgress, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Button, Typography, ButtonGroup, TableFooter, TablePagination, ClickAwayListener, FormGroup, TextField, Tooltip, TableSortLabel } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import './ProductKeyDistributionDashboard.css';
import PropTypes from 'prop-types';
import StopIcon from '@material-ui/icons/Stop';
import InfoIcon from '@material-ui/icons/Info';
import ApplicationConstants from '../../Utilities/ApplicationConstants';
import { ApiCall } from '../../Utilities/ApiCall';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import MuiAlert from '@material-ui/lab/Alert';
import queryString from 'query-string';
import RefreshIcon from '@material-ui/icons/Refresh';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
    title: {
        fontFamily: '"Segoe UI VSS (Regular)","Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
        color: 'black',
        fontWeight: 450,
        fontSize: '18px',
    },
    container: {
        maxHeight: '32em',
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

const useStyles1 = makeStyles(theme => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

var inProcessWidgetWidth;

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = event => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = event => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = event => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = event => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};



function View(props) {

    const classes = useStyles();
    const theme = useTheme();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [cancelReject, setCancelReject] = React.useState(false);
    const [cancelComment, setCancelComment] = React.useState('');
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('createdDate');
    const [selectedRow, setSelectedRow] = React.useState([]);
    const [keyValidationRequests, setKeyValidationRequests] = React.useState([])
    const [totalRecords, setTotalRecords] = React.useState(-1);
    const [errorCode, setErrorCode] = React.useState(0);
    const [errorDescription, setErrorDescription] = React.useState('');
    const [numberOfInError, setNumberOfInError] = React.useState(props?.numberOfInError);
    const [numberOfInProgress, setNumberOfInProgress] = React.useState(props?.numberOfInProgress);
    const [maxWidth, setMaxWidth] = React.useState(0);
    const [openLoading, setOpenLoading] = React.useState(props.loading);
    const [openSnackBar, setOpenSnackBar] = React.useState(false);
    const [snackBarMessage, setSnackBarMessage] = React.useState('');
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertSelector, setAlertSelector] = React.useState(0);
    const [openAlert, setOpenAlert] = React.useState(false);
    const [propsLoading, setPropsLoading] = React.useState(false);

    useEffect(() => {
        if (props.errorCode !== 0 && propsLoading === false && props.loading === false) {
            setAlertMessage(ApplicationConstants.AlertMessages.ErrorCode + props.errorCode + ApplicationConstants.AlertMessages.ErrorMessage + props.errorDescription);
            setAlertSelector(0);
            setOpenAlert(true);
            setOpenLoading(false);
            setErrorCode(props.errorCode)
            setPropsLoading(true);
        }
        else if (propsLoading === false && props.loading === false) {
            if (Array.isArray(keyValidationRequests) && keyValidationRequests.length === 0) {
                if (Array.isArray(props.keyValidationRequests) && props.keyValidationRequests.length) {
                    var tempRequests = [];
                    calculateTotalLineItems(props);
                }
            }
            if (totalRecords === -1) {
                setTotalRecords(props.totalRecord);
                setErrorCode(props.errorCode);
                setErrorDescription(props.errorDescription);
                setNumberOfInError(props.numberOfInError);
                setNumberOfInProgress(props.numberOfInProgress);
                var i = 0;
                if (props?.numberOfInError > 0) {
                    i++;
                }
                if (props?.numberOfInProgress > 0) {
                    i++;
                }
                setMaxWidth((100 - (i * 7)) / 100);
                setOpenLoading(props.loading);
                if (props.totalRecord === 0 && props.keyValidationRequests.length === 0) {
                    console.log(ApplicationConstants.AlertMessages.NoRecordsFound)
                    setAlertMessage(ApplicationConstants.AlertMessages.NoRecordsFound);
                    setAlertSelector(2);
                    setOpenAlert(true);
                    setOpenLoading(false);
                }
            }
        }
    })

    inProcessWidgetWidth = (parseInt(numberOfInProgress) / parseInt(numberOfInProgress + numberOfInError)) * 100;

    const calculateTotalLineItems = (response) => {
        var tempRequests = [];
        response.keyValidationRequests.forEach(function (keyValidationRow, index) {
            var temp = keyValidationRow;
            temp.totalNumberOfLineItems = keyValidationRow.productKeyList.length;
            var completedCount = 0;
            keyValidationRow.productKeyList.forEach(function (productKey, index) {
                if (productKey.lineItemStatus.localeCompare(ApplicationConstants.DashboardRequestStatus.Completed) === 0) {
                    completedCount++;
                }
            })
            temp.totalNumberOfLineItemsCompleted = completedCount;
            tempRequests.push(temp);
        })
        setKeyValidationRequests(tempRequests);
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            setOpenAlert(false);
            return;
        }

        setOpenAlert(false);
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };

    const handleChangePage = async (event, newPage) => {
        setPage(newPage);
        setSelectedRow([]);
        setCancelReject(false);
        var payload = { Limit: rowsPerPage, PageNumber: newPage + 1 };
        await FetchKeyValidationRequests(payload, true);
    };

    const handleChangeRowsPerPage = async event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        setSelectedRow([]);
        setCancelReject(false);
        var payload = { Limit: event.target.value, PageNumber: 1 };
        await FetchKeyValidationRequests(payload, true);
    };

    const handleCancelRejectClick = event => {
        setCancelReject(!cancelReject);
        var targetElm;
        if (!cancelReject) {
            setTimeout(() => {
                targetElm = document.querySelector('.commentBox');
                if (targetElm !== null) {
                    console.log(targetElm);
                    targetElm.scrollIntoView({ behavior: 'smooth', block: 'end' });
                }
            }, 50);
        } else {
            targetElm = document.querySelector('#root');
            targetElm.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
    }

    const handleSelectRow = row => {
        setCancelReject(false);
        if (selectedRow === row) {
            setSelectedRow([])
        } else {
            setSelectedRow(row);
        }
    }

    const handleSnackBarClose = () => {
        setOpenSnackBar(false);
    }

    const handleComment = (event) => {
        setCancelComment(event.target.value);
    }

    const FetchKeyValidationRequests = async (getKeyValidationRequest, snackBarMessage, refreshButtonClicked = false) => {
        setOpenLoading(true);
        if (snackBarMessage) {
            setOpenSnackBar(true);
            setSnackBarMessage(ApplicationConstants.AlertMessages.PleaseWait)
        }
        if (refreshButtonClicked) {
            setPage(0);
        }
        await ApiCall.CallApiGet(ApplicationConstants.EndPoints.GetProductKeyValidationRequestsDashboardEndPoint, getKeyValidationRequest, props.idToken, props.accessToken)
            .then((response) => {
                console.log('logging response in view history');
                console.log(response);
                if (response.errorCode === 0) {
                    calculateTotalLineItems(response)
                    setTotalRecords(response.totalRecord);
                    setErrorCode(response.errorCode);
                    setErrorDescription(response.errorDescription);
                    setNumberOfInError(response.numberOfInError);
                    setNumberOfInProgress(response.numberOfInProgress);
                    var i = 0;
                    if (response?.numberOfInError > 0) {
                        i++;
                    }
                    if (response?.numberOfInProgress > 0) {
                        i++;
                    }
                    setMaxWidth((100 - (i * 7)) / 100);
                } else {
                    setErrorCode(response.errorCode);
                    setErrorDescription(response.errorDescription);
                    setAlertMessage(ApplicationConstants.AlertMessages.ErrorCode + response.errorCode + ApplicationConstants.AlertMessages.ErrorMessage + response.errorDescription);
                    setAlertSelector(0);
                    setOpenAlert(true);
                }
            })
            .catch(error => {
                setErrorCode(error.status);
                setErrorDescription(error);
                setAlertMessage(ApplicationConstants.AlertMessages.ErrorCode + error.status + ApplicationConstants.AlertMessages.ErrorMessage + error.statusText);
                setAlertSelector(0);
                setOpenAlert(true);
            });

        setOpenLoading(false);
        setOpenSnackBar(false);
    }

    const handleActionButtonClick = (action, comment) => {
        var payload;
        if (action.localeCompare(ApplicationConstants.Buttons.Retry) === 0) {
            setCancelReject(false);
            payload = { KeyValidationRequestId: selectedRow.keyValidationRequestId };
            ActionClick(payload);
        } else {
            payload = { KeyValidationRequestId: selectedRow.keyValidationRequestId, KeyValidationRequestStatus: ApplicationConstants.DashboardRequestStatus.Cancelled, Comment: comment };
            ActionClick(payload);
        }
    }

    const Sleep = (milliseconds) => {
        const date = Date.now();
        let currentDate = null;
        do {
            currentDate = Date.now();
        } while (currentDate - date < milliseconds);
    }

    const ActionClick = async (payload) => {
        setOpenLoading(true);
        setOpenSnackBar(true);
        setSnackBarMessage(ApplicationConstants.AlertMessages.PleaseWait)
        await ApiCall.CallApiPost(ApplicationConstants.EndPoints.UpdateKeyValidationAsyncEndPoint, payload, props.idToken, props.accessToken)
            .then(async (response) => {
                console.log('logging response in view history');
                console.log(response);
                if (response.errorCode === 0) {
                    Sleep(2000);
                    var payload = { Limit: rowsPerPage, PageNumber: 1 };
                    await FetchKeyValidationRequests(payload, false);
                    setAlertMessage(ApplicationConstants.AlertMessages.Success);
                    setAlertSelector(1);
                    setOpenAlert(true);
                }
                else {
                    setErrorCode(response.errorCode);
                    setErrorDescription(response.errorDescription);
                    setAlertMessage(ApplicationConstants.AlertMessages.ErrorCode + response.errorCode + ApplicationConstants.AlertMessages.ErrorMessage + response.errorDescription);
                    setAlertSelector(0);
                    setOpenAlert(true);
                }
            })
            .catch(error => {
                setErrorCode(error.status);
                setErrorDescription(error);
                setAlertMessage(ApplicationConstants.AlertMessages.ErrorCode + error.status + ApplicationConstants.AlertMessages.ErrorMessage + error.statusText);
                setAlertSelector(0);
                setOpenAlert(true);
            });

        setOpenLoading(false);
        setOpenSnackBar(false);
        setSelectedRow([]);
        setCancelReject(false);
    }

    const BottomSnackBar = (<Snackbar
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        style={{ 'fontSize': '12px' }}
        open={openSnackBar}
        autoHideDuration={6000}
        message={snackBarMessage}
        action={
            <React.Fragment>
                <IconButton size="large" aria-label="close" color="inherit" onClick={handleSnackBarClose}>
                    <CloseIcon fontSize="large" />
                </IconButton>
            </React.Fragment>
        }
    />
    )

    //selector = 0 for error
    //selector = 1 for success
    //selector = 2 for info
    const AlertSnackBar = (selector) => (
        <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleAlertClose}>
            {selector === 0 ?
                <Alert onClose={handleAlertClose} severity="error">
                    {alertMessage}
                </Alert>
                : selector === 1 ?
                    <Alert onClose={handleAlertClose} severity="success">
                        {alertMessage}
                    </Alert> :
                    <Alert onClose={handleAlertClose} severity="info">
                        {alertMessage}
                    </Alert>
            }
        </Snackbar>
    )

    const visualRequestsContainer = (
        <Card>
            {numberOfInError > 0 || numberOfInProgress > 0 ? < div className='BarWidget'>
                {numberOfInProgress > 0 ? < div className='visual-inprogress' style={{ width: ((inProcessWidgetWidth * maxWidth) + 7) + '%' }}>
                    {numberOfInProgress !== -1 ? < span className='visual-progress-count'>{numberOfInProgress}</span> : null}
                </div> : null}
                {numberOfInError > 0 ? < div className='visual-error' style={{ width: (((100 - inProcessWidgetWidth) * maxWidth) + 7) + '%' }}>
                    {numberOfInError !== -1 ? < span className='visual-progress-count'>{numberOfInError}</span> : null}
                </div> : null}
            </div> : null}
            <div id='Legend'>
                <div id="colorcode">
                    <StopIcon id="inProgressLegend" />
                    <Typography id='LegendBody' variant="body2" color="textSecondary" component="p">
                        {ApplicationConstants.VisualBar.NumberofInProgessRequests}
                    </Typography>
                </div>
                <div id="colorcode">
                    <StopIcon id="errorLegend" />
                    <Typography id='LegendBody' variant="body2" color="textSecondary" component="p">
                        {ApplicationConstants.VisualBar.NumberofErrorRequests}
                    </Typography>
                </div>
            </div>
        </Card>)

    const inProcessRequestTable = (<Card className='CardOverflow'>
        <TableContainer className={classes.container} >
            <Table stickyHeader id="pkpnTable" aria-label="sticky table" className='pendingTable'>
                <TableHead>
                    <TableRow>
                        {ApplicationConstants.TableColumns.ProductKeyValidationDashboard.InProcessTableColumn.map(column => (
                            <TableCell id="TableHeader" className="headerBorder"
                                key={column.id}
                                style={{ minWidth: column.minWidth }}
                                sortDirection={orderBy === column.id ? order : false}>
                                {column.id == 'createdDate' ?
                                    <TableSortLabel
                                        active={orderBy === column.id}
                                        direction={orderBy === column.id ? order : 'asc'}
                                        onClick={createSortHandler(column.id)}
                                    >
                                        {column.label}
                                        {orderBy === column.id ? (
                                            <span className={classes.visuallyHidden}>
                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </span>
                                        ) : null}
                                    </TableSortLabel> :
                                    column.label
                                }
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {keyValidationRequests !== null && keyValidationRequests.length > 0 ?
                        (keyValidationRequests
                        ).map(row => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} onClick={event => handleSelectRow(row)} selected={row === selectedRow}>
                                    {ApplicationConstants.TableColumns.ProductKeyValidationDashboard.InProcessTableColumn.map(column => {
                                        if ((column.id == "createdDate") && row[column.id] != null) {
                                            row[column.id] = new Date(row[column.id]).toLocaleString();
                                        }
                                        const value = row[column.id]
                                        return (
                                            <TableCell id="PKPNTableBody" key={column.id} align={column.align}>
                                                {column.format && typeof value === 'number' ? column.format(value) : value}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })
                        : null
                    }
                </TableBody>
            </Table>
        </TableContainer>
        <TablePagination
            rowsPerPageOptions={[10, 20, 30, { label: 'All', value: totalRecords && totalRecords !== -1 ? totalRecords : 0 }]}
            colSpan={5}
            count={totalRecords && totalRecords !== -1 ? totalRecords : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
        />
    </Card>)

    const commentBox = (
        <div className='commentBox'>
            <FormGroup style={{ width: '27em' }}>
                <div className='FlexLabel'>
                    <label for="outlined-multiline-static" >{ApplicationConstants.Labels.EnterComments}</label>
                    <Tooltip title={ApplicationConstants.InfoIconsText.RequiredMax256AphaNumeric}>
                        <IconButton aria-label={ApplicationConstants.InfoIconsText.RequiredMax256AphaNumeric}>
                            <InfoIcon style={{ fontSize: 18 }} />
                        </IconButton>
                    </Tooltip>
                </div>
                <TextField
                    id="outlined-multiline-static"
                    required
                    label="Comment"
                    margin="normal"
                    variant="outlined"
                    size="medium"
                    inputProps={{ maxLength: '256' }}
                    onChange={event => handleComment(event)}
                    size="medium"
                />
                <Button variant="contained" color="primary" style={{ marginTop: "2em", marginBottom: "2em" }} disabled={cancelComment === ''} onClick={event => handleActionButtonClick(ApplicationConstants.Buttons.Cancel, cancelComment)}>
                    {ApplicationConstants.Buttons.Submit}
                </Button>
            </FormGroup>
        </div>)

    return (
        <div>
            <Backdrop className={classes.backdrop} open={openLoading} style={{ marginLeft: props.drawerWidth }}>
                <CircularProgress />
            </Backdrop>
            <div className='RowFlex'>
                <div className='TopTable'>
                    {visualRequestsContainer}
                    <div className="refreshButtonTopTable">
                        <Button variant="contained" color="primary" id='refreshTopTable' onClick={event => FetchKeyValidationRequests({ Limit: rowsPerPage, PageNumber: 1 }, true, true)}>
                            {ApplicationConstants.Buttons.Refresh}&nbsp;
                    <RefreshIcon fontSize="medium" />
                        </Button>
                    </div>
                </div>
                {inProcessRequestTable}
                <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group" style={{ marginTop: '2em' }} disabled={selectedRow.length === 0} className='pkdd-buttonGroup'>
                    <Button onClick={event => handleActionButtonClick(ApplicationConstants.Buttons.Retry)} disabled={Object.entries(selectedRow).length > 0 ? ((selectedRow?.keyValidationRequestStatus?.localeCompare(ApplicationConstants.DashboardRequestStatus.PartialCompleted) !== 0
                        && selectedRow?.keyValidationRequestStatus?.localeCompare(ApplicationConstants.DashboardRequestStatus.Failed) !== 0)
                        && selectedRow?.keyValidationRequestStatus?.localeCompare(ApplicationConstants.DashboardRequestStatus.NotStarted) !== 0 && selectedRow?.keyValidationRequestStatus?.localeCompare(ApplicationConstants.DashboardRequestStatus.InProgress) !== 0) : true}>
                        {ApplicationConstants.Buttons.Retry}
                    </Button>
                    <Button onClick={event => handleCancelRejectClick(event)} disabled={Object.entries(selectedRow).length > 0 ? ((selectedRow?.keyValidationRequestStatus?.localeCompare(ApplicationConstants.DashboardRequestStatus.PartialCompleted) !== 0
                        && selectedRow?.keyValidationRequestStatus?.localeCompare(ApplicationConstants.DashboardRequestStatus.Failed) !== 0)
                        && selectedRow?.keyValidationRequestStatus?.localeCompare(ApplicationConstants.DashboardRequestStatus.NotStarted) !== 0 && selectedRow?.keyValidationRequestStatus?.localeCompare(ApplicationConstants.DashboardRequestStatus.InProgress) !== 0 ): true}>
                        {ApplicationConstants.Buttons.Cancel}
                    </Button>
                </ButtonGroup>
                {cancelReject ? commentBox : null}
                {BottomSnackBar}
                {AlertSnackBar(alertSelector)}
            </div>
        </div>
    );
}

View.propTypes = {
    error: PropTypes.bool,
    errorDescription: PropTypes.string,
    loading: PropTypes.bool,
    keyValidationRequests: PropTypes.array,
    totalRecord: PropTypes.number,
    responseCount: PropTypes.number,
    numberOfInError: PropTypes.number,
    numberOfInProgress: PropTypes.number,
    drawerWidth: PropTypes.string
}

export class ProductKeyValidationDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorCode: 0,
            errorDescription: '',
            loading: true,
            keyValidationRequests: [],
            totalRecord: -1,
            userPrincipalName: this.props.graphProfile.userPrincipalName,
            numberOfInError: -1,
            numberOfInProgress: -1,
            idToken: this.props.idToken,
            accessToken: this.props.accessToken,
            drawerWidth: this.props.drawerWidth
        }
    }

    componentDidMount() {
        const values = queryString.parse(this.props?.location?.search);
        var keyValidationRequestId = values?.keyValidationRequestId;
        if (keyValidationRequestId) {
            console.log(keyValidationRequestId);
            const keyValidationQueryRequest = { KeyValidationRequestId: keyValidationRequestId };
            this.FetchKeyValidationRequests(keyValidationQueryRequest);
        } else {
            const keyValidationQueryRequest = { Limit: 10, PageNumber: 1 };
            this.FetchKeyValidationRequests(keyValidationQueryRequest);
        }
    }
    componentWillUnmount() {
        var targetElm = document.querySelector('#root');
        targetElm.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }

    async FetchKeyValidationRequests(getKeyValidationRequest) {
        await ApiCall.CallApiGet(ApplicationConstants.EndPoints.GetProductKeyValidationRequestsDashboardEndPoint, getKeyValidationRequest, this.props.idToken, this.props.accessToken)
            .then((response) => {
                console.log('logging response in view history');
                console.log(response);
                this.setState({
                    errorCode: response.errorCode,
                    errorDescription: response.errorDescription,
                    loading: false,
                    keyValidationRequests: response.keyValidationRequests ? response.keyValidationRequests : null,
                    totalRecord: response.totalRecord,
                    numberOfInError: response.numberOfInError,
                    numberOfInProgress: response.numberOfInProgress
                })
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    errorCode: error.status,
                    errorDescription: error.statusText,
                    loading: false,
                    keyValidationRequests: [],
                    totalRecord: 0
                })
            });
    }

    render() {

        return (
            <React.Fragment>
                <View {...this.state} />
            </React.Fragment>
        )
    }
}