import React from 'react';
import { Component } from 'react';
import querystring from 'querystring';

export class ApiCall extends Component {

    static async CallApiGet(
        path,
        body,
        idToken,
        accessToken,
        args = {
            method: "get",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${idToken}`,
                'Authorization-AccessToken': `Bearer ${accessToken}`
            },
        }
    ) {
        let querySring = querystring.stringify(body);
        return await this.CallHttp(new Request(path + '?' + querySring, args));
    };

    static async CallApiPost(
        path,
        body,
        idToken,
        accessToken,
        args = {
            method: "post",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${idToken}`,
                'Authorization-AccessToken': `Bearer ${accessToken}`
            },
            body: JSON.stringify(body)
        }
    ) {
        return await this.CallHttp(new Request(path, args));
    };

    static async CallHttp(request){
        const response = await fetch(request);
        if (!response.ok) {
            console.log(`error caused while calling api${response.statusText}`);
            console.log(response);
            throw (response);
        }
        const data = await response.json();
        return data;
    }
}

export default ApiCall;